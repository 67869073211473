import {chapters} from "./Seeder";

export default function makeChapterMock(makeId, sleep) {
    // Follow Repo Interface: Fetch, add, delete
    async function fetchAll() {
        await sleep(600);
        return chapters;
    }

    async function deleteById(chapter_id) {
        await sleep(600)
        delete chapters[chapter_id];
        return chapter_id;
    }

    async function add({name = 'Chapter', description = 'Description'}) {
        await sleep(600);
        const new_id = makeId();
        chapters.push({
            id: new_id,
            name: name,
            description: description,
            lessons: [],
        });
        return new_id;
    }

    async function update(chapter = {}){
        await(sleep(600));
        chapters.push({
            id: chapter.id,
            name: chapter.name,
            description: chapter.description,
            lessons: [],
        });
        return chapter.id;
    }

    async function fetchById(chapter_id) {
        await sleep(600);
        let chapter = null;
        chapters.some((curChapter) => {
            if(curChapter.id === chapter_id) {
                chapter = curChapter;
                return true;
            }
        });
        return chapter;
    }

    // Return API object
    return Object.freeze({
        fetchAll,
        fetchById,
        deleteById,
        add,
        update,
    });
}
