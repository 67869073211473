import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import StatisticsController from "../../controllers/StatsController";
import {notificationCreated} from "../notifications/notificationsSlice";

const initialState = {
    profilePopupOpen: false,
    visits: {
        data: {},
        status: "idle",
    },
    lessonCompletionRates: {
        data: [],
        status: "idle"
    },
    statementErrorRates: {
        data: [],
        status: "idle"
    },
    statementHintRates: {
        data: [],
        status: "idle"
    },
    upTimes: {
        data: [],
        status: "idle"
    }
}

const handleError = (error, action, thunkAPI) => {
    const notification = {
        message: error.message,
        severity: 'error',
        action: null,
    }
    thunkAPI.dispatch(notificationCreated(notification));
    return thunkAPI.rejectWithValue(error);
}

export const fetchVisits = createAsyncThunk(
    'home/getVisits',
    async ({}, thunkAPI) => {
        const res = await StatisticsController.getVisits();
        if (res.error) return handleError(res, null, thunkAPI);
        res.status = "idle";
        return {
            visits: {
                data: res.visits,
                status: "idle"
            }
        }
    }
);

export const fetchLessonCompletionRates = createAsyncThunk(
    "home/fetchLessonCompletionRates",
    async({}, thunkAPI) => {
        const res = await StatisticsController.getLessonCompletionRates();
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            lessonCompletionRates: {
                data: res,
                status: "idle"
            }
        }
    }
);

export const fetchStatementErrorRates = createAsyncThunk(
    "home/fetchStatementErrorRates",
    async({}, thunkAPI) => {
        const res = await StatisticsController.getStatementErrorRates();
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            statementErrorRates: {
                data: res,
                status: "idle"
            }
        }
    }
);

export const fetchStatementHintRates = createAsyncThunk(
    "home/fetchStatementHintRates",
    async({}, thunkAPI) => {
        const res = await StatisticsController.getStatementHintRates();
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            statementHintRates: {
                data: res,
                status: "idle"
            }
        }
    }
);

export const fetchUpTimes = createAsyncThunk(
    "home/fetchUpTimes",
    async({}, thunkAPI) => {
        const res = await StatisticsController.getUpTimes();
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            upTimes: {
                data: res,
                status: "idle"
            }
        }
    }
)

export const homeSlice = createSlice({
    name: 'home',
    initialState: initialState,
    reducers: {
        toggleProfilePopup: (state, action) => {
            state.profilePopupOpen = !state.profilePopupOpen;
        }
    },
    extraReducers: {
        [fetchVisits.pending]: (state, action) => {
            state.visits.status = "loading";
        },
        [fetchVisits.fulfilled]: (state, action) => {
            state.visits.status = action.payload.visits.status;
            state.visits.data = action.payload.visits.data;
        },
        [fetchVisits.rejected]: (state, action) => {
            state.visits.status = "idle";
            state.visits.data = {}
        },
        [fetchLessonCompletionRates.pending]: (state, action) => {
            state.lessonCompletionRates.status = "loading";
        },
        [fetchLessonCompletionRates.fulfilled]: (state, action) => {
            state.lessonCompletionRates.status =
                action.payload.lessonCompletionRates.status;
            state.lessonCompletionRates.data =
                action.payload.lessonCompletionRates.data;
        },
        [fetchLessonCompletionRates.rejected]: (state, action) => {
            state.lessonCompletionRates.status = "idle";
            state.lessonCompletionRates.data = [];
        },
        [fetchStatementErrorRates.fulfilled]: (state, action) => {
            state.statementErrorRates.status =
                action.payload.statementErrorRates.status;
            state.statementErrorRates.data =
                action.payload.statementErrorRates.data;
        },
        [fetchStatementErrorRates.pending]: (state, action) => {
            state.statementErrorRates.status = "loading";
        },
        [fetchStatementErrorRates.rejected]: (state, action) => {
            state.statementErrorRates.status = "idle";
            state.statementErrorRates.data = [];
        },
        [fetchStatementHintRates.fulfilled]: (state, action) => {
            state.statementHintRates.status =
                action.payload.statementHintRates.status;
            state.statementHintRates.data =
                action.payload.statementHintRates.data;
        },
        [fetchStatementHintRates.pending]: (state, action) => {
            state.statementHintRates.status = "loading";
        },
        [fetchStatementHintRates.rejected]: (state, action) => {
            state.statementHintRates.status = "idle";
            state.statementHintRates.data = [];
        },
        [fetchUpTimes.fulfilled]: (state, action) => {
            state.upTimes.status = action.payload.upTimes.status;
            state.upTimes.data = action.payload.upTimes.data;
        },
        [fetchUpTimes.pending]: (state, action) => {
            state.upTimes.status = "loading";
        },
        [fetchUpTimes.rejected]: (state, action) => {
            state.upTimes.status = "idle";
            state.upTimes.data = [];
        }
    }
});

export const selectProfilePopupOpen = (state) => state.home.profilePopupOpen;
export const selectVisits = (state) => state.home.visits;
export const selectLessonCompletionRates = (state) => state.home.lessonCompletionRates;
export const selectStatementErrorRates = (state) => state.home.statementErrorRates;
export const selectStatementHintRates = (state) => state.home.statementHintRates;
export const selectUpTimes = (state) => state.home.upTimes;
export const {toggleProfilePopup} = homeSlice.actions;
export default homeSlice.reducer;
