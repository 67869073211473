// React
import React, {useState} from 'react';
import {useHistory} from "react-router-dom";

// Redux
import {useDispatch} from "react-redux";
import {deleteStatements, statementsReordered} from "../statementsSlice";

// Custom
import {infoColor, errorColor} from "../../../styleVariables";

// Material UI
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLess, ExpandMore, StarBorder} from "@material-ui/icons";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import {setScreenStatements} from "../statementsSlice";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const ScreenListItem = ({statements, screenNb, provided, stepId}) => {

    // Local State
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }

    // Actions
    const dispatch = useDispatch();
    const history = useHistory();
    const deleteScreenStatements = (statements) => {
        dispatch(deleteStatements({
            statements: statements,
            stepId: stepId,
        }));
    }
    const editScreenStatements = (statements) => {
        dispatch(setScreenStatements({
            stepId: stepId,
            screenNb: screenNb,
            screenStatements: statements
        }));
        history.push('/edit');
    }
    const reorder = (result) => {
        if (!result.destination) return;
        const {source, destination} = result;
        dispatch(statementsReordered({
            source: source,
            destination: destination,
            stepId: stepId,
            statements: statements,
        }));
    }

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <ListItem {...provided.dragHandleProps}>
                <ListItemIcon style={{cursor: 'pointer'}} onClick={handleClick}>
                    {open ? <ExpandLess/> : <ExpandMore/>}
                </ListItemIcon>
                <ListItemText
                    primary={`Screen: ${screenNb}`}
                    secondary={`# of statements: ${statements?.length}`}
                />
                <ListItemSecondaryAction>
                    <IconButton
                        style={{color: infoColor}}
                        onClick={() => editScreenStatements(statements)}
                        edge="end"
                        aria-label="edit">
                        <EditTwoToneIcon/>
                    </IconButton>
                    <IconButton
                        style={{color: errorColor}}
                        onClick={() => deleteScreenStatements(statements)}
                        edge="end"
                        aria-label="delete">
                        <DeleteTwoToneIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <DragDropContext onDragEnd={reorder}>
                        <Droppable droppableId={`${stepId}`}>
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {statements.map(function (stmt, index) {
                                        return (
                                            <Draggable
                                                draggableId={`${stmt.id}`}
                                                key={stmt.id}
                                                index={stmt.index}
                                            >
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        className={classes.nested}
                                                    >
                                                        <ListItemIcon>
                                                            <StarBorder/>
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={`${stmt.index + 1} - ${stmt.name}`}
                                                            secondary={`${stmt.type}`}
                                                        />
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </List>
            </Collapse>
        </div>
    );
};
export default ScreenListItem;