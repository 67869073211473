export default function makeUserRepo(
    {baseUrl, uuid, isJson, setToken, getToken, deleteToken, checkError}) {

    const validRolesMap = {
        0: "student",
        1: "teacher",
        2: "admin"
    };

    /**
     * Fetches user
     * @param userId : user id
     * @returns
     */
    async function fetchById(userId) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
            method: 'GET',
            headers: myHeaders
        };
        const user = await (await fetch(baseUrl + ':' + userId.toString(), requestOptions)).json();
        checkError(user)
        return user;
    }

    /**
     * Fetches all users
     * @returns all users
     */
    async function fetchAllUsers({}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl, {
            method: 'GET',
            headers: myHeaders
        })).json();
        checkError(response)
        return response.users;
    }

    /**
     * Logs user in
     * @param email
     * @param password
     * @returns user's information
     */
    async function login({email, password}) {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"email": email, "password": password})
        }
        const response = await (await fetch(baseUrl + 'login', requestOptions)).json();
        checkError(response);
        setToken(response.token);
        return response;
    }

    /**
     * Registers user
     * @param username
     * @param email
     * @param password
     * @param firstName
     * @param lastName
     * @param role
     * @returns user's id
     */
    async function register({username, email, password, firstName, lastName, role}) {
        role = validRolesMap[role];
        const response = await (await fetch(baseUrl + 'register', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "username": username,
                "email": email,
                "password": password,
                "firstName": firstName,
                "lastName": lastName,
                "role": role
            })
        })).json();
        checkError(response)

        return response.id;
    }

    /**
     * Logs user out by deleting token
     * @returns boolean
     */
    function logout() {
        deleteToken();
        return true;
    }

    /**
     * Modifies user's information
     * @param user
     * @returns user's id
     */
    async function update({user}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        let requestBody;
        if (user.password) {
            requestBody = JSON.stringify({
                "username": user.username,
                "email": user.email,
                "password": user.password,
                "firstName": user.firstName,
                "lastName": user.lastName
            });
        } else {
            requestBody = JSON.stringify({
                "username": user.username,
                "email": user.email,
                "firstName": user.firstName,
                "lastName": user.lastName
            });
        }
        const response = await (await fetch(baseUrl + user.id.toString(), {
            method: 'PATCH',
            headers: myHeaders,
            body: requestBody
        })).json();
        checkError(response);
        return response.id;
    }

    /**
     * Adds user
     * @param user
     * @returns user's id
     */
    async function addUser({user}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "username": user.username,
                "email": user.email,
                "password": user.password,
                "firstName": user.firstName,
                "lastName": user.lastName,
                "role": user.role
            })
        })).json();
        checkError(response);
        return response.id;
    }

    /**
     * Deletes user whose id is given as paramter
     * @param userId: user's id
     * @returns user's id
     */
    async function deleteUser({userId}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());

        const response = await (await fetch(baseUrl + userId.toString(), {
            method: 'DELETE',
            headers: myHeaders
        })).json();
        checkError(response);
        return Number(response.id);
    }

    /**
     * Locally login a user that was already logged in
     * @param user: A valid user entity
     * @returns true if user can be logged in, false otherwise
     */
    function loginLocal({user}) {
        if (!user.token || typeof user.token === "undefined")
            throw new Error(("Invalid token"));
        setToken(user.token);
        return true;
    }

    //STILL UNCLEAR
    /*
    async function resetPassword(email)
    {
        return fetch(baseUrl + 'resetPassword', {
            method: 'POST',
            headers:
            {'Content-Type': 'application/json'},
            body: JSON.stringify({"email":email})
        })
        .then(data => data.json())
    }*/

    return Object.freeze({
        fetchById,
        fetchAllUsers,
        login,
        register,
        logout,
        update,
        addUser,
        deleteUser,
        loginLocal,
        //resetPassword,
    });
}