export default function buildMakeStatement({makeWidget}) {
    const error = "Statement could not be created! "
    let errorDetails = '';

    const statementMap = {
        theory: "theory",
        exercise: "exercise",
        problem: "problem",
    };

    function isDataValid(data) {
        let isValid = true;

        if (typeof data.id === "undefined") {
            isValid = false;
            errorDetails += "No id found. ";
        }

        if (!data.type || !Object.values(statementMap).includes(data.type)) {
            isValid = false;
            errorDetails += "Unknown statement type. ";
        }

        data.name = data.name ?? " - ";
        data.description = data.description ?? " - ";

        if (!Number.isInteger(data.screen) || data.screen < 0) {
            isValid = false;
            errorDetails += "No valid screen number found. ";
        }

        if (!Number.isInteger(data.index) || data.index < 0) {
            isValid = false;
            errorDetails += "No valid index found. ";
        }

        if (!Number.isInteger(data.columns) || data.columns < 0) {
            isValid = false;
            errorDetails += "Invalid number of columns. ";
        }

        if(!Array.isArray(data.widgets)) {
            isValid = false;
            errorDetails += "No widget array found. ";
        }

        return isValid;
    }

    return function makeStatement({data}) {
        if (!isDataValid(data)) {
            throw new Error(error + "\n" + errorDetails);
        }
        return {
            id: data.id,
            stepId: data.stepId,
            type: data.type,
            name: data.name,
            description: data.description,
            screen: data.screen,
            index: data.index,
            columns: data.columns,
            widgets: data.widgets.map((widget) => makeWidget({
                data: widget
            })),
        };
    }
}