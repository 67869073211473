// React
import React from 'react';
import classNames from 'classnames';
import {useHistory} from "react-router-dom";

// Material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import {Chip, Collapse} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PublishIcon from '@material-ui/icons/Publish';

// Styles
import {useStyles} from "./LessonSingleStyles";
import {successColor, warningColor} from "../../../styleVariables";

// Redux
import {selectChapterLessons, lessonStateChanged} from "../lessonsSlice";
import {selectUser} from "../../authentication/authenticationSlice";
import {useDispatch, useSelector} from "react-redux";
import {setChapterId} from "../../statements/statementsSlice";


const LessonSingle = ({lesson, chapterId, provided, handleToggle}) => {
    let history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const lessons = useSelector((state) => selectChapterLessons(state, chapterId));
    const user = useSelector((state) => selectUser(state));
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const editLesson = () => {
        handleToggle(lesson, true);
    }
    const setLessonState = (state) => {
        dispatch(lessonStateChanged({
            lessonId: lesson.id,
            chapterId: chapterId,
            oldState: lesson.state,
            newState: state,
            lastModifiedBy: user.id,
        }));
    }
    const toStatements = (lesson) => {
        dispatch(setChapterId({chapterId: chapterId}));
        history.push('/statements/' + lesson.id);
    }
    const getLessonById = (id) => {
        let lesson = null;
        lessons.some((l) => {
            if (l.id === id) {
                lesson = l;
                return true;
            }
            return false;
        });
        return lesson;
    }
    const displayDependencies = (deps) => {
        let displayed = [];
        if (deps.length > 0) {
            displayed = deps.map((dep, index) => {
                const lesson = getLessonById(dep);
                if(lesson === null) return null;
                if (index !== 0 && index % 30 === 0)
                    return (
                        <div key={lesson.id}>
                            <br/><Chip variant="outlined" label={`${index + 1}. ${lesson.name}`}/>
                        </div>
                    );

                return (
                    <Chip key={lesson.id} variant="outlined" label={`${index + 1}. ${lesson.name}`}/>
                );
            });
        }

        if(displayed.length > 0) return displayed;
        return (
            <Typography component='p' variant='caption'> No dependencies found </Typography>
        );
    }
    const displayActions = () => {
        let actions = [];
        // Edit action is always available
        actions.push(
            <Grid item key="edit">
                <Box display='flex'>
                    <Button
                        onClick={() => editLesson()}
                        size='small'
                        color='primary'
                        startIcon={<EditIcon/>}
                    >
                        Edit
                    </Button>
                </Box>
            </Grid>
        );

        // Delete action available if state is not already deleted
        if (lesson.state !== "deleted") {
            actions.push(
                <Grid item key="delete">
                    <Box display='flex'>
                        <Button
                            onClick={() => setLessonState("deleted")}
                            size='small'
                            color='secondary'
                            startIcon={<DeleteIcon/>}
                        >
                            Delete
                        </Button>
                    </Box>
                </Grid>
            );
        }

        // Set pending available only if state not deleted and lesson originally modified
        // by the current user.
        if (lesson.state === "dirty" && user.role !== "admin"
            && lesson.lastModifiedBy === user.id) {
            actions.push(
                <Grid item key="pending">
                    <Box display='flex'>
                        <Button
                            style={{color: warningColor}}
                            onClick={() => setLessonState("pending")}
                            size='small'
                            startIcon={<AssignmentTurnedInIcon/>}
                        >
                            Set Pending
                        </Button>
                    </Box>
                </Grid>
            )
        }

        // Set pending action available only if current user is an admin.
        if (lesson.state !== "validated" && user.role === "admin") {
            actions.push(
                <Grid item key="validate">
                    <Box display='flex'>
                        <Button
                            style={{color: successColor}}
                            onClick={() => setLessonState("validated")}
                            size='small'
                            startIcon={<PublishIcon/>}
                        >
                            Validate
                        </Button>
                    </Box>
                </Grid>
            )
        }

        return actions;
    }

    return (
        <Card
            className={classNames(classes.lesson, lesson.state ? classes[lesson.state] : 'validated')}
            ref={provided.innerRef}
            key={lesson.id}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <CardContent>
                <Typography onClick={() => toStatements(lesson)} variant="body1" gutterBottom>
                    <Link>{lesson.name}</Link>
                </Typography>
                <Typography component='p' noWrap variant="caption">
                    {lesson.description}
                </Typography>
            </CardContent>
            <Typography style={{cursor: 'pointer'}} onClick={handleExpandClick} component='p' noWrap variant="caption">
                Dependencies
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </Typography>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {displayDependencies(lesson.dependencies)}
                </CardContent>
            </Collapse>
            <Divider variant="middle" />
            <Grid style={{marginTop: "10px"}} container spacing={3} direction="row" justify='space-around'
                  alignItems="center" alignContent="space-between">
                {displayActions()}
            </Grid>
        </Card>
    );
};

export default LessonSingle;