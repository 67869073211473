const lightBlue = require('@material-ui/core/colors/lightBlue');
const yellow = require('@material-ui/core/colors/yellow');
const green = require('@material-ui/core/colors/green');
const red = require('@material-ui/core/colors/red')

// Colors
const infoColor = lightBlue.default[500];
const warningColor = yellow.default[500];
const successColor = green.default[500];
const errorColor = red.default[500];

// Spacing
const drawerWidth = 240;
const notificationCenterWidth = 300;
const MobileBreakpoint = 960;

export {
    infoColor,
    warningColor,
    successColor,
    errorColor,
    drawerWidth,
    notificationCenterWidth,
    MobileBreakpoint
};