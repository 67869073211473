import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setWidget} from "../../../statementsSlice";
import {widgetTypes} from "../../../../../presenters/EditorPresenter";
import CardContent from "@material-ui/core/CardContent";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const QuestionWidgetEditor = ({SubtypeEditor, widget}) => {
    // dispatch
    const dispatch = useDispatch();
    const setValue = (key, value) => {
        dispatch(setWidget({
            key: key,
            value: value,
        }));
    }

    // render
    return (
        <div>
            <CardContent>
                <FormControl style={{margin: '5px', width: '80%',}}>
                    <InputLabel>Widget Subtype</InputLabel>
                    <Select
                        onChange={(event) =>
                            setValue("subtype", event.target.value)}
                        value={widget.subtype}
                    >
                        {Object.keys(widgetTypes[widget.type].subtypes).map((type) => (
                            <MenuItem
                                key={type}
                                value={type}>
                                {widgetTypes[widget.type].subtypes[type].name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br/> <br/>
                <FormControl style={{margin: '5px', width: '80%',}}>
                    <TextField
                        onChange={(event) =>
                            setValue("difficulty", event.target.value)}
                        style={{width: "80%"}}
                        id="difficulty"
                        label="An estimate of the question's difficulty (max 10)"
                        type="number"
                        inputProps={{min: 1, max: 10, type: "number"}}
                        fullWidth
                        value={widget.difficulty ?? 1}
                    />
                </FormControl>
                <br/> <br/>
                <FormControl style={{margin: '5px', width: '80%',}}>
                    <TextField
                        onChange={(event) =>
                            setValue("mistakeMessage", event.target.value)}
                        id='error'
                        style={{width: "80%"}}
                        label="Message displayed on wrong answer"
                        value={widget.mistakeMessage ?? ""}
                    />
                </FormControl>
            </CardContent>
            <CardContent>
                <SubtypeEditor widget={widget}/>
            </CardContent>
        </div>
    );
}

export default QuestionWidgetEditor;