import makeChapterMock from "./ChapterRepoMock";
import makeLessonMock from "./LessonRepoMock";
import makeStatementMock from "./StatementRepoMock";
import makeUserRepoMock from "./UserRepoMock";
import makeStatisticsMock from "./StatRepoMock"
import { v4 as uuidv4 } from 'uuid';

const makeId = () => {
    return uuidv4();
}
const sleep = m => new Promise(r => setTimeout(r, m));

// Make token accessible to all repos
let token = null;
const setToken = (newToken) => token = newToken;
const getToken = () => token;
const deleteToken = () => token = null;

const chapterMockRepository = makeChapterMock(makeId, sleep, getToken);
const lessonMockRepository = makeLessonMock(makeId, sleep, getToken);
const statementMockRepository = makeStatementMock(makeId, sleep, getToken);
const userRepoMock = makeUserRepoMock(makeId, sleep, getToken, setToken, deleteToken);
const statisticsMockRepository = makeStatisticsMock(makeId, sleep, getToken);

const mockRepositoryService = Object.freeze({
    chapterMockRepository,
    lessonMockRepository,
    statementMockRepository,
    userRepoMock,
    statisticsMockRepository,
});

export {
    chapterMockRepository, lessonMockRepository, statementMockRepository,
    userRepoMock, statisticsMockRepository,
};

export default mockRepositoryService;