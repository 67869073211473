import chaptersService from "../usecases/chapters/index";
import {createChapterListModel} from "../presenters/ChapterPresenter";

export async function getChaptersList() {
    const response = await chaptersService.listChapters();
    return createChapterListModel(response);
}

export async function addChapterController(chapter) {
    return await chaptersService.addChapter({
        newChapter: chapter,
    });
}

export async function deleteChapterController(chapterId) {
    return await chaptersService.deleteChapter(chapterId);
}

const ChapterController = {
    getChaptersList,
    addChapterController,
    deleteChapterController,
}

export default ChapterController;