import elementFactory from "../elements";
import buildMakeMCQWidget from "./MCQWidget";
import buildMakeSingleQuestionWidget from "./SingleQuestionWidget";
import buildMakeQuestionWidgetFactory from "./QuestionWidgetFactory";

const questionWidgetMap = {
    mcq: "mcq",
    single: "single_question"
};

const makeMCQWidget = buildMakeMCQWidget({
    subtypeString: "Multiple Choice Question",
    subtypeVal: questionWidgetMap.mcq,
    elementFactory: elementFactory
});

const makeSingleQuestionWidget = buildMakeSingleQuestionWidget({
    subtypeString: "Single Question Widget",
    subtypeVal: questionWidgetMap.single,
    elementFactory: elementFactory
});

const createWidget = (data) => {
    if (typeof data.subtype !== "undefined") {
        switch (data.subtype) {
            case questionWidgetMap.mcq:
                return makeMCQWidget({
                    data: data
                });
            case questionWidgetMap.single:
                return makeSingleQuestionWidget({
                    data: data
                });
            default:
                throw new Error("Unknown question widget type!");
        }
    }
}

const makeQuestionWidgetFactory = buildMakeQuestionWidgetFactory({});
const questionWidgetFactory = makeQuestionWidgetFactory({
    createWidget: createWidget
});

export default questionWidgetFactory;
