import React, {useEffect} from "react";
import {DataGrid, GridToolbar, GridFooter} from "@material-ui/data-grid";
import {useDispatch, useSelector} from "react-redux";
import {
    listUsers,
    selectStatus,
    selectUsers,
    setUserValue,
    toggleAddUserPopup,
    userDeleted,
    userSaved
} from "./usersSlice";
import {CenteredLoader} from "../../components";
import {createColumns} from "../../presenters/UsersPresenter";
import {Card, CardHeader, Paper, Select} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {notificationCreated} from "../notifications/notificationsSlice";
import {infoColor} from "../../styleVariables";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddUserPopup from "./AddUserPopup";

const useStyles = makeStyles(theme => ({
    card: {
        margin: "20px",
        marginRight: "20px",
        padding: "20px",
        height: "100%",
        width: "100%",
    },
    paper: {
        marginBottom: "20px",
        padding: "20px",
        elevation: 10,
        height: "100%"
    },
    grid: {
        marginLeft: "auto",
        marginRight: "auto",
        height: "80%",
        maxWidth: "80%",
    },
    select: {
        alignSelf: "center",
        margin: "20px",
        '& .MuiInputBase-input': {
            height: "30px"
        }
    }
}));

function RoleSelectCell(props) {
    const dispatch = useDispatch();
    const setRole = ({userId, field, value}) => {
        dispatch(setUserValue({
            userId: userId,
            key: field,
            value: value
        }));
    }
    const classes = useStyles();
    const {id, value, api, field} = props;
    const handleChange = React.useCallback(
        (event) => {
            setRole({
                userId: id,
                field: field,
                value: event.target.value,
            });
            const editProps = {
                value: String(event.target.value)
            };
            api.commitCellChange({id, field, props: editProps});
            api.setCellMode(id, field, "view");
        }, [api, field, id]
    );

    return (
        <Select
            className={classes.select}
            open={true}
            native={true}
            autoFocus={true}
            onChange={handleChange}
            defaultValue={value}
        >
            <option value="student"> student</option>
            <option value="teacher"> teacher</option>
            <option value="admin"> admin</option>
        </Select>
    );
}

function renderRoleEditCell(params) {
    return <RoleSelectCell {...params} />;
}

function Panel() {
    const dispatch = useDispatch();
    const onAddClicked = () => {
        dispatch(toggleAddUserPopup({}));
    }
    return (
        <Grid container direction="row">
            <Grid item xs={12} sm={12} md={10} lg={10}>
                <GridToolbar/>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button
                    onClick={onAddClicked}
                    variant="outlined"
                    style={{margin: "20px", color: infoColor}}>
                    Add user
                </Button>
            </Grid>
        </Grid>
    );
}

const UsersList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(listUsers({}));
    }, []);
    const deleteUser = (userId) => {
        dispatch(userDeleted({
            userId: userId
        }));
    };
    const saveUser = (userId) => {
        dispatch(userSaved({
            userId: userId
        }));
    };

    const users = useSelector((state) => selectUsers(state));
    const status = useSelector((state) => selectStatus(state));
    const columns = createColumns({
        onDelete: deleteUser,
        onSave: saveUser,
        renderRoleEditCell: renderRoleEditCell
    });

    const handleEditRowsModelChange = ({id, field, props}) => {
        if (field === "email") {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(props.value).toLowerCase())) {
                const notification = {
                    message: "Invalid email address (will be ignored)",
                    severity: "warning",
                    action: null
                };
                dispatch(notificationCreated(notification));
                return null;
            }
        }

        if (field === "role") return

        dispatch(setUserValue({
            userId: id,
            key: field,
            value: props.value
        }));
    }

    if (status === "pending")
        return <CenteredLoader/>

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    title="Users List"
                    subheader="As an admin you may create, add, delete or modify any
                of the application's users. Browse the table below"
                />
                <Paper className={classes.paper}>
                    <div style={{display: 'flex', height: '100%', padding: "20px"}}>
                        <DataGrid
                            components={{
                                Toolbar: Panel,
                                Footer: GridFooter
                            }}
                            className={classes.grid}
                            rows={users}
                            columns={columns}
                            pageSize={5}
                            onEditCellChangeCommitted={handleEditRowsModelChange}
                        />
                    </div>
                </Paper>
            </Card>
            <AddUserPopup />
        </>
    );
}

export default UsersList;