import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {notificationCreated} from '../notifications/notificationsSlice'
import ChapterController from "../../controllers/ChapterController";
import LessonController from "../../controllers/LessonController";
import {selectUser} from "../authentication/authenticationSlice";

const initialState = {
    chapters: {},
    status: 'idle',
    addingStatus: 'idle',
    error: null,
    dependencies: [],
    editingLesson: {
        id: null,
        name: '',
        description: '',
        state: "dirty",
        dependencies: [],
    },
    lessonDisplayOptions: {
        search: '',
        filter: {},
        sort: {}
    }
}

const handleError = (error, action, thunkAPI) => {
    const notification = {
        message: error.message,
        severity: 'error',
        action: null,
    }
    thunkAPI.dispatch(notificationCreated(notification));
    return thunkAPI.rejectWithValue(error);
}

// Fetch all lessons
export const fetchAllLessons = createAsyncThunk(
    'lessons/fetchAllLessons',
    async (arg, thunkAPI) => {
        const res = await ChapterController.getChaptersList();
        if (res.error) {
            return handleError(res, null, thunkAPI);
        }
        return {chapters: res};
    });

// Save a newly added chapter
export const chapterSaved = createAsyncThunk(
    'lessons/chapterAdded',
    async ({lessonBoardName, chapter}, thunkAPI) => {
        const res = await ChapterController.addChapterController(chapter);
        if (res.error) {
            return handleError(res, null, thunkAPI);
        }

        res.lessons = chapter.lessons;
        return {
            status: 'success',
            chapter: res
        }
    }
)

// Delete a lesson from db
export const chapterDeleted = createAsyncThunk(
    'lessons/chapterDeleted',
    async ({lessonBoardName, chapterId}, thunkAPI) => {
        const res = await ChapterController.deleteChapterController(chapterId);
        if (res.error) {
            return handleError(res, null, thunkAPI);
        }
        return {
            status: 'success',
            lessonBoardName: lessonBoardName,
            chapterId: chapterId
        }
    }
)

// Save a newly added lesson
export const lessonSaved = createAsyncThunk(
    'lessons/lessonSaved',
    async ({newLesson, chapterId, isEdit}, thunkAPI) => {
        const chapter = thunkAPI.getState().lessons.chapters[chapterId];
        const res = await LessonController.createLessonController(chapter, newLesson, isEdit);
        if (res.error) return handleError(res, null, thunkAPI);
        res.lastModifiedBy = selectUser(thunkAPI.getState()).id;
        return {
            isEdit: isEdit,
            lesson: res,
            chapterId: chapter.id,
        };
    }
)

// Delete a lesson from db.
export const lessonDeleted = createAsyncThunk(
    'lessons/lessonDeleted',
    async ({lessonId, chapterId}, thunkAPI) => {
        const chapter = thunkAPI.getState().lessons.chapters[chapterId];
        let lesson = null;
        let index = null;
        chapter.lessons.some((curLesson, i) => {
            if (curLesson.id === lessonId) {
                lesson = curLesson;
                index = i;
                return true;
            }
            return false;
        });

        const res = await LessonController.deleteLessonController(chapter, lesson);

        if (res.error) {
            return handleError(res);
        }

        return {
            chapter: chapter,
            index: index,
        }
    }
)

export const lessonStateChanged = createAsyncThunk(
    'lessons/lessonStateChanged',
    async ({lessonId, chapterId, oldState, newState}, thunkAPI) => {
        const chapter = thunkAPI.getState().lessons.chapters[chapterId];
        let lesson = null;
        chapter.lessons.some((curLesson, i) => {
            if (curLesson.id === lessonId) {
                lesson = {...curLesson};
                return true;
            }
            return false;
        });
        lesson.state = newState;
        const res = await LessonController.setLessonState(lesson, chapter, oldState);
        if(res.error) return handleError(res);

        return {
            chapter: chapter,
            lesson: res
        }
    }
)

const lessonsSlice = createSlice({
    name: 'lessons',
    initialState: initialState,
    reducers: {
        reorderLessons: (state, action) => {
            const reordering = action.payload.reordering;
            const chapters = state.chapters;
            if (reordering.sourceChapterId === reordering.destChapterId) {
                const chapter = chapters[reordering.sourceChapterId];
                chapter.lessons.splice(reordering.indexAfter, 0,
                    chapter.lessons.splice(reordering.indexBefore, 1)[0]);
            }
        },
        setDependencies: (state, action) => {
            const chapterId = action.payload.chapterId;
            const curLesson = state.editingLesson;
            const allLessons = [...state.chapters[chapterId].lessons];
            state.editingLesson.dependencies = action.payload.dependencies ?? [];
            state.dependencies =
                LessonController.getLessonDependencies(curLesson, allLessons);
        },
        setEditingLesson: (state, action) => {
            state.editingLesson = action.payload.lesson;
            state.editingLesson.dependencies =
                state.editingLesson.dependencies ?? [];
        },
        setSearchOptions: (state, action) => {
            state.lessonDisplayOptions = {
                ...state.lessonDisplayOptions,
                ...action.payload
            };
        }
    },
    extraReducers: {
        [fetchAllLessons.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchAllLessons.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.chapters = action.payload.chapters;
        },
        [fetchAllLessons.rejected]: (state, action) => {
            state.status = 'failed';
        },
        [chapterSaved.fulfilled]: (state, action) => {
            state.chapters[action.payload.chapter.id] = {
                id: action.payload.chapter.id,
                title: action.payload.chapter.name,
                description: action.payload.chapter.description,
                lessons: action.payload.chapter.lessons
            }
        },
        [chapterDeleted.fulfilled]: (state, action) => {
            delete state.chapters[action.payload.chapterId];
        },
        [lessonSaved.pending]: (state, action) => {
            state.addingStatus = 'loading';
        },
        [lessonSaved.fulfilled]: (state, action) => {
            state.addingStatus = 'idle';
            const lessons = state.chapters[action.payload.chapterId].lessons;
            if (!action.payload.isEdit)
                lessons.push(action.payload.lesson);
            else {
                lessons.some((curLesson, index) => {
                    if (curLesson.id === action.payload.lesson.id) {
                        lessons[index] = action.payload.lesson;
                        return true;
                    }
                    return false;
                });
            }
        },
        [lessonSaved.rejected]: (state, action) => {
            state.addingStatus = 'failed';
        },
        [lessonDeleted.fulfilled]: (state, action) => {
            const chapter = state.chapters[action.payload.chapter.id];
            chapter.lessons.splice(action.payload.index, 1);
        },
        [lessonStateChanged.fulfilled]: (state, action) => {
            const lesson = action.payload.lesson;
            const chapter = state.chapters[action.payload.chapter.id];
            chapter.lessons.some((curLesson, index) => {
                if(curLesson.id === lesson.id) {
                    if(lesson.wasDeleted === true)
                        chapter.lessons.splice(index, 1);
                    else
                        chapter.lessons[index] = lesson;
                    return true;
                }
                return false;
            });
        }
    }
});

// Selectors
export const selectAllChapters = (state) => Object.values(state.lessons.chapters);
export const selectAddingStatus = (state) => state.lessons.addingStatus;
export const selectLessonById = (state, lessonId) => {
    let lesson = null;
    Object.values(state.lessons.chapters).some((chapter) => {
        if (lesson !== null) return true;
        chapter.lessons.some((curLesson) => {
            if (curLesson.id === lessonId) {
                lesson = curLesson;
                return true;
            }
            return false;
        });
        return false;
    });
    return lesson ?? {};
}
export const selectChapterLessons = (state, chapterId) => {
    return state.lessons.chapters[chapterId].lessons;
}
export const selectDependencies = (state) => state.lessons.dependencies;
export const selectEditingLesson = (state) => state.lessons.editingLesson;
export const selectLessonDisplayOptions = (state) => state.lessons.lessonDisplayOptions;
export const {reorderLessons, setDependencies, setEditingLesson,
    setSearchOptions} = lessonsSlice.actions;
export default lessonsSlice.reducer;