import makeError from "../../entities/error";

export default function makeDeleteLesson({lessonRepository}) {
    return async function deleteLesson(chapter, lesson) {
        try {
            return await lessonRepository.deleteById(chapter.id, lesson.id);
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? 'The application encoutered' +
                    'a problem trying to delete this lesson.'});
        }
    }
}