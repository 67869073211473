import buildMakeGenericWidget from "./GenericWidget";
import buildMakeHintWidget from "./HintWidget";
import elementFactory from "../elements";

const informationWidgetMap = {
    generic: "generic",
    hint: "hint",
};

const makeGenericWidget = buildMakeGenericWidget({
    subtypeString: "Generic Widget",
    subtypeVal: informationWidgetMap.generic,
    elementFactory: elementFactory,
});

const makeHintWidget = buildMakeHintWidget({
    subtypeString: "Hint Widget",
    subtypeVal: informationWidgetMap.hint,
    elementFactory: elementFactory
});

const informationWidgetFactory = {
    create: (data) => {
        if(data.subtype && typeof data.subtype !== "undefined") {
            switch (data.subtype) {
                case informationWidgetMap.generic:
                    return makeGenericWidget({
                        data: data
                    });
                case informationWidgetMap.hint:
                    return makeHintWidget({
                        data: data
                    });
                default: throw new Error("Unknown Information Widget type!");
            }
        } else {
            switch (data.subtypeString) {
                case "Generic Widget":
                    return makeGenericWidget({
                        data: data
                    });
                case "Hint Widget":
                    return makeHintWidget({
                        data: data
                    });
                default: throw new Error("Unknown Information widget type!");
            }
        }
    }
}

export default informationWidgetFactory;