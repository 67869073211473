Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + parseInt(days));
    return this;
};

export const dateDisplayOptions = {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
};

export const intervals = {
    DAY: 1,
    WEEK: 7,
    MONTH: 31.
};

export const createDateRange = (start, end, interval) => {
    const dateArray = [];
    let s = new Date(start);
    const e = new Date(end);
    while (s <= e) {
        dateArray.push(new Date(s.getTime()));
        s = s.addDays(intervals[interval]);
    }
    return dateArray;
}

