/**
 * @param statements
 * E.g: [{screen: 1, order: 2}, {screen: 1, order: 1}, {screen: 2, order: 1}]
 * @returns {*}
 * E.g: {1: [{screen: 1, order: 1}, {screen: 1, order 2}], 2: [{screen: 2, order: 1}]}
 */
export function aggregateStatementsByScreen(statements) {
    const sortByOrder = (statements) => {
        return statements.sort((s1, s2) => {
            return (s1.index - s2.index);
        });
    }

    const groupByKey = (stmts, key) => {
        return stmts.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    statements = groupByKey(statements, 'screen');
    Object.keys(statements).map((screen, index) => {
        statements[screen] = sortByOrder(statements[screen]);
    });

    return statements;
}

/**
 * Reorder screens. Source <-> destination
 * @param screens : Object of the form {screenNb: [screenStatements], ...}
 * @param source  : Object
 * @param destination : Object
 * source.index/destination.index is the index of the screen in the array
 * source.droppableId = destination.droppableId = lessonId
 * @returns newScreens array with the elements reordered correctly
 */
export function optimisticScreenReorder(screens, source, destination) {
    // Screens can be changed within the same lesson only!
    if(source.droppableId !== destination.droppableId)
        return screens;

    const stmtsByScreen = Object.values(screens);
    stmtsByScreen.splice(destination.index, 0,
        stmtsByScreen.splice(source.index, 1)[0]);
    const newScreens = {};
    let i = 0;
    stmtsByScreen.forEach(ss => newScreens[i++] = ss);
    return newScreens;
}

/**
 * Sort steps by index
 * @param steps
 * @returns {*}
 */
export function sortSteps(steps) {
    return steps.sort((s1, s2) => {
        return (s1.index - s2.index);
    });
}

/**
 * Reorder steps. Source should be places at destination and everything in
 * between should have their indexes updated accordingly.
 * @param steps
 * @param source
 * @param destination
 * source.droppableId = destination.droppableId = lessonId
 * source.index/destination.index is the index of the step in the array.
 * @returns {*}
 */
export function optimisticStepReorder(steps, source, destination) {
    if(source.droppableId !== destination.droppableId) {
        return steps;
    }
    steps.splice(destination.index, 0, steps.splice(source.index, 1)[0]);
}