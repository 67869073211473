import makeUser from "../../entities/user";
import makeError from "../../entities/error";

export default function makeRegister({userRepository}){
    return async function register({newUser}){
        try{
            newUser.id = 1; // placeholder id

            let validatedUser = makeUser({
                userData: newUser,
            }); // validate user data

            // register and set actual id
            validatedUser.id = await userRepository.register({
                username: validatedUser.username,
                lastName: validatedUser.lastName,
                firstName: validatedUser.firstName,
                email: validatedUser.email,
                password: validatedUser.password,
                role: validatedUser.roleVal
            });
            return validatedUser;
        }catch(err){
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application " +
                    "encountered a problem"
            });
        }
    }
}