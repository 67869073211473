import repositoryService from "../../repositories";
import mockRepositoryService from "../../mocks";
import makeListLessons from "./listLessons";
import makeCreateLesson from "./createLesson";
import makeUpdateLesson from "./updateLesson";
import makeDeleteLesson from "./deleteLesson";
import makeGetLessonDependencies from "./getLessonDependencies";

let repo;
if(process.env.REACT_APP_USE_MOCKS !== 'false') {
    repo = mockRepositoryService.lessonMockRepository
} else {
    repo = repositoryService.lessonRepository;
}

const listLessons = makeListLessons({lessonRepository: repo});
const createLesson = makeCreateLesson({lessonRepository: repo});
const updateLesson = makeUpdateLesson({lessonRepository: repo});
const deleteLesson = makeDeleteLesson({lessonRepository: repo});
const getLessonDependencies = makeGetLessonDependencies();

const lessonsService = Object.freeze({
    listLessons,
    createLesson,
    updateLesson,
    deleteLesson,
    getLessonDependencies,
});

export {listLessons, createLesson, updateLesson, deleteLesson,
        getLessonDependencies};
export default lessonsService;
