export default function makeStatisticsRepo(
    {baseUrl, uuid, isJson, getToken, checkError}
) {
    async function fetchUserVisits({user_id}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        };
        const response = await (
            await fetch(baseUrl + "stats/visits/" + user_id.toString(), requestOptions)
        ).json();
        checkError(response);
        return response;
    }

    async function fetchLessonsCompletionRates() {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        };
        const response = await (
            await fetch(baseUrl + "analytics/lessons", requestOptions)
        ).json();
        checkError(response);
        return response;
    }

    async function fetchStatementErrorRates() {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        };
        const response = await (
            await fetch(baseUrl + "analytics/steps", requestOptions)
        ).json();
        checkError(response);
        return response;
    }

    async function fetchStatementHintRates() {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const requestOptions = {
            method: "GET",
            headers: myHeaders
        };
        const response = await (
            await fetch(baseUrl + "analytics/steps", requestOptions)
        ).json();
        checkError(response);
        return response;
    }

    async function fetchUpTimes() {
        throw new Error("Not Available!");
    }

    return Object.freeze({
        fetchUserVisits,
        fetchLessonsCompletionRates,
        fetchStatementErrorRates,
        fetchStatementHintRates,
        fetchUpTimes,
    });
}