// React
import React, {useEffect, useState} from "react";

// Material UI
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// Components
import StatementForm from "./statementForm";
import ContentForm from "./contentForm";
import InstanceForm from "./instanceForm";
import Previewer from "./previewer";

const useStyles = makeStyles(theme => ({
    box: {
        position: 'relative',
        height: "99%",
        margin: theme.spacing(1)
    },
}));

const Social = () => {
    const classes = useStyles();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    console.log("INITIAL HEIGHT", windowHeight);
    const handleResize = (e) => {
        console.log("RESIZE CALLED");
        setWindowHeight(window.innerWidth);
        console.log("WINDOW HEIGHT", windowHeight);
    }
    useEffect(() => {
        return function removeListener() {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return (
        <Box
            display="flex"
            flex="1"
            justifyContent='space-between'
            className={classes.box}
            style={{height: 0.9*windowHeight}}
        >
            <Grid container spacing={1} alignItems="stretch" align="center" justify="center">
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Grid container spacing={1} alignItems="stretch" align="center" justify="center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <StatementForm/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ContentForm/>
                        </Grid>
                        <Box
                            style={{height: 0.9*windowHeight - 535}}
                            display='flex'
                            flex='1'
                            alignContent="stretch"
                            alignItems="center"
                        >
                            <InstanceForm />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Previewer />
                </Grid>
            </Grid>

        </Box>

    );
};

export default Social;
