import makeUser from "../../entities/user";

export default function makeGetInitState({storage, userRepository}){
    return function getInitState() {
        // User is not authenticated and set id to null to show that invalid
        // result was obtained
        const defaultInitState = {
            isAuthenticated: false,
            user: {id: null}
        };
        try{
            const isAuthenticated = storage.read("is_authenticated") ?? false;
            let user = storage.read("user");

            if (isAuthenticated && user !== null && typeof user !== "undefined") {
                user = makeUser({userData: user});
                userRepository.loginLocal({
                    user:user
                });
            } else {
                return defaultInitState;
            }

            return {
                isAuthenticated: isAuthenticated,
                user: user
            };
        } catch(err){
            return defaultInitState;
        }
    }
}