export default function buildMakeImageElement(
    {typeString, typeVal, isValidUrl, makeId}
) {
    const error = "Creating an image element failed. ";
    let errorDetails = "";

    const isValidElement = (data) => {
        let isValid = true;
        if(data.type !== typeVal &&
            data.typeString !== typeString) {
            isValid = false;
            errorDetails += "Invalid type for image element. ";
        }

        if(!data.url) {
            isValid = false;
            errorDetails = "No url found. ";
        } else {
            if(!isValidUrl(data.url)) {
                isValid = false;
                errorDetails = "Invalid image url. ";
            }
            else{
                if(data.url.length > 101879){ //approximate length of image of size = 75 ko
                    errorDetails ="Image is too large. Please upload an image preferably lighter than 75 ko.";
                    isValid = false;
                }
            }
        }

        return isValid;
    }

    return function makeImageElement({data}) {
        if(!isValidElement(data)) {
            throw new Error(error + "\n" + errorDetails);
        }

        return {
            id: makeId(),
            typeString: typeString,
            type: typeVal,
            url: data.url
        };
    }
}