export default function checkError(serverResponse) {
    if (serverResponse.code) {
        switch (serverResponse.code) {
            case "unauthorized" : {
                serverResponse.description = "You do not have permissions to perform this action."
                break;
            }
            case "expired_token" : {
                serverResponse.description = "Your access token has expired."
                break;
            }
            case "invalid_token" : {
                serverResponse.description = "Your access token is not valid."
                break;
            }
            case "error_token" : {
                serverResponse.description = "An error occured during token verification."
                break;
            }
            case "bad_input" : {
                serverResponse.description = "Your inputs were not in a correct format."
                break;
            }
            case "bad_login" : {
                serverResponse.description = "The email or password you provided is incorrect."
                break;
            }
            case "email_already_exists" : {
                serverResponse.description = "The email you entered is already used."
                break;
            }
            case "username_already_exists" : {
                serverResponse.description = "The username you entered is already used."
                break;
            }
            case "name_already_exists" : {
                serverResponse.description = "The name you entered is already being used."
                break;
            }
            case "not_found" : {
                serverResponse.description = "Could not find what was requested."
                break;
            }
            case "invalid_req_body" : {
                serverResponse.description = "An invalid request body was provided."
                break;
            }
            case "invalid_req_params" : {
                serverResponse.description = "Invalid request parameters were provided."
                break;
            }
            default:
                break;
        }
        throw new Error(serverResponse.description)
    }
}