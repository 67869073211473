import makeChapter from "../../entities/chapter";
import makeLesson from "../../entities/lesson";
import makeError from "../../entities/error";

export default function makeListChapters({chapterRepository}) {
    return async function listChapters() {
        try {
            const chaptersInfo = await chapterRepository.fetchAll();
            return chaptersInfo.map((chapterInfo) => {
                return makeChapter({
                    id: chapterInfo.id,
                    name: chapterInfo.name,
                    description: chapterInfo.description ?? '',
                    lessons: chapterInfo.lessons.map((lesson) => makeLesson(lesson)) ?? []
                });
            });
        } catch(err) {
            return makeError({
                code: -1,
                message:
                err.message,
                description: err.description ?? 'The application ' +
                    'encountered a problem while trying to list all available chapters'});
        }
    }
}