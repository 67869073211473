import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {
    resetError,
    selectAuthError,
    selectAuthState,
    selectStatus,
    selectUsername,
    userLoggedIn
} from "./authenticationSlice";
import {useHistory} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import {notificationCreated} from "../notifications/notificationsSlice";

const useStyles = makeStyles(theme => ({
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 1300,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const Login = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const emailChanged = (event) => setEmail(event.target.value);
    const [pass, setPass] = useState("");
    const passChanged = (event) => setPass(event.target.value);
    const [persist, setPersist] = useState(false);
    const persistSet = (event) => setPersist(event.target.checked);

    // Handle form submission
    const status = useSelector((state) => selectStatus(state));
    const formSubmitted = (e) => {
        e.preventDefault();
        if (status !== "pending") {
            dispatch(userLoggedIn({
                email: email,
                password: pass,
                persist: persist,
            }));
        }
    }

    // Handle redirect on success
    const isAuthenticated = useSelector((state) => selectAuthState(state));
    const history = useHistory();
    const username = useSelector((state) => selectUsername(state));
    useEffect(() => {
        if (isAuthenticated){
            const notification = {
                message: "Login Successful! Welcome " + username,
                severity: "success",
                action: null
            };
            dispatch(notificationCreated(notification));
            history.push("/");
        }
    }, [isAuthenticated]);

    // Handle error
    const errMessage = useSelector((state) => selectAuthError(state));
    useEffect(() => {
        if(status === 'failed' && errMessage !== ""){
            const notification = {
                message: errMessage,
                severity: "error",
                action: null
            };
            dispatch(notificationCreated(notification));
            resetForm();
        }
    }, [errMessage])

    // Handle reset on error
    const resetForm = () => {
        setPass("");
        setEmail("");
        setPersist(false);
        dispatch(resetError({}));
    }


    /**
     * Return login button or progress depending on status
     * @returns {JSX.Element}
     */
    const loginState = () => {
        if (status === "loading") return <CircularProgress className="pt-1 text-md-center"/>
        return (
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
            >
                Login
            </Button>
        );
    }

    return (
        <div className={classNames(classes.session, classes.background)}>
            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <Card>
                        <CardContent>
                            <form onSubmit={(e) => formSubmitted(e)}>
                                <div
                                    className={classNames(classes.logo, `text-xs-center pb-xs`)}
                                >
                                    <Typography variant="h6">
                                        Welcome to the
                                    </Typography>
                                    <Typography variant="h4">
                                        MSS dashboard
                                    </Typography>
                                    <Divider style={{margin: "10px"}}/>
                                    <Typography variant="caption">
                                        Sign in with your provided credentials
                                    </Typography>
                                </div>
                                <TextField
                                    required
                                    id="email"
                                    label="Email"
                                    className={classes.textField}
                                    fullWidth
                                    margin="normal"
                                    value={email}
                                    onChange={(event) => emailChanged(event)}
                                />
                                <TextField
                                    required
                                    id="password"
                                    label="Password"
                                    className={classes.textField}
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    value={pass}
                                    onChange={(event) => passChanged(event)}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox onChange={(event) => persistSet(event)}
                                                  value={persist}/>
                                    }
                                    label="Stayed logged in"
                                    className={classes.fullWidth}
                                />

                                {loginState()}

                                <div className="pt-1 text-md-center">
                                    <Link to="/forgot">
                                        <Button>Forgot password?</Button>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Link to="/register">
                                        <Button>Create new account.</Button>
                                    </Link>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Login;