import makeError from "../../entities/error";

export default function makeGetUpTimes({statRepository}) {
    return async function getUpTimes({}) {
        try {
            return await statRepository.fetchUpTimes();
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description
            });
        }
    }
}