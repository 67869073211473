import makeLesson from "../../entities/lesson";
import makeError from "../../entities/error";

export default function makeUpdateLesson({lessonRepository}) {
    return async function updateLesson(chapter, newLesson) {
        try {
            const res = await lessonRepository.update(chapter.id, newLesson);
            return makeLesson({
                id: res,
                chapterId: chapter.id,
                name: newLesson.name,
                description: newLesson.description,
                state: newLesson.state,
                dependencies: newLesson.dependencies,
                lastModifiedBy: newLesson.lastModifiedBy,
                createdBy: newLesson.createdBy,
                statements: newLesson.statements ?? [],
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err. description ?? 'The application encountered' +
                    'a problem trying to update this lesson.'});
        }
    }
}