import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Card, CardHeader, Checkbox, FormControl, FormControlLabel,
    FormGroup, FormLabel, TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import {setSearchOptions, selectLessonDisplayOptions} from "../lessonsSlice";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    card: {
        elevation: 10,
        boxShadow: theme.shadows[3],
    },
    form: {
        display: 'flex',
        width: '70%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(3),
    }
}));

const LessonSearch = ({}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    // init display options
    const displayOptions = useSelector((state) => selectLessonDisplayOptions(state));
    let initFilters = [
        {label: "Validated", key: "validated", value: false},
        {label: "Pending", key: "pending", value: false},
        {label: "Deleted", key: "deleted", value: false},
        {label: "Modified", key: "dirty", value: false},
    ];
    let initSortKeys = [
        {label: "Name", key: "name", value: false},
        {label: "Description", key: "description", value: false},
        {label: "State", key: "state", value: false},
        {label: "Nb of dependencies", key: "dependencies", value: false}
    ];
    useEffect(() => {
        if(displayOptions.filter.state){
            displayOptions.filter.state.forEach((key) => {
                initFilters.forEach((filter) =>{
                    if(filter.key === key) filter.value = true;
                });
            });
        }
        Object.keys(displayOptions.sort).forEach((key) => {
            initSortKeys.forEach((sort) => {
                if(sort.key === key) sort.value = true;
            });
        });
    }, []);

    // handle searching
    const [search, setSearch] = useState(displayOptions.search);
    const searchChanged = (event) => {
        setSearch(event.target.value);
        dispatch(setSearchOptions({
            search: event.target.value
        }));
    }

    // handle state filtering
    const [stateFilters, setStateFilters] = useState(initFilters);
    const stateFilterChanged = (event, index) => {
        let newFilters = [...stateFilters];
        newFilters[index].value = event.target.checked;
        setStateFilters(newFilters);
        const newFilter = newFilters.filter((filter) => {
            return filter.value;
        }).map((filter) => {
            return filter.key;
        });
        if(newFilter.length > 0) {
            dispatch(setSearchOptions({
                filter: {
                    state: newFilters.filter((filter) => {
                        return filter.value;
                    }).map((filter) => {
                        return filter.key;
                    })
                }
            }));
        } else {
            dispatch(setSearchOptions({
                filter: {}
            }));
        }
    }

    // handle sorting
    const [sortKeys, setSortKeys] = useState(initSortKeys);
    const sortKeysChanged = (event, index) => {
        let newKeys = [...sortKeys];
        newKeys[index].value = event.target.checked;
        setSortKeys(newKeys);
        const sort = {};
        newKeys.forEach((key) => {
            if(key.value) sort[key.key] = null;
        });
        dispatch(setSearchOptions({
            sort: sort
        }));
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar aria-label="search">
                        <SearchIcon/>
                    </Avatar>
                }
                title="Chapter Lessons"
                subheader="Search and filter through the chapter's lessons"
            />
            <CardContent>
                <FormControl className={`${classes.formControl} ${classes.form}`}>
                    <TextField
                        id="standard-full-width"
                        label="Lesson Name"
                        style={{margin: 8, width: "100%"}}
                        helperText="Filter through lesson names"
                        margin="normal"
                        value={search}
                        onChange={searchChanged}
                    />
                </FormControl>
                <Divider style={{margin: "20px"}}/>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Show based on state:</FormLabel>
                    <FormGroup row>
                        {stateFilters.map((filter, index) => (
                            <FormControlLabel key={index}
                                control={
                                    <Checkbox checked={filter.value}
                                              onChange={(event) =>
                                                  stateFilterChanged(event, index)}
                                              name={filter.label}/>
                                }
                                label={filter.label}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
                <Divider style={{margin: "20px"}}/>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Sort based on</FormLabel>
                    <FormGroup row>
                        {sortKeys.map((sortKey, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={sortKey.value}
                                        onChange={(event) => sortKeysChanged(event, index)}
                                        name={sortKey.label}
                                    />
                                }
                                label={sortKey.label}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </CardContent>
        </Card>
    );
}

export default LessonSearch;