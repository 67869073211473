import {makeStyles} from "@material-ui/styles";
import {infoColor, warningColor, successColor, errorColor} from "../../../styleVariables";

export const useStyles = makeStyles(theme => ({
    fab: {
        top: 'auto',
        right: 50,
        bottom: 50,
        left: 'auto',
        position: 'fixed',
        color: 'primary',
        backgroundColor: 'secondary'
    },
    lessonBoard: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    wrapper: {
        width: '80%',
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1) / 2,
        paddingLeft: theme.spacing(1) / 2,
        display: 'block',
        verticalAlign: 'top',
        height: '100%',

    },
    list: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        whiteSpace: 'normal',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: theme.shape.borderRadius
    },
    header: {
        position: 'relative',
        flexDirection: 'row',
        align: 'left',
        letterSpacing: '0.02rem',
        padding: theme.spacing(1),
        margin: 0,
        fontWeight: 1000,
        textTransform: 'uppercase',
        fontSize: '15px'
    },
    subheader: {
        position: 'relative',
        flexDirection: 'row',
        align: 'left',
        letterSpacing: '0.02rem',
        padding: theme.spacing(1),
        margin: 0,
        fontWeight: 500,
        fontSize: '12px'
    },
    lesson: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow: theme.shadows[3],
        color: theme.palette.text.primary,
        background: theme.palette.background.paper,
        elevation: 20,
        borderRadius: "10px",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '&:last-child': {
            marginBottom: 10
        },
        '&:hover': {
            cursor: 'grab'
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            border: '15px solid transparent',
            top: 0,
            borderTopWidth: '20px',
            borderBottomColor: 'transparent',
            right: '10px'
        }
    },
    validated: {
        '&::after': {
            borderTopColor: successColor,
            borderRightColor: successColor,
            borderLeftColor: successColor
        }
    },
    dirty: {
        '&::after': {
            borderTopColor: infoColor,
            borderRightColor: infoColor,
            borderLeftColor: infoColor
        }
    },
    pending: {
        '&::after': {
            borderTopColor: warningColor,
            borderRightColor: warningColor,
            borderLeftColor: warningColor
        }
    },
    deleted: {
        '&::after': {
            borderTopColor: errorColor,
            borderRightColor: errorColor,
            borderLeftColor: errorColor
        }
    },
    cards: {
        position: 'relative',
        boxSizing: 'border-box',
        height: '100%',
        overflow: 'hidden',
    },
    expand: {
        margin: 0,
        padding: 0,
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));