import {makeStep} from "../../entities/statement";
import makeError from "../../entities/error";

export default function makeUpdateStep({statementRepository}) {
    return async function({step}) {
        try{
            let res;
            if(step.id === null)
                res = await statementRepository.addStep(step.lessonId, step.id, step);
            else
                res = await statementRepository.updateStep(step.lessonId, step.id, step);
            step.id = res;
            return makeStep({
                stepData: step
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered " +
                    "a problem setting this step"
            });
        }
    }
}