import React, {useEffect} from "react";
import {Card, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {removeWidget, selectOnEditStatement, selectOnEditWidget} from "../statementsSlice";
import GenericWidgetEditor from "./widgetEditors/informationWidgets/GenericWidgetEditor";
import HintWidgetEditor from "./widgetEditors/informationWidgets/HintWidgetEditor";
import QuestionWidgetEditor from "./widgetEditors/questionWidgets/QuestionWidgetEditor";
import SingleQuestionWidgetEditor from "./widgetEditors/questionWidgets/SingleQuestionWidgetEditor";
import {widgetTypes} from "../../../presenters/EditorPresenter";
import {setWidget} from "../statementsSlice";
import MCQWidgetEditor from "./widgetEditors/questionWidgets/MCQWidgetEditor";
import Button from "@material-ui/core/Button";
import {errorColor} from "../../../styleVariables";

const useStyles = makeStyles(theme => ({
    card: {
        width: "100%",
        height: "550px",
        overflowY: "scroll",
    },
}));

const ContentForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const statement = useSelector((state) => selectOnEditStatement(state));
    const widget = useSelector((state) => selectOnEditWidget(state));
    const setValue = (key, value) => {
        dispatch(setWidget({
            key: key,
            value: value
        }));
    }
    const remove = () => {
        dispatch(removeWidget({
            statementId: statement.id,
            widgetId: widget.id
        }));
    }

    const getWidgetEditor = () => {
        switch (widget.type) {
            case "information": {
                switch (widget.subtype) {
                    case "generic":
                        return <GenericWidgetEditor widget={widget}/>
                    case "hint":
                        return <HintWidgetEditor widget={widget}/>
                }
            }
                break;
            case "question": {
                switch (widget.subtype) {
                    case "single_question":
                        return <QuestionWidgetEditor
                            widget={widget}
                            SubtypeEditor={SingleQuestionWidgetEditor}
                        />
                    case "mcq":
                        return <QuestionWidgetEditor
                            widget={widget}
                            SubtypeEditor={MCQWidgetEditor}
                        />
                }
            }
                break;
        }
    }

    useEffect(() => {
        switch (widget?.type) {
            case "information": {
                if (!Object.keys(widgetTypes.information.subtypes)
                    .includes(widget.subtype)) {
                    dispatch(setWidget({
                        key: "subtype",
                        value: "generic"
                    }));
                }
            }
                break;
            case "question": {
                if (!Object.keys(widgetTypes.question.subtypes)
                    .includes(widget.subtype)) {
                    dispatch(setWidget({
                        key: "subtype",
                        value: "single_question"
                    }));
                }
            }
                break;
        }
    }, [widget]);

    if(typeof widget !== "undefined") {
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Widget
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                    <FormControl style={{margin: '5px', width: '80%',}}>
                        <InputLabel>Widget Type</InputLabel>
                        <Select
                            onChange={(event) =>
                                setValue("type", event.target.value)}
                            value={widget.type}
                        >
                            {Object.keys(widgetTypes).map((type) => (
                                <MenuItem key={type} value={type}>{widgetTypes[type].name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CardContent>
                {
                    getWidgetEditor()
                }
                <CardContent>
                    <Button
                        onClick={remove}
                        style={{color: errorColor}}
                        variant="outlined">
                        Remove Widget
                    </Button>
                </CardContent>
            </Card>
        );
    }

    return <Card className={classes.card}>
        <h6> No Widget Selected </h6>
    </Card>
};

export default ContentForm;