import makeError from "../../entities/error";

export default function makeDeleteChapter({chapterRepository}) {
    return async function deleteChapter(chapterId) {
        try {
            const res =  await chapterRepository.deleteById({id: chapterId});
            return res;
        }catch(err){
            return makeError({code:-1, message:err.message, description: 'The application ' +
                    'encountered a problem deleting this chapter'});
        }
    }
}