import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DialogContentText} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {selectOnEditStep, selectOpenEditStep, stepSaved, toggleStepModal} from "../statementsSlice";


const StepPopup = ({lesson}) => {
    const step = useSelector((state) => selectOnEditStep(state));
    const stepCopy = {...step};
    const open = useSelector((state) => selectOpenEditStep(state));
    const [stepName, setStepName] = useState("");
    const nameChanged = (event) => setStepName(event.target.value);
    const [stepDesc, setStepDesc] = useState("");
    const descChanged = (event) => setStepDesc(event.target.value);
    const [stepAdvised, setStepAdvised] = useState([]);

    useEffect(() => {
        setStepName(stepCopy.name);
        setStepDesc(stepCopy.description);
        setStepAdvised(stepCopy.advised);
    }, [step])

    const dispatch = useDispatch();
    const saveStep = () => {
        const newStep = {
            ...stepCopy,
            name: stepName,
            description: stepDesc,
            advised: stepAdvised,
        };
        dispatch(stepSaved({
            step: newStep,
        }));
        dispatch(toggleStepModal({}));
    }
    const modalClosed = () => {
        setStepName("");
        setStepDesc("");
        setStepAdvised([]);
        dispatch(toggleStepModal({}));
    }

    if (!lesson || typeof lesson === "undefined" || typeof lesson.id === "undefined") return <></>;

    return (
        <Dialog fullWidth={true} open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {lesson.name.toUpperCase()}
            </DialogTitle>
            <Divider/>
            <Box mr={2} ml={2} mt={0}>
                <DialogContentText style={{marginTop: "20px"}}>
                    You're creating a step for <strong>{lesson.name}</strong>.
                    Fill in the information below and press <strong>Done!</strong>
                </DialogContentText>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <TextField
                            onChange={(event) => nameChanged(event)}
                            autoFocus
                            id="name"
                            label="Step Title"
                            type="text"
                            fullWidth
                            defaultValue={stepName}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) => descChanged(event)}
                            id="description"
                            label="Step Description"
                            multiline
                            rows={4}
                            defaultValue={stepDesc}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
            <DialogActions>
                <Button onClick={() => modalClosed()} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => saveStep()}
                    color="primary">
                    Done!
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default StepPopup;