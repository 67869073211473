import {createSlice} from '@reduxjs/toolkit';

const initialState = {
}

const lessonsListSlice = createSlice({
    name: 'lessonsList',
    initialState: initialState,
    reducers: {
    },
});

export const { lessonsReordered, lessonRemoved } = lessonsListSlice.actions;
export default lessonsListSlice.reducer;