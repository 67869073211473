import repositoryService from "../../repositories";
import mockRepositoryService from "../../mocks";
import makeListChapters from "./listChapters";
import makeAddChapter from "./addChapter";
import makeDeleteChapter from "./deleteChapter";

// Pick Actual or Mock Repository
let repo;
if(process.env.REACT_APP_USE_MOCKS !== 'false') {
    console.log('USING MOCK REPOSITORIES / Chapters');
    repo = mockRepositoryService.chapterMockRepository;
} else {
    console.log('USING API / Chapters');
    repo = repositoryService.chapterRepository;
}

// Create Use Cases
const listChapters = makeListChapters({chapterRepository: repo});
const addChapter = makeAddChapter({chapterRepository: repo});
const deleteChapter = makeDeleteChapter({chapterRepository: repo});

const chaptersService = Object.freeze({
    listChapters,
    addChapter,
    deleteChapter
})

export {listChapters, addChapter, deleteChapter};
export default chaptersService;