// React
import React from "react";

// Presenter
import {statementTypes} from "../../../presenters/EditorPresenter";

// Material UI
import Card from "@material-ui/core/Card"
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {selectOnEditStatement, setStatement} from "../statementsSlice";

const useStyles = makeStyles(theme => ({
    card: {
        width: "100%",
        height: "550px",
        overflowY: "scroll",
    },
}));


const StatementForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const statement = useSelector((state) => selectOnEditStatement(state));
    const setValue = (key, value) => {
        dispatch(setStatement({
            key: key, value: value
        }));
    }

    if(typeof statement !== "undefined") {
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Statement
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                    <TextField
                        onChange={(event) =>
                            setValue("name", event.target.value)}
                        id="title"
                        style={{width: "80%"}}
                        label="Give a short title"
                        value={statement.name}
                    />
                    <br/> <br/>
                    <TextField
                        onChange={(event) =>
                            setValue("description", event.target.value)}
                        id='description'
                        multiline
                        rows={4}
                        style={{width: "80%"}}
                        label="Give a short description"
                        value={statement.description}
                    />
                    <br/> <br/>
                    <TextField
                        onChange={(event) => {
                            let val = 1;
                            try {
                                val = parseInt(event.target.value);
                                val = (val <= 0) ? 1 : val;
                                val = (val >= 3) ? 2 : val;
                            } catch(_) {  }
                            setValue("columns", val)}
                        }
                        style={{width: "80%"}}
                        id="columns"
                        label="Number of columns (max 2)"
                        type="number"
                        inputProps={{min: 1, max: 2, type: "number"}}
                        fullWidth
                        value={statement.columns}
                    />
                </CardContent>
                <CardContent>
                    <FormControl style={{margin: '5px', width: '80%',}}>
                        <InputLabel>Statement Type</InputLabel>
                        <Select
                            value={statement.type}
                            onChange={(event) =>
                                setValue("type", event.target.value)}
                        >
                            {Object.keys(statementTypes).map((type) => (
                                <MenuItem key={type} value={type}>{statementTypes[type]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CardContent>
            </Card>
        );
    }

    return <Card className={classes.card}>
        <h6> No Statement Selected </h6>
    </Card>
};

export default StatementForm;