// React
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Material
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LayersSharpIcon from '@material-ui/icons/LayersSharp';
import Grid from "@material-ui/core/Grid";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {fetchAllLessons} from "../lessonsSlice";
import {selectAllChapters} from "../lessonsSlice";

// Styles
import {useStyles} from './SubjectTabsStyles';

// Components
import LessonBoard from "./LessonBoard";
import {CenteredLoader} from '../../../components'
import {Paper} from "@material-ui/core";

// Tabs Container
function TabContainer(props) {
    return (
        <Grid
            container
            display="flex"
            align="center"
            direction="column"
        >
            <Grid item xs={12}>
                <div style={{
                    paddingTop: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    boxSizing: 'border-box',
                }}>
                    {props.children}
                </div>
            </Grid>
        </Grid>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const SubjectTabs = () => {
    // Local State
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChangeTab = (event, value) => setValue(value);

    // Global State
    const dispatch = useDispatch();
    const chapters = useSelector(selectAllChapters);
    const boardStatus = useSelector((state) => state.lessons.status);
    const error = useSelector((state) => state.lessons.error);

    // Fetch data on first appearance
    useEffect(() => {
        if (boardStatus === 'idle') {
            dispatch(fetchAllLessons());
        }
    }, [boardStatus, dispatch]);

    // Set content based on state
    let contents = Array(chapters.length).fill(<div>Something has gone wrong</div>);
    if (boardStatus === 'loading') {
        contents.forEach(function (content, index) {
            this[index] = <CenteredLoader key={index}/>
        }, contents);
    } else if (boardStatus === 'succeeded') {
        contents.forEach(function (content, index) {
            this[index] =
                <LessonBoard key={index} lessonBoard={chapters[index]} lessonBoardName={chapters[index].title}/>
        }, contents);
    } else if (boardStatus === 'failed') {
        contents.forEach(function (content, index) {
            this[index] = <div key={index}>An error occurred: {error}</div>
        }, contents)
    }
    return (
        <>
            <Paper className={classes.root} position='sticky' color='default'>
                <Tabs
                    value={value}
                    onChange={(event, value) => handleChangeTab(event, value)}
                    scrollButtons='on'
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    {chapters.map((chapter, index) => {
                        return <Tab key={index} label={chapter.title} icon={<LayersSharpIcon/>}/>;
                    })}
                </Tabs>
            </Paper>
            {typeof contents[value] === 'undefined' ? <div> {error} </div> :
                <TabContainer>{contents[value]}</TabContainer>}
        </>
    )
}
export default SubjectTabs;