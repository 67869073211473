import makeError from "../../entities/error";
import makeUser from "../../entities/user";

export default function makeGetUser({userRepository}) {
    return async function getUser({userId}) {
        try {
            const res = await userRepository.fetchById(userId);
            return makeUser({
                userData: res
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered " +
                    "a problem trying to fetch a user"
            });
        }
    }
}