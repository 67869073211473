export default function buildMakeLesson({uuid, isValidId}) {
    /**
     * API uses property name: requiredLessonIds
     * App uses property name: dependencies
     * This constructor needs to work with both. Whichever property name is sent
     * is used. If both are sent, requiredLessonIds is preferred (since it probably
     * comes from the API).
     * TODO: make an adapter on the repo to rename to dependencies!
     */
    return function makeLesson(
        {
            id = uuid.make(), chapterId = uuid.make(),
            name = '', description = '',
            state = '', requiredLessonIds = [],
            statements = [], lastModifiedBy = null,
            createdBy = null, dependencies = [],
        } = {}) {

        const states = ['validated', 'dirty', 'pending', 'deleted'];

        if(!isValidId)
            throw new Error('Lesson Invalid id');
        if(name.length < 5)
            throw new Error('Lesson Name must be more than 5 characters long');
        if(description.length < 5)
            throw new Error('Lesson Description must be more than 5 characters long');
        if(!states.includes(state))
            throw new Error('Unknown lesson state');
        if(!Array.isArray(requiredLessonIds) && !Array.isArray(dependencies))
            throw new Error('Invalid lesson dependencies');
        if(!Array.isArray(statements))
            throw new Error('The statements of a lesson must be an array');

        return {
            id: id,
            chapterId: chapterId,
            name: name,
            description: description,
            state: state,
            dependencies: requiredLessonIds.length ? requiredLessonIds : dependencies,
            lastModifiedBy: lastModifiedBy,
            createdBy: createdBy,
        };
    }
}