import React, {createRef} from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {setElement} from "../../statementsSlice";
import {FormControl} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    imagePreviewer: {
        minHeight: "200px",
        borderBottom: "4px solid lightgray",
        borderRight: "4px solid lightgray",
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        padding: "10px",
        margin: "15px",
        cursor: "pointer",
    },
    imgPreview: {
        textAlign: "center",
        margin: "20px",
        minHeight: "200px",
        width: "XYpx",
        borderLeft: "1px solid gray",
        borderRight: "1px solid gray",
        borderTop: "5px solid gray",
        borderBottom: "5px solid gray",
    }
}));

const ImageElementEditor = ({element}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const setValue = (key, value) => {
        dispatch(setElement({
            key: key,
            value: value
        }));
    }

    const inputRef = createRef();
    const divClicked = () => {
        inputRef.current.click();
    }

    const filePicked = () => {
        const input = inputRef.current;
        const file = input.files;
        const reader = new FileReader();
        if (file && file[0]) {
            reader.onload = e => {
                setValue("url", e.target.result);
            }
        }
        reader.readAsDataURL(file[0]);
    }
    return (
        <>
        <Grid item xs={12} sm={12} md={8} lg={8}>
            <div>
                <div
                    style={{outline: "solid 2px black"}}
                    className={classes.imgPreview}
                    onClick={divClicked}
                >
                    <img
                        style={{width: "100%", maxHeight: "100%"}}
                        src={element.url ?? null}
                        alt="Click here to upload image ..."
                    />
                </div>
                <FormControl>
                    <input
                        style={{visibility: "hidden", height: "0"}}
                        ref={inputRef}
                        type="file"
                        onInputCapture={filePicked}
                    />
                </FormControl>
            </div>
        </Grid>
            </>
    );
}

export default ImageElementEditor;