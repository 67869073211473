import React, {useEffect, useState} from "react";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem, Paper
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    selected: {
        cursor: "pointer",
        width: "95%",
        margin: "5px",
        padding: "2px",
        paddingLeft: "5px",
    },
    idle: {
        cursor: "pointer",
        padding: "5px",
        margin: "5px",
        width: "95%",
    }
}));

const Selectable = ({isSelected, actions, onSelected, children}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(isSelected);
    const [anchorEl, setAnchorEl] = useState(null);
    const mouseOver = (_) => setSelected(true);
    const mouseOut = (_) => setSelected(isSelected);
    const handleActionsToggle = event => setAnchorEl(event.currentTarget);
    const handleCloseActions = () => setAnchorEl(null);

    const onClick = (event) => {
        event.stopPropagation();
        dispatch(onSelected());
    }

    useEffect(() => {
        setSelected(isSelected);
    }, [isSelected])

    const getSecondaryAction = () => {
        if (actions === null) return null;
        return (
            <Grid item>
                <div>
                    <IconButton
                        aria-label="actions"
                        aria-owns={anchorEl ? 'user-menu' : null}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleActionsToggle}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseActions}
                    >
                        {actions.map((action) => (
                            <MenuItem key={action.name} onClick={action.callback}>
                                <ListItemIcon>
                                    {action.icon}
                                </ListItemIcon>
                                <ListItemText primary={action.name}/>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </Grid>
        )
    }

    return (
        <Paper onClick={onClick} onMouseOver={mouseOver} onMouseOut={mouseOut}
               elevation={selected ? 5 : 0}
               className={isSelected ? classes.selected : classes.idle}>
            <ListItem alignItems="center" component="div">
                <Grid container direction="row" alignItems="flex-start">
                    <div style={{width: "100%", height: "100%"}}>
                        {children}
                    </div>
                </Grid>
                {getSecondaryAction()}
            </ListItem>
        </Paper>
    );
}

export default Selectable;