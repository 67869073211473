import buildMakeVisits from "./Visits";
import {dateDisplayOptions} from "../../utils/DateUtils";

const isValidDate = (date) => {
    return !Number.isNaN(Date.parse(date));
}

const makeVisits = buildMakeVisits({
    isValidDate: isValidDate,
    dateDisplayOptions: dateDisplayOptions
});

const statistics = {
    makeVisits: makeVisits
};

export default statistics;