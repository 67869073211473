import makeChapterRepo from "./ChapterRepository";
import makeLessonRepo from "./LessonsRepository";
import makeStatementRepo from "./StatementRepository";
import makeUserRepo from "./UserRepository";
import makeStatisticsRepo from "./StatisticsRepository";
import checkError from "./ErrorHandling"
import Storage from "../utils/Storage";
import uuid from "../utils/uuid";

const baseUrl = process.env.REACT_APP_API_URL;

const isJson = (response) => {
    const contentType = response.headers.get('content-type');
    return contentType && contentType.indexOf('application/json') !== -1;
}

const idCreator = () => {
    return uuid.make();
}

// Make token accessible to all repos
let token = null;
try {
    token = Storage.read("user").token;
} catch(_) {}

const setToken = (newToken) => token = newToken;
const getToken = () => token;
const deleteToken = () => token = null;

const chapterBaseUrl = baseUrl + 'chapters/';
export const chapterRepository = makeChapterRepo({
    baseUrl: chapterBaseUrl, 
    uuid: idCreator, 
    isJson: isJson,
    getToken: getToken,
    checkError: checkError,
    makeLessonRepo: makeLessonRepo
});
export const lessonRepository = makeLessonRepo({
    baseUrl: baseUrl, 
    uuid: idCreator, 
    isJson: isJson,
    getToken: getToken,
    checkError: checkError
});
export const statementRepository = makeStatementRepo({
    baseUrl: baseUrl,
    uuid: idCreator,
    isJson: isJson,
    getToken: getToken,
    checkError: checkError,
});
export const userRepository = makeUserRepo({
    baseUrl: baseUrl + "users/",
    uuid: idCreator,
    isJson: isJson,
    setToken: setToken,
    getToken: getToken,
    deleteToken: deleteToken,
    checkError: checkError,
});

export const statisticsRepository = makeStatisticsRepo({
    baseUrl: baseUrl,
    uuid: idCreator,
    isJson: isJson,
    getToken: getToken,
    checkError: checkError
});

const repositoryService = Object.freeze({
    chapterRepository,
    lessonRepository,
    statementRepository,
    userRepository,
    statisticsRepository,
});

export default repositoryService;
