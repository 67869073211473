export default function buildMakeWidgetFactory({}) {
    const error = "Creating the widget factory failed! ";
    let errorDetails = "";

    const isValidData = (data) => {
        let isValid = true;
        if (!data.name || (typeof data.name !== "string"
            && !(data.name instanceof String))) {
            data.name = "Unknown";
        }

        if (!data.description || (typeof data.description !== "string"
            && !(data.description instanceof String))) {
            data.description = "-";
        }

        if (typeof data.column === "undefined"
            || data.column === null || data.column < 0) {
            isValid = false;
            errorDetails += "Column number not found. ";
        }

        if (typeof data.index === "undefined"
            || data.index === null || data.index < 0) {
            isValid = false;
            errorDetails += "Index number not found. ";
        }

        return isValid;
    }

    return function makeWidgetFactory({createWidget}) {
        return {
            create: (data) => {
                if (!isValidData(data)) {
                    throw new Error(error + "\n" + errorDetails);
                }
                return {
                    id: data.id,
                    name: data.name,
                    description: data.description,
                    column: data.column,
                    index: data.index,
                    type: data.type,
                    isSelected: false,
                    ...createWidget(data)
                };
            }
        };
    }
}