import {resetUser} from "../features/authentication/authenticationSlice";

const authMiddleware = store => next => action => {
    const res = next(action);
    if(typeof res.payload !== "undefined" && typeof res.payload.error !== "undefined")
        if(res.payload.error.error && res.payload.error.shouldResetUser)
            store.dispatch(resetUser({error: res.payload.error.message}));
    return res;
}

export default authMiddleware;