import statementsService from "../usecases/statements";

export async function getStatementsList(lessonId, stepId) {
    return await statementsService.listStatements(lessonId, stepId);
}

export async function deleteStatements(statements, lessonId, stepId) {
    if (Array.isArray(statements)) {
        let results;
        try {
            results = await Promise.all(statements.map(async (statement) => {
                return await statementsService.deleteStatement(statement, lessonId, stepId);
            }));
        } catch (err) {
            err.error = true;
            results = [err];
        }
        return results ?? [];
    }
    return await statementsService.deleteStatement(statements.meta, lessonId, stepId);
}

export async function getStepsList(lesson_id) {
    return await statementsService.listSteps({lesson_id});
}

export async function updateStatement(statement, lessonId, stepId, dict) {
    const res = await statementsService.updateStatements({
        stepId: stepId,
        lessonId: lessonId,
        statements: [statement],
        dict: dict
    });
    if (Array.isArray(res)) return res[0];
    return res;
}

export async function reorderSteps(steps, sourceId, destinationId) {
    // reorder
    steps = statementsService.reorderSteps({
        steps: [...steps],
        sourceId: sourceId,
        destId: destinationId,
    });

    // not necessary but faster/easier to check here than let update throw
    if (steps.error) return steps;

    // update
    return await Promise.all(steps.map(async (step) => {
        return await statementsService.updateStep({
            step: step
        });
    }));
}

export async function reorderScreens(lessonId, stepId, {
    sourceScreenNb, destScreenNb, allStatements
}) {
    const stmtsByScreen = Object.values(allStatements);
    stmtsByScreen.splice(destScreenNb,0, stmtsByScreen.splice(sourceScreenNb, 1)[0]);
    const updatedStmtsByScreen = stmtsByScreen.map((s,i) => {
        return s.map(ss => {return {...ss, screen: i};})
    });
    const results = await Promise.all(updatedStmtsByScreen.flat().map(async us => {
        return await updateStatement(us, lessonId, stepId, {screen: us.screen});
    }));
    results.forEach(r => {
        if (r.error) return r;
    });
    return results;
}

export async function updateStep(step) {
    return await statementsService.updateStep({
        step: step,
    });
}

export async function deleteStep(step) {
    return await statementsService.deleteStep({
        step: step
    });
}

export async function reorderStatements(stepId, lessonId, statements, source, destination) {
    statements.splice(destination.index, 0, statements.splice(source.index, 1)[0]);
    let i = 0;
    statements.forEach((s) => s.index = i++);
    const results = await Promise.all(statements.map(async s => {
        return await updateStatement(s, lessonId, stepId, {index: s.index});
    }));
    results.forEach(r => {
        if (r.error) return r;
    });
    return results;
}

const StatementController = {
    getStepsList,
    updateStep,
    deleteStep,
    reorderSteps,
    getStatementsList,
    deleteStatements,
    reorderScreens,
    updateStatement,
    reorderStatements,
};

export default StatementController;