import makeError from "../../entities/error";
import makeStatement from "../../entities/statement";

export default function makeUpdateStatements({statementRepository}) {
    const setByPath = (obj, path, value) => {
        let schema = obj;
        const pList = path.split('.');
        const len = pList.length;
        for (let i = 0; i < len - 1; i++) {
            const elem = pList[i];
            if (!schema[elem]) schema[elem] = {};
            schema = schema[elem];
        }
        schema[pList[len - 1]] = value;
    }

    return async function updateStatements({lessonId, stepId, statements, dict}) {
        try {
            // Update statement values locally.
            const updatedStatements = statements.map((stmt) => {
                const copy = {...stmt};
                Object.keys(dict).forEach((key) => {
                    setByPath(copy, key, dict[key]);
                });
                return copy;
            });


            // Let the server know.
            const results = await Promise.all(updatedStatements.map(async (stmt, i) => {
                if (statements[i].id !== null && typeof statements[i].id !== "undefined") {
                    makeStatement({data: stmt});
                    return await statementRepository.updateStatement(lessonId, stepId, stmt);
                }
                else {
                    makeStatement({data: stmt});
                    return await statementRepository.addStatement(lessonId, stepId, stmt);
                }
            }));

            // Make sure returned values are ok and return updated statements.
            return updatedStatements.map((stmt, index) => {
                stmt.id = results[index];
                return makeStatement({
                    data: stmt
                });
            });
        } catch (err) {
            // Try to recover
            try {
                await Promise.all(statements.map(async (stmt) => {
                    makeStatement({data: stmt});
                    return await statementRepository.updateStatement(lessonId, stepId, stmt);
                }));
            } catch(err) {
                return makeError({
                    code: err.code ?? -1,
                    message: err.message,
                    description: err.description ?? "The application encountered a " +
                        "problem trying to update the properties of certain statements"
                });
            }

            // Return error
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered a problem trying to update" +
                    "the properties of certain statements: "
                    + statements.forEach((stmt) => stmt.id + ": " + stmt.title)
            });
        }
    }
}