export default function buildMakeStep({makeStatement, isValidId}) {
    let errorDetails = '';
    function isDataValid(stepData) {
        let isValid = true;
        if(typeof stepData.id === "undefined" || !isValidId) {
            isValid = false;
            errorDetails += "Cannot create a statement with a non valid id\n";
        }
        if(typeof !stepData.lessonId === "undefined" || !isValidId) {
            isValid = false;
            errorDetails += "Step does not belong to a valid lesson\n";
        }
        if(typeof stepData.name === "undefined" || stepData.name === "") {
            isValid = false;
            errorDetails += "Step must have a valid name\n";
        }
        if(typeof stepData.index === "undefined" || stepData.index < 0) {
            isValid = false;
            errorDetails += "Step must have a positive index order\n";
        }
        if(typeof stepData.statements !== "undefined"
            && !Array.isArray(stepData.statements)) {
            isValid = false;
            errorDetails += "No valid statements found for this step. ";
        } else {
            stepData.statements = stepData.statements ?? [];
        }
        return isValid;
    }

    return function makeStep({stepData}) {
        let error = "Invalid Step data detected.\n";

        if(!isDataValid(stepData))
            throw new Error(error + errorDetails);

        const stmts = stepData.statements.map((stmt) => {
            return makeStatement({
                data: {...stmt}
            });
        });


        return {
            id: stepData.id,
            lessonId: stepData.lessonId,
            name: stepData.name,
            description: stepData.description,
            index: stepData.index,
            statements: stmts,
            status: stepData.status ?? "pending",
        };
    }
}