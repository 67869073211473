// React
import React, {useState} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

// Styles
import {useStyles} from './LessonSingleStyles';

// Material ui
import {AddCircle} from "@material-ui/icons";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@material-ui/lab";
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Grid from "@material-ui/core/Grid";
import {AppBar, Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Redux
import {useDispatch} from "react-redux";
import {chapterDeleted, reorderLessons} from "../lessonsSlice";

// Custom
import Column from './Column';
import ChapterPopup from "./ChapterPopup";
import ChapterHelp from "./ChapterHelp";
import LessonSearch from "./LessonSearch";

const TabContainer = (props) => {
    return (
        <Grid
            container
            spacing={2}
            alignItems="stretch"
            justify="space-between"
            direction="column"
        >
            <Grid item xs={12}>
                <div style={{padding: 8 * 3}}>
                    {props.children}
                </div>
            </Grid>
        </Grid>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const LessonBoard = ({lessonBoard, lessonBoardName}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);
    const newChapter = {id: null, title: '', description: ''};

    const onDragEnd = result => {
        if (!result.destination) return;
        const {source, destination} = result;
        const reordering = {
            sourceChapterId: source.droppableId,
            destChapterId: destination.droppableId,
            indexBefore: source.index,
            indexAfter: destination.index
        };

        // Optimistic UI to avoid lagging the drag and drop operations
        dispatch(reorderLessons({reordering: reordering}));
    };
    const createNewChapter = () => {
        handleClose();
        toggleNewModal();
    };
    const editChapter = () => {
        handleClose();
        toggleEditModal();
    };
    const toggleEditModal = () => {
        setOpenEditModal(!openEditModal);
    };
    const toggleNewModal = () => {
        setOpenNewModal(!openNewModal);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const deleteChapter = () => {
        handleClose();
        const args = {lessonBoardName: lessonBoard.title, chapterId: lessonBoard.id};
        dispatch(chapterDeleted(args));
    };

    const speedDialActions = [
        {icon: <AddCircle/>, name: "Add Chapter", action: createNewChapter},
        {icon: <CreateIcon/>, name: "Edit Chapter", action: editChapter},
        {icon: <DeleteOutlineIcon/>, name: "Delete Chapter", action: deleteChapter}
    ];

    const tabs = [
        {tabName: "Search / Filter", content: <LessonSearch />},
        {tabName: "Notes", content: "Not Implemented"},
        {tabName: "Help", content: <ChapterHelp />}
    ];

    return (
        <div style={{height: "100%"}}>
            <ChapterPopup
                open={openNewModal}
                handleToggle={toggleNewModal}
                chapter={newChapter}
            />
            <ChapterPopup
                open={openEditModal}
                handleToggle={toggleEditModal}
                chapter={lessonBoard}
            />
            <Grid style={{height: "100%"}} container spacing={3} justify='space-around'
                  alignItems="stretch" direction="row">
                <Grid xs={12} sm={12} md={6} lg={6} style={{width: "50%"}} item>
                    <Card style={{height: "100%"}}>
                        <CardContent>
                            <div style={{width: "100%", height: "100%"}}>
                                <AppBar className={classes.root} position='static' color='default'>
                                    <Tabs
                                        value={tab}
                                        onChange={(event, value) => setTab(value)}
                                        scrollButtons='on'
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered
                                    >
                                        {tabs.map((tab, index) => {
                                            return <Tab key={index} label={tab.tabName}/>;
                                        })}
                                    </Tabs>
                                </AppBar>
                                {typeof tabs[tab] === 'undefined' ? <div> Something went wrong! </div> :
                                    <TabContainer>{tabs[tab].content}</TabContainer>}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} style={{minWidth: "50%", height: "100%"}} item display='flex'>
                    <DragDropContext onDragEnd={result => onDragEnd(result)}>
                        <div className={classes.lessonBoard}>
                            <Column
                                key={lessonBoardName}
                                lessons={lessonBoard.lessons}
                                chapter={lessonBoard}
                            />
                        </div>
                    </DragDropContext>
                </Grid>
            </Grid>

            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={classes.fab}
                icon={<SpeedDialIcon/>}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {speedDialActions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={action.action}
                    />
                ))}
            </SpeedDial>
        </div>
    );
};

export default LessonBoard;