// React
import React from 'react';

// Material UI
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useStyles} from "./ToastNotifierStyles";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {notificationClosed} from './notificationsSlice'
import {selectIsOpen, selectMessage, selectSeverity, selectAction} from "./notificationsSlice";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ToastNotifier = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const open = useSelector(selectIsOpen);
    const msg = useSelector(selectMessage);
    const severity = useSelector(selectSeverity);

    const handleClose = (event, reason) => {
        if(reason === 'clickaway')
            return;
        dispatch(notificationClosed());
    }

    return (
        <div className={classes.root}>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {msg}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ToastNotifier;