import authService from "../usecases/authentication";

export async function login(email, password, persist) {
    return await authService.login({
        email: email,
        password: password,
        persist: persist,
    });
}

export function getInitState() {
    const {isAuthenticated, user} = authService.getInitState();
    if (isAuthenticated && user.id !== null) {
        return {
            status: "idle",
            isAuthenticated: isAuthenticated,
            isAdmin: user.isAdmin,
            errMessage: "",
            errDescription: "",
            user: user,
        };
    }
    return {
        status: "idle",
        isAuthenticated: false,
        isAdmin: false,
        errMessage: "",
        errDescription: "",
        user: {
            id: null,
            username: "",
            email: "",
            firstName: "",
            lastName: "",
            role: "",
            token: "",
        }
    }
}

export function logout() {
    return authService.logout();
}

export async function register(newUser) {
    return await authService.register({
        newUser: newUser
    });
}

export async function update(updatedUser) {
    return await authService.update({
        user: updatedUser
    });
}

export async function list() {
    return await authService.list({});
}

export async function deleteUser(userId) {
    return await authService.deleteUser({
        userId: userId,
    });
}

export async function getUser(userId) {
    return await authService.getUser({
        userId: userId
    });
}

export async function addUser(user) {
    return await authService.addUser({
        user: user
    });
}

const AuthController = Object.freeze({
    login,
    logout,
    getInitState,
    register,
    update,
    list,
    deleteUser,
    getUser,
    addUser,
});

export default AuthController;