import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import AppProvider from "./components/AppProvider/AppProvider";
import RoutingComponent from "./layouts/Router";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
      <AppProvider>
          <Provider store={store}>
              <BrowserRouter>
                <RoutingComponent />
              </BrowserRouter>
          </Provider>
      </AppProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
