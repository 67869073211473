import makeChapter from "../entities/chapter";

export default function makeChapterRepo({baseUrl, uuid, isJson, getToken, checkError, makeLessonRepo}) {
    return Object.freeze({
        fetchAll,
        fetchById,
        add,
        update,
        deleteById
    })

//baseUrl = http://localhost:3000/api/chapters/

    /**
     * Fetches all chapters
     * @returns all chapters
     */
    async function fetchAll() {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl, {
            method: 'GET',
            headers: myHeaders,
        })).json();
        checkError(response);

        let chapterId;
        for (let i = 0; i < response.chapters.length; i++) {
            chapterId = response.chapters[i].id;
            response.chapters[i].lessons = await makeLessonRepo({
                baseUrl,
                uuid,
                isJson,
                getToken,
                checkError
            }).fetchAll(chapterId);
        }
        return response.chapters;
    }

    /**
     * Deletes a chapter given its id
     * @param id : chapter id
     * @returns chapter id
     */
    async function deleteById({id}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());  //request requires admin permissions
        const response = await (await fetch(baseUrl + id.toString(), {
            method: 'DELETE',
            headers: myHeaders,
        })).json();

        checkError(response);
        return Number(response.id);
    }

    /**
     * Adds a new chapter
     * @param name : name of new chapter
     * @param description : description of new chapter
     * @param id : id of new chapter
     * @returns lesson id
     */
    async function add({name, description}) {
        makeChapter({name: name})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await
            fetch(baseUrl, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    "name": name,
                    "description": description
                })
            })).json();
        checkError(response);
        return response.id;
    }

    /**
     * Fetches a chapter
     * @param id : chapter id
     * @returns chapter with id entered as parameter
     */
    async function fetchById({id}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const chapter = await (await fetch(baseUrl + id.toString(), {
            method: 'GET',
            headers: myHeaders,
        })).json();
        checkError(chapter)
        chapter.lessons = await makeLessonRepo({baseUrl, uuid, isJson, getToken}).fetchAll(id);
        return chapter;
    }

    /**
     * Modifies a chapter
     * @param id : chapter id
     * @param name : chapter new name
     * @param description : chapter new description
     * @returns chapter id
     */
    async function update({id, name, description}) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl + id.toString(), {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify({name, description})
        })).json();
        checkError(response);
        return response.id;
    }
}

