import {users} from "./Seeder"
import {chapters} from "./Seeder";
import {statements} from "./Seeder";

export default function makeStatMock(sleep) {
    let data_users = {};
    users.forEach(user => data_users[user.id] = user.visits);

    let data_lessons = [];
    chapters.forEach(ch => ch.lessons.forEach(l => data_lessons.push({
            id: l.id,
            avgCompletionRate: l.avgCompletionRate,
            name: l.name,
        }
    )));

    let data_statements = [];
    statements.forEach(st => data_statements.push({
        id: st.id,
        name: st.name,
        avgErrorRate: st.avgErrorRate,
        avgRevelationRate: st.avgRevelationRate,
    }));

    let data_avgUpTime = [
        {"2021-05-03T00:31:31.031Z": "30"},
        {"2021-04-05T00:31:31.031Z": "12"},
        {"2021-05-12T00:31:31.031Z": "5"},
        {"2021-05-10T00:31:31.031Z": "27"},
        {"2021-05-09T00:31:31.031Z": "15"},
        {"2021-05-08T00:31:31.031Z": "35"},
        {"2021-05-07T00:31:31.031Z": "3"},
        {"2021-05-06T00:31:31.031Z": "40"},
        {"2021-05-02T00:31:31.031Z": "29"},
    ];


    async function fetchUserVisits({user_id}) {
        await sleep(600);
        return data_users[user_id];
    }

    async function fetchLessonsCompletionRates() {
        await sleep(600);
        return data_lessons;
    }

    async function fetchStatementErrorRates() {
        await sleep(600);
        return data_statements;
    }

    async function fetchStatementHintRates() {
        await sleep(600);
        return data_statements;
    }

    async function fetchUpTimes() {
        await sleep(600);
        return data_avgUpTime;
    }

    return Object.freeze({
        fetchUserVisits,
        fetchLessonsCompletionRates,
        fetchStatementErrorRates,
        fetchStatementHintRates,
        fetchUpTimes,
    });
}