import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {notificationCreated} from '../notifications/notificationsSlice'
import StatementController from "../../controllers/StatementController";
import {lessonStateChanged} from "../lessons/lessonsSlice";
import {defaultStatement, getDefaultWidget, prepareStatements, unselectWidget} from "../../presenters/EditorPresenter";

const initialState = {
    chapterId: null,
    lessonId: null,
    status: 'idle',
    error: null,
    steps: {},
    screenNb: 1,
    stepId: null,
    stepModalOpen: false,
    screenStatements: [defaultStatement()],
    onEditStep: {
        id: null,
        lessonId: null,
        name: "",
        description: "",
        index: -1,
        advised: [],
        statements: [],
    }
}

const handleError = (error, action, thunkAPI) => {
    const notification = {
        message: error.message,
        severity: 'error',
        action: null,
    }
    thunkAPI.dispatch(notificationCreated(notification));
    return thunkAPI.rejectWithValue(error);
}

const sendNotification = (message, severity, thunkAPI) => {
    const notification = {
        message: message,
        severity: severity,
        action: null
    };
    thunkAPI.dispatch(notificationCreated(notification));
}

const setLessonDirty = (thunkAPI) => {
    const state = thunkAPI.getState();
    const chapterId = state.statements.chapterId;
    const lessonId = state.statements.lessonId;
    thunkAPI.dispatch(lessonStateChanged({
        lessonId: lessonId,
        chapterId: chapterId,
        oldState: "",
        newState: "dirty"
    }));
}

const selectedStatement = (screenStatements) => {
    try {
        return screenStatements.find(s => s.isSelected);
    } catch (err) {
        throw err;
    }
}

const selectedWidget = (screenStatements) => {
    const statement = selectedStatement(screenStatements);
    return statement?.widgets.find(w => w.isSelected);
}

const selectedElement = (screenStatements) => {
    const widget = selectedWidget(screenStatements);
    switch (widget?.type) {
        case "information":
            switch (widget.subtype) {
                case "generic":
                    return widget.body.isSelected ? widget.body : null;
                case "hint":
                    return widget.body.isSelected ? widget.body : null;
            }
            break;
        case "question":
            switch (widget.subtype) {
                case "single_question":
                    return widget.question.isSelected ? widget.question : null;
                case "mcq": {
                    if (widget.question.isSelected) return widget.question;
                    return widget.choices.find(e => e.isSelected);
                }
            }
            break;
    }
    return null;
}

const selectStatement = (screenStatements, statementId) => {
    const statement = screenStatements.find(s => s.id === statementId);

    if (typeof statement === "undefined") {
        screenStatements[0].isSelected = true;
        return screenStatements[0];
    }

    if (statement.isSelected) return statement;
    const old = selectedStatement(screenStatements);
    if (old) {
        old.isSelected = false;
        old.widgets.forEach(w => unselectWidget(w));
    }
    statement.isSelected = true;
    return statement;
}

const selectWidget = (screenStatements, statement, widgetId) => {
    const widget = statement.widgets.find(w => w.id === widgetId);

    if (typeof widget === "undefined") {
        statement.widgets[0].isSelected = true;
        return statement.widgets[0];
    }

    if (widget.isSelected) return widget;

    unselectWidget(selectedWidget(screenStatements));
    widget.isSelected = true;
    return widget;
}

const selectElement = (widget, selected_old, elementId) => {
    if (selected_old && typeof selected_old !== "undefined") {
        if (selected_old.id === elementId) {
            selected_old.isSelected = true;
            return selected_old;
        } else selected_old.isSelected = false;
    }

    switch (widget.type) {
        case "information": {
            switch (widget.subtype) {
                case "generic": {
                    widget.body.isSelected = true;
                    return widget.body;
                }
                case "hint": {
                    widget.body.isSelected = true;
                    return widget.body;
                }
            }
        }
            break;
        case "question": {
            switch (widget.subtype) {
                case "single_question": {
                    widget.question.isSelected = true;
                    return widget.question;
                }
                case "mcq": {
                    if (widget.question.id === elementId) {
                        widget.question.isSelected = true;
                        return widget.question;
                    }

                    const element = widget.choices.find(c => c.id === elementId);
                    if (typeof element === "undefined") {
                        widget.question.isSelected = true;
                        return widget.question;
                    }
                    element.isSelected = true;
                    return element;
                }
            }
        }
    }
    return null;
}

export const fetchAllSteps = createAsyncThunk(
    "statements/fetchAllSteps",
    async ({lesson_id}, thunkAPI) => {
        const res = await StatementController.getStepsList(lesson_id);
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            steps: res, lessonId: lesson_id,
        };
    }
)

export const stepSaved = createAsyncThunk(
    "statements/createStep",
    async ({step}, thunkAPI) => {
        // Create new step
        const state = thunkAPI.getState();
        const lessonId = state.statements.lessonId;
        const res = await StatementController.updateStep(step);
        if (res.error) return handleError(res, null, thunkAPI);

        // Set lesson state as dirty if there was no error
        setLessonDirty(thunkAPI);

        return {
            step: res,
            lessonId: lessonId,
        };
    }
)

export const stepDeleted = createAsyncThunk(
    "statements/deleteStep",
    async ({step}, thunkAPI) => {
        const res = await StatementController.deleteStep(step);
        if (res.error) return handleError(res, null, thunkAPI);
        setLessonDirty(thunkAPI);

        return {
            lessonId: thunkAPI.getState().statements.lessonId,
            stepId: res
        };
    }
)

export const stepsReordered = createAsyncThunk(
    "statements/stepsReordered",
    async ({dragResult}, thunkAPI) => {
        const steps = dragResult.sortedSteps;
        const res = await StatementController.reorderSteps(steps,
            dragResult.sourceId, dragResult.destId);

        if (!Array.isArray(res) && res.error) return handleError(res, null, thunkAPI);
        else if (Array.isArray(res)) {
            res.forEach((r) => {
                if (r.error) return handleError(r, null, thunkAPI);
            });
        }

        setLessonDirty(thunkAPI);
        return {
            lessonId: dragResult.lessonId,
            steps: res
        };
    }
)

export const fetchAllStatements = createAsyncThunk(
    'statements/fetchAllStatements',
    async ({lessonId, stepId}, thunkAPI) => {
        let res = [];
        const state = thunkAPI.getState();
        const step = state.statements.steps[lessonId].find(s => s.id === stepId);
        if (step?.statements.length) {
            res = step.statements;
        } else {
            res = await StatementController.getStatementsList(
                lessonId, stepId
            );
        }
        if (res.error) return handleError(res, null, thunkAPI);
        return {statements: res, lessonId: lessonId, stepId: stepId};
    }
);

export const deleteStatements = createAsyncThunk(
    'statements/deleteStatements',
    async ({statements, stepId}, thunkAPI) => {
        // Try deleting
        const state = thunkAPI.getState();
        const results = await StatementController.deleteStatements(
            statements, state.statements.lessonId, stepId
        );
        let deleted = [];

        // Verify
        results.forEach((res, index) => {
            if (res.error) {
                handleError(res, null, thunkAPI);
            } else {
                deleted.push(index);
            }
        });

        // Send action to modify UI
        const deletedStatements = deleted.map(index => statements[index]);
        setLessonDirty(thunkAPI);
        return {
            deleted: deletedStatements,
            stepId: stepId,
            lessonId: state.statements.lessonId
        };
    }
);

export const statementSaved = createAsyncThunk(
    "statements/statementSaved",
    async ({statement, stepId}, thunkAPI) => {
        const state = thunkAPI.getState();
        const oldId = statement.id;
        const step = state.statements.steps[state.statements.lessonId].find(s => s.id === stepId);
        const statementIndex = step.statements.findIndex(s => s.id === statement.id);
        const stmt = JSON.parse(JSON.stringify(statement));
        stmt.id = statementIndex >= 0 ? stmt.id : null;
        const res = await StatementController.updateStatement(
            stmt, state.statements.lessonId, stepId, {},
        );
        if (res.error) return handleError(res, null, thunkAPI);
        sendNotification("Statement saved successfully", "success", thunkAPI);
        setLessonDirty(thunkAPI);
        stmt.id = res.id;
        return {
            oldId: oldId,
            lessonId: state.statements.lessonId,
            stepId: stepId,
            statement: stmt,
            statementIndex: statementIndex >= 0 ? statementIndex : null,
        }
    }
);

export const statementsReordered = createAsyncThunk(
    "statements/statementsReordered",
    async ({source, destination, stepId, statements}, thunkAPI) => {
        const state = thunkAPI.getState();
        const res = await StatementController.reorderStatements(
            stepId, state.statements.lessonId,
            JSON.parse(JSON.stringify(statements)),
            source, destination
        );

        if (res.error) return handleError(res, null, thunkAPI);
        setLessonDirty(thunkAPI);
        return {
            statements: res,
            stepId: stepId,
            lessonId: state.statements.lessonId,
        }
    }
);

export const reorderScreens = createAsyncThunk(
    'statements/reorderScreens',
    async ({
               stepId, lessonId, allStatements,
               sourceScreenNb, destScreenNb,
               sourceStatements, destStatements
           }, thunkAPI) => {

        const res = await StatementController.reorderScreens(
            lessonId,
            stepId,
            {
                sourceScreenNb, destScreenNb, allStatements
            });

        // check for error and notify user
        if (res.error) {
            handleError(res, null, thunkAPI);
        }

        return {
            statements: res,
            lessonId: lessonId,
            stepId: stepId,
        };
    }
);

const statementsSlice = createSlice({
    name: 'statements',
    initialState: initialState,
    reducers: {
        setChapterId: (state, action) => {
            state.chapterId = action.payload.chapterId
        },
        setOnEditStatements: (state, action) => {
            state.onEditStatements = action.payload.statements;
        },
        toggleStepModal: (state, action) => {
            state.stepModalOpen = !state.stepModalOpen;
        },
        setOnEditStep: (state, action) => {
            if (action.payload.step.id === null) state.onEditStep = {
                ...initialState.onEditStep,
                ...action.payload.step
            };
            else state.onEditStep = {...action.payload.step};
        },
        setScreenStatements: (state, action) => {
            state.stepId = action.payload.stepId;
            state.screenNb = action.payload.screenNb;
            state.screenStatements =
                prepareStatements(JSON.parse(JSON.stringify(action.payload.screenStatements)));
        },
        setStatement: (state, action) => {
            (selectedStatement(state.screenStatements))[action.payload.key]
                = action.payload.value;
        },
        setWidget: (state, action) => {
            const key = action.payload.key;
            const value = action.payload.value;
            const widget = selectedWidget(state.screenStatements);
            widget[key] = value;
            if (key === "subtype") {
                const w_id = widget.id;
                const w_index = widget.index;
                const w_column = widget.column;
                const statement = selectedStatement(state.screenStatements);
                const index = statement.widgets.indexOf(widget);
                statement.widgets[index] = getDefaultWidget(value);
                statement.widgets[index].id = w_id;
                statement.widgets[index].index = w_index;
                statement.widgets[index].column = w_column;
            }
        },
        setElement: (state, action) => {
            (selectedElement(state.screenStatements))[action.payload.key]
                = action.payload.value;
        },
        statementSelected: (state, action) => {
            selectStatement(state.screenStatements, action.payload.statementId);
        },
        addStatement: (state, action) => {
            const newStatement = defaultStatement();
            newStatement.screen = state.screenNb;
            newStatement.index = state.screenStatements.length;
            newStatement.stepId = state.stepId;
            state.screenStatements.push(newStatement);
            selectStatement(state.screenStatements, newStatement.id);
        },
        widgetSelected: (state, action) => {
            const statement = selectStatement(state.screenStatements, action.payload.statementId);
            selectWidget(state.screenStatements, statement, action.payload.widgetId);
        },
        elementSelected: (state, action) => {
            const selected_old = selectedElement(state.screenStatements);
            let statement = selected_old;
            if (!selected_old || selected_old.id !== action.payload.statementId)
                statement = selectStatement(state.screenStatements, action.payload.statementId);

            const widget_old = selectedWidget(state.screenStatements);
            let widget = widget_old;
            if (typeof widget_old === "undefined" || widget_old.id !== action.payload.widgetId) {
                unselectWidget(widget_old);
                widget = selectWidget(state.screenStatements, statement, action.payload.widgetId);
            }

            const _ = selectElement(widget, selected_old, action.payload.elementId);
        },
        restoreStatement: (state, action) => {
            const step = state.steps[state.lessonId].find(s => s.id === action.payload.stepId);
            const source = step.statements?.find(s => s.id === action.payload.statementId);
            let targetIndex = state.screenStatements?.findIndex(s => s.id === action.payload.statementId);
            if (typeof source !== "undefined" &&
                typeof targetIndex !== "undefined" && targetIndex >= 0) {
                state.screenStatements[targetIndex] = JSON.parse(JSON.stringify(source));
            }
        },
        reorderWidgets: (state, action) => {
            const source = action.payload.source;
            const destination = action.payload.destination;
            const statement = state.screenStatements?.find(s => s.id === action.payload.statementId);
            if (typeof statement === "undefined") return;
            if (destination.droppableId === source.droppableId) { // same column
                statement.widgets.splice(destination.index, 0,
                    statement.widgets.splice(source.index, 1)[0]);
                statement.widgets.forEach((w, i) => w.index = i);
            } else {
                const dest_col = source.droppableId === "1" ? 2 : 1;
                const source_col = dest_col === 1 ? 2 : 1;
                const source_column = statement.widgets.map(w => {
                    return w.column === source_col ? w : null;
                });
                const dest_column = statement.widgets.map(w => {
                    return w.column === dest_col ? w : null;
                });
                source_column[source.index].column = dest_col;
                dest_column.splice(destination.index, 0, source_column[source.index]);
                source_column.filter(w => w !== null).forEach((w, i) => {
                    if (w) w.index = i;
                });
                dest_column.filter(w => w !== null).forEach((w, i) => {
                    if (w) w.index = i;
                });
            }
        },
        addWidget: (state, action) => {
            const statement = state.screenStatements.find(s => s.id === action.payload.statementId);
            if (typeof statement !== "undefined") {
                const new_widget = getDefaultWidget("generic");

                new_widget.index = statement.widgets.length > 0 ?
                    statement.widgets[statement.widgets.length - 1].index + 1 : 0;

                const selectedWidget = statement?.widgets.find(w => w.isSelected);
                if (typeof selectedWidget !== "undefined")
                    unselectWidget(selectedWidget);

                statement.widgets.push(new_widget);
                selectWidget(state.screenStatements, statement, new_widget.id);
            }
        },
        removeWidget: (state, action) => {
            const statement = state.screenStatements.find(s => s.id === action.payload.statementId);
            const widgetIndex = statement?.widgets.findIndex(w => w.id === action.payload.widgetId);
            statement?.widgets.splice(widgetIndex, 1);
        }
    },
    extraReducers: {
        [fetchAllStatements.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchAllSteps.pending]: (state, action) => {
            state.status = "loading";
        },
        [fetchAllStatements.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            const lessonId = action.payload.lessonId;
            const stepId = action.payload.stepId;
            let step = state.steps[lessonId].find(s => s.id === stepId);
            step.statements = action.payload.statements;
            step.status = "idle";
        },
        [fetchAllSteps.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.lessonId = action.payload.lessonId;
            state.steps[action.payload.lessonId] = action.payload.steps;
            state.lessonId = action.payload.lessonId;
        },
        [fetchAllStatements.rejected]: (state, action) => {
            state.status = 'failed';
        },
        [fetchAllSteps.rejected]: (state, action) => {
            state.status = "failed";
        },
        [deleteStatements.pending]: (state, action) => {
            state.status = 'loading';
        },
        [deleteStatements.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            const lessonId = action.payload.lessonId;
            const stepId = action.payload.stepId;
            let step = state.steps[lessonId].find(s => s.id === stepId);

            step.statements = step.statements.filter(s => {
                return !action.payload.deleted.some((del) => {
                    return del.id === s.id;
                });
            });

            state.screenStatements = state.screenStatements.filter(s => {
                return !action.payload.deleted.some((del) => {
                    return del.id === s.id;
                });
            });
        },
        [deleteStatements.rejected]: (state, action) => {
            state.status = 'failed';
        },
        [reorderScreens.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            const lessonId = action.payload.lessonId;
            const stepId = action.payload.stepId;
            const step = state.steps[lessonId].find(s => s.id === stepId);
            step.statements = action.payload.statements;
        },
        [stepSaved.fulfilled]: (state, action) => {
            const step = action.payload.step;
            state.steps[action.payload.lessonId] =
                state.steps[action.payload.lessonId]
                    .filter(s => s.id !== step.id).concat(step);
        },
        [stepDeleted.fulfilled]: (state, action) => {
            const stepId = action.payload.stepId;
            state.steps[action.payload.lessonId] =
                state.steps[action.payload.lessonId]
                    .filter(s => s.id !== stepId);
        },
        [stepsReordered.fulfilled]: (state, action) => {
            state.steps[action.payload.lessonId] = action.payload.steps;
        },
        [statementSaved.fulfilled]: (state, action) => {
            const step = state.steps[action.payload.lessonId].find(s =>
                s.id === action.payload.stepId);

            if (action.payload.statementIndex !== null) {
                step.statements[action.payload.statementIndex] = action.payload.statement;
            } else {
                step.statements = [...step.statements, action.payload.statement];
            }

            const screenStatement = state.screenStatements.find(s => s.id === action.payload.oldId);
            if (typeof screenStatement !== "undefined")
                screenStatement.id = action.payload.statement.id;
        },
        [statementsReordered.fulfilled]: (state, action) => {
            const step = state.steps[action.payload.lessonId]
                .find(s => s.id === action.payload.stepId);
            const reordered = action.payload.statements;
            step.statements = step.statements.map(s => {
                const query = reordered.find(rs => rs.id === s.id);
                if (typeof query !== "undefined") return query;
                return s;
            });
        }
    }
});

export const selectAllLessons = (state) => state.lessons.chapters[state.statements.chapterId]?.lessons ?? [];
export const selectAllStatements = (state, lessonId, stepId) => {
    let statements = null;
    state.statements.steps[lessonId].some((step) => {
        if (step.id === stepId) {
            statements = step.statements;
            return true;
        }
        return false;
    });
    if (typeof (statements) === 'undefined' || statements === null)
        return [];
    return statements;
}
export const selectAllSteps = (state, lessonId) => {
    const steps = state.statements.steps[lessonId];
    if (typeof (steps) === 'undefined' || steps === null) return [];
    return steps;
}
export const selectStep = (state, lessonId, stepId) => {
    return state.statements.steps[lessonId]?.find(s => s.id === stepId);
}
export const selectOnEditStep = (state) => state.statements.onEditStep;
export const selectOpenEditStep = (state) => state.statements.stepModalOpen;
export const selectStatus = (state) => state.statements.status;
export const selectOnEditStatements = (state) => state.statements.screenStatements;
export const selectOnEditStatement = (state) => selectedStatement(state.statements.screenStatements)
export const selectOnEditWidget = (state) => selectedWidget(state.statements.screenStatements);
export const selectOnEditElement = (state) => selectedElement(state.statements.screenStatements);
export const selectEditorStepId = (state) => state.statements.stepId;

export const {
    setChapterId, setOnEditStatements, toggleStepModal, setOnEditStep,
    setStatement, setWidget, setElement,
    statementSelected, widgetSelected, elementSelected,
    setScreenStatements, restoreStatement, reorderWidgets,
    addWidget, removeWidget, addStatement,
} = statementsSlice.actions;
export default statementsSlice.reducer;