import React from "react";
import {FormControl, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setWidget} from "../../../statementsSlice";

const SingleQuestionWidgetEditor = ({widget}) => {
    const dispatch = useDispatch();
    const setValue = (key, value) => {
        dispatch(setWidget({
            key: key,
            value: value,
        }));
    }

    return (
        <div>
            <FormControl style={{margin: '5px', width: '80%',}}>
                <TextField
                    onChange={(event) =>
                        setValue("solution", event.target.value)}
                    id='solution'
                    style={{width: "80%"}}
                    label="Give the solution of this question"
                    helperText="The question is defined using the element"
                    value={widget.solution ?? ""}
                />
            </FormControl>
            <br /> <br />
        </div>
    );
}

export default SingleQuestionWidgetEditor;