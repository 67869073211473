import React from "react";
import MCQWidget from "./MCQWidget";
import SingleQuestionWidget from "./SingleQuestionWidget";

const QuestionWidget = ({widget, statementId}) => {
    switch (widget.subtype) {
        case "mcq":
            if(widget.choices)
                return <MCQWidget widget={widget} statementId={statementId}/>;
        break;
        case "single_question":
            return <SingleQuestionWidget widget={widget} statementId={statementId}/>;
        default:
            return (<h6>Unknown Widget</h6>);
    }
}

export default QuestionWidget;