import questionWidgetFactory from "./questionWidgets";
import informationWidgetFactory from "./informationWidgets";
import buildMakeWidgetFactory from "./WidgetFactory";
import buildMakeWidget from "./Widget";
import buildMakeStatement from "./Statement";
import buildMakeStep from "./Step";

const isValidId = (id) => true;

const makeWidgetFactory = buildMakeWidgetFactory({});

const makeWidget = buildMakeWidget({
    questionWidgetFactory: questionWidgetFactory,
    informationWidgetFactory: informationWidgetFactory,
    makeWidgetFactory: makeWidgetFactory,
});

export const makeStatement = buildMakeStatement({
    makeWidget: makeWidget
});

export const makeStep = buildMakeStep({
    isValidId: isValidId,
    makeStatement: makeStatement,
})

export default makeStatement;