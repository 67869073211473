import makeError from "../../entities/error";

export default function makeGetLessonCompletionRates({statRepository}) {
    return async function getCompletionRates({}) {
        try {
            return await statRepository.fetchLessonsCompletionRates();
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description,
            });
        }
    }
}