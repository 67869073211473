// React
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

// Styles
import { useStyles } from "./WorkspaceStyles";
import {ToastNotifier} from "../../features/notifications";

const Workspace = ({ children, opened }) => {
    const classes = useStyles();
    return (
        <main
            className={classNames(classes.content, classes[`content-left`], {
                [classes.contentShift]: opened,
                [classes[`contentShift-left`]]: opened
            })}
        >
            {children}
        </main>
    );
};

Workspace.prototypes = {
    children: PropTypes.node.isRequired,
    opened: PropTypes.bool
};

export default Workspace;