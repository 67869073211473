import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {useDispatch, useSelector} from "react-redux";
import {selectLessonById} from "../../lessons/lessonsSlice";
import {fetchAllSteps, selectAllSteps, selectStatus,
    setOnEditStep, toggleStepModal, stepsReordered} from "../statementsSlice";

import {sortSteps, optimisticStepReorder} from "../../../presenters/StatementsPresenter";

import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import {CardHeader} from "@material-ui/core";

import StepPopup from "./StepPopup";
import NestedList from "./ScreenList";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '99%',
        justifyContent: "center",
    },
    list: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '20px',
        width: '80%',
        height: '100%',
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.paper,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function StepList({}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    let {lesson_id} = useParams();
    if (!Number.isNaN(Number(lesson_id))) lesson_id = Number(lesson_id);
    const lesson = useSelector((state) => selectLessonById(state, lesson_id));
    const steps = useSelector((state) => selectAllSteps(state, lesson_id));
    const status = useSelector(selectStatus);

    useEffect(() => {
        if (steps.length === 0 && (status === "idle" || status === "succeeded")) {
            dispatch(fetchAllSteps({lesson_id: lesson_id}));
        }
    }, []);

    let sortedSteps = [];
    if (steps.length) sortedSteps = sortSteps([...steps]);

    const addStepClicked = () => {
        dispatch(setOnEditStep({
            step: {
                id: null,
                lessonId: lesson_id,
                index: sortedSteps.length > 0 ?
                    sortedSteps[sortedSteps.length-1].index+1 : 0
            }
        }));
        dispatch(toggleStepModal({}));
    }
    const dragEnded = (result) => {
        if(result.destination === null) return;
        const sourceId = result.draggableId;
        const destId = sortedSteps[result.destination.index].id;

        optimisticStepReorder(sortedSteps, result.source, result.destination);
        dispatch(stepsReordered({
            dragResult: {
                lessonId: lesson_id,
                sourceId: sourceId,
                destId: destId,
                sortedSteps: sortedSteps
            }
        }))
            .catch(() => { sortedSteps = sortSteps([...steps]) });
    }

    return (
        <Box
            className={classes.root}
            display="flex"
        >
            <StepPopup lesson={lesson}/>
            <Card
                className={classes.list}
                m="auto"
            >
                <CardHeader
                    disableTypography={true}
                    action={
                        <div>
                            <IconButton aria-label="settings" onClick={() => addStepClicked()}>
                                <AddCircleOutlineIcon color="primary" fontSize="large"/>
                            </IconButton> <br/>
                            Add Step
                        </div>
                    }
                    title={<Typography align="center" variant="h6" gutterBottom>
                        {lesson.name}
                    </Typography>}
                    subheader={<Typography align="center" variant="subtitle1" gutterBottom>
                        {lesson.description}
                    </Typography>}
                />
                <Divider/>
                <hr/>
                <DragDropContext onDragEnd={dragEnded}>
                    <Droppable droppableId={`${lesson.id}`}>
                        {(provided, snapshot) => {
                            return (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{overflowY: "scroll", height: "100%"}}
                                >
                                    <List
                                        component="h2" aria-labelledby="steps-list"
                                    >
                                        {sortedSteps.map((step, index) => (
                                            <Draggable
                                                key={step.id}
                                                draggableId={`${step.id}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => {
                                                    return (
                                                        <NestedList
                                                            key={step.id}
                                                            stepId={step.id}
                                                            lessonId={lesson.id}
                                                            provided={provided}
                                                        />
                                                    )
                                                }}
                                            </Draggable>
                                        ))}
                                    </List>
                                    {provided.placeholder}
                                </div>
                            )
                        }}

                    </Droppable>
                </DragDropContext>
            </Card>
        </Box>
    )

}