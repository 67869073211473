import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectProfilePopupOpen, toggleProfilePopup} from "./homeSlice";
import {Dialog, DialogActions, DialogContentText, DialogTitle, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {userUpdated} from "../authentication/authenticationSlice";
import {notificationCreated} from "../notifications/notificationsSlice";

const ProfilePopup = ({user}) => {
    const open = useSelector((state) => selectProfilePopupOpen(state));
    const dispatch = useDispatch();
    const [username, setUsername] = useState(user.username);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState("");
    const [passwordVer, setPasswordVer] = useState("");
    const closeModal = (username, firstName, lastName) => {
        setUsername(username ?? user.username);
        setFirstName(firstName ?? user.firstName);
        setLastName(lastName ?? user.lastName);
        setEmail(email ?? user.email);
        setPassword(password ?? "");
        setPasswordVer(passwordVer ?? "");
        dispatch(toggleProfilePopup({}));
    }
    const updateUser = () => {
        if(password && password.length < 5){
            const notification = {
                message: "Password must be more than 5 characters long",
                severity: "error",
                action: null
            };
            dispatch(notificationCreated(notification));
            return;
        }
        if(password !== passwordVer){
            const notification = {
                message: "Password confirmation failed. Check your password",
                severity: "warning",
                action: null
            };
            dispatch(notificationCreated(notification));
            return;
        }
        const updatedUser = {
            ...user,
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password
        };
        dispatch(userUpdated({
            updatedUser: updatedUser
        }));
        closeModal(username, firstName, lastName, email);
    }

    return (
        <Dialog fullWidth={true} open={open} aria-labelledby="profile-dialog-popup">
            <DialogTitle>
                {user.email}
            </DialogTitle>
            <Divider/>
            <Box mr={2} ml={2} mt={0}>
                <DialogContentText style={{marginTop: "20px"}}>
                    Edit your <strong>profile</strong> information.
                    Press <strong>Done!</strong> to save.
                </DialogContentText>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setUsername(event.target.value)}
                            autoFocus
                            id="username"
                            label="Username"
                            type="text"
                            fullWidth
                            value={username}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setFirstName(event.target.value)}
                            autoFocus
                            id="firstname"
                            label="First Name"
                            type="text"
                            fullWidth
                            value={firstName}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setLastName(event.target.value)}
                            autoFocus
                            id="lastname"
                            label="Last Name"
                            type="text"
                            fullWidth
                            value={lastName}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setEmail(event.target.value)}
                            autoFocus
                            id="email"
                            label="E-mail"
                            type="text"
                            fullWidth
                            value={email}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setPassword(event.target.value)}
                            autoFocus
                            id="password"
                            label="New password"
                            type="password"
                            fullWidth
                            value={password}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setPasswordVer(event.target.value)}
                            autoFocus
                            id="passwordVer"
                            label="Verify new password"
                            type="password"
                            fullWidth
                            value={passwordVer}
                        />
                    </Grid>
                </Grid>
            </Box>
            <DialogActions>
                <Button
                    onClick={closeModal}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={updateUser}
                    color="primary">
                    Done!
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProfilePopup;