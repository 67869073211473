export default function makeStatementRepo({baseUrl, uuid, isJson, getToken, checkError}) {
    return Object.freeze({
        fetchAllSteps,
        fetchStepById,
        addStep,
        updateStep,
        deleteStepById,
        fetchStatementById,
        fetchAllStatements,
        addStatement,
        updateStatement,
        deleteStatementById,
        reorder
    });

    /**
     * Fetches all steps from a lesson
     * @param lessonId : lesson id
     * @returns all steps from lesson of which id = lessonId
     */
    async function fetchAllSteps(lessonId) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl + 'lessons/' + lessonId.toString() + '/steps', {
            method: 'GET',
            headers: myHeaders,
        })).json();
        checkError(response);

        for (let i = 0; i < response.steps.length; i++)
            response.steps[i].statements = await fetchAllStatements(lessonId, response.steps[i].id);

        return response.steps;
    }

    /**
     * Deletes a step given its id
     * @param lessonId
     * @param stepId : step id
     * @returns step id
     */
    async function deleteStepById(lessonId, stepId) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl + 'steps/' + stepId.toString(), {
            method: 'DELETE',
            headers: myHeaders,
        })).json()
        checkError(response);
        response.id = Number(response.id)
        return response.id;
    }

    /**
     * Adds a new step
     * @param lessonId
     * @param stepId
     * @param step : new step
     * @returns lesson id
     */
    async function addStep(lessonId, stepId, step) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl + 'lessons/' + step.lessonId.toString() + '/steps', {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "name": step.name,
                "description": step.description,
                "index": step.index
            })
        })).json()
        checkError(response);
        return response.id;
    }

    /**
     * Fetches a step
     * @param lessonId
     * @param stepId : new step
     * @returns step
     */
    async function fetchStepById(lessonId, stepId) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl + 'steps/' + stepId.toString(), {
            method: 'GET',
            headers: myHeaders,
        })).json()
        checkError(response);
        return response;
    }

    /**
     * Modifies a step
     * @returns step id
     * @param lessonId
     * @param stepId
     * @param step
     */
    async function updateStep(lessonId, stepId, step) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(baseUrl + 'steps/' + step.id.toString(), {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify({
                "lessonId": step.lessonId,
                "name": step.name,
                "description": step.description,
                "index": step.index
            })
        })).json()
        checkError(response);
        return response.id;
    }

    /**
     * Fetches all statements from a step
     * @param lessonId
     * @param stepId : step id
     * @returns all statements from step for which id = stepId
     */
    async function fetchAllStatements(lessonId, stepId) {
        const apiUrl = baseUrl + 'steps/' + stepId.toString() + '/statements';
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'GET',
            headers: myHeaders
        })).json()
        checkError(response);
        return response.statements;
    }

    /**
     * Fetches a statement
     * @returns statement
     * @param statementId
     */
    async function fetchStatementById(statementId) {
        const apiUrl = baseUrl + 'statements/' + statementId.toString();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'GET',
            headers: myHeaders,
        })).json()
        checkError(response);
        return response;
    }

    /**
     * Deletes a statement given its id
     * @param lessonId
     * @param stepId
     * @param statementId : statement id
     * @returns statement id
     */
    async function deleteStatementById(lessonId, stepId, statementId) {
        if (typeof statementId === "string")
            throw new Error("Save statement before trying to delete it");
        const apiUrl = baseUrl + 'statements/' + statementId.toString();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'DELETE',
            headers: myHeaders
        })).json();
        checkError(response);
        return Number(response.id);
    }

    /**
     * Adds a new statement
     * @param lessonId
     * @param stepId : id of step in which statement must be added
     * @param statement : statement to add
     * @returns lesson id
     */
    async function addStatement(lessonId, stepId, statement) {
        const apiUrl = baseUrl + 'steps/' + stepId.toString() + '/statements';
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());

        const response = await (await fetch(apiUrl, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                "type": statement.type,
                "name": statement.name,
                "description": statement.description,
                "screen": statement.screen,
                "index": statement.index,
                "columns": statement.columns,
                "widgets": statement.widgets
            }),
            mode: 'cors'
        })).json();
        checkError(response);
        return response.id;
    }

    /**
     * Modifies a statement
     * @returns statement id
     * @param lessonId
     * @param stepId
     * @param statement
     */
    async function updateStatement(lessonId, stepId, statement) {
        const apiUrl = baseUrl + 'statements/' + statement.id.toString();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify({
                "stepId": stepId,
                "type": statement.type,
                "name": statement.name,
                "description": statement.description,
                "screen": statement.screen,
                "index": statement.index,
                "columns": statement.columns,
                "widgets": statement.widgets
            })
        })).json();
        checkError(response);
        return response.id;
    }

    async function reorder(lesson_id, {indexBefore, indexAfter}) {
        throw new Error('Statement Repo - reorder - Not implemented');
    }
}