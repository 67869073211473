export default function buildMakeGenericWidget(
    {subtypeString, subtypeVal, elementFactory}
) {
    const error = "Creating a Generic Widget failed! ";
    let errorDetails = "";

    const isValidWidget = (data) => {
        let isValid = true;
        if(data.subtype !== subtypeVal
            && data.subtypeString !== subtypeString) {
            isValid = false;
            errorDetails += "Invalid type for a generic widget. ";
        }

        if(!data.body || (typeof data.body !== "object")) {
            isValid = false;
            errorDetails += "No body found. ";
        }

        return isValid;
    }

    return function makeGenericWidget({data}) {
        if(!isValidWidget(data)) {
            throw new Error(error + "\n" + errorDetails);
        }

        return {
            subtype: subtypeVal,
            subtypeString: subtypeString,
            body: elementFactory.create(data.body),
        };
    }
}