// React
import React from 'react';

// Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import HelpIcon from '@material-ui/icons/Help';

// Styles
import {makeStyles} from '@material-ui/core/styles';
import {errorColor, infoColor, successColor, warningColor} from "../../../styleVariables";
import {Card, CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    card: {
        elevation: 10,
        boxShadow: theme.shadows[3],
    }
}));

export default function ChapterHelp() {
    const classes = useStyles();
    const states = [
        {
            name: "VALIDATED",
            color: successColor,
            explanation: "The lesson has been validated by an admin. It appears as is on the application"
        },
        {
            name: "MODIFIED",
            color: infoColor,
            explanation: "The lesson has been modified by a professor. This professor has not yet signaled" +
                "that he has finished modifying it."
        },
        {
            name: "PENDING",
            color: warningColor,
            explanation: "A professor has finished modifying a lesson. He has requested an admin to validate " +
                "his modifications. This validation is still pending."
        },
        {
            name: "DELETED",
            color: errorColor,
            explanation: "A professor has deleted the whole lesson. This deletion has not yet been validated." +
                "Notice that validating a lesson whose state is deleted is equivalent to deleting the lesson"
        }
    ]

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar aria-label="help">
                        <HelpIcon/>
                    </Avatar>
                }
                title="Lesson States"
                subheader="See what the bookmarks and their colors mean"
            />
            <CardContent>
                <List className={classes.root}>
                    {states.map((state, index) => (
                        <div key={state.name}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar>
                                        <BookmarkIcon style={{color: state.color}}/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={state.name}
                                    secondary={
                                        <Typography
                                            noWrap={false}
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {state.explanation}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li"/>
                        </div>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
}