import makeLesson from "../../entities/lesson";
import makeError from "../../entities/error";

export default function makeCreateLesson({lessonRepository}) {
    return async function createLesson(chapter, newLesson) {
        try {
            const res = await lessonRepository.add(chapter.id, newLesson);
            return makeLesson({
                id: res,
                chapterId: chapter.id,
                name: newLesson.name,
                description: newLesson.description,
                state: newLesson.state,
                dependencies: newLesson.dependencies,
                lastModifiedBy: newLesson.lastModifiedBy,
                statements: newLesson.statements ?? [],
            });
        } catch (err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? 'The application encountered ' +
                    'a problem trying to add this lesson.'});
        }
    }
}