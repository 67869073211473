import makeLesson from "../../entities/lesson";
import makeError from "../../entities/error";

export default function makeListLessons({lessonRepository}) {
    return async function listLessons(chapter) {
        try {
            const response = await lessonRepository.fetchAll(chapter.id);
            chapter.lessons = response.map((lesson) => {
                return makeLesson({
                    id: lesson.id,
                    chapterId: chapter.id,
                    name: lesson.name,
                    description: lesson.description,
                    state: lesson.state,
                    dependencies: lesson.dependencies,
                    lastModifiedBy: lesson.lastModifiedBy ?? null,
                    statements: lesson.statements ?? [],
                });
            });
            return chapter;
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? 'The application encountered' +
                    'a problem trying to access lessons for chapter: ' + chapter.name});
        }
    }
}