import {configureStore} from '@reduxjs/toolkit';
import homeReducer from '../features/home/homeSlice';
import lessonsListSliceReducer from '../features/lessons/lessonsList/lessonsListSlice'
import lessonsSliceReducer from "../features/lessons/lessonsSlice";
import statementsSliceReducer from "../features/statements/statementsSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import authenticationReducer from "../features/authentication/authenticationSlice";
import usersReducer from "../features/users/usersSlice";
import authMiddleware from "../utils/AuthMiddleware";

export default configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(authMiddleware),
    reducer: {
        notifications: notificationsReducer,
        home: homeReducer,
        users: usersReducer,
        lessons: lessonsSliceReducer,
        statements: statementsSliceReducer,
        lessonsList: lessonsListSliceReducer,
        authentication: authenticationReducer,
    },
});
