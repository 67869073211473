import makeError from "../../entities/error";

export default function makeReorderSteps({}) {
    const findStepIndexById = (steps, id) => {
        return steps.findIndex((step) => step.id === id);
    }

    const sortSteps = (steps) => {
        return steps.sort((s1, s2) => {
            return (s1.index - s2.index);
        });
    }

    const resetIndexes = (steps) => {
        steps.forEach((step, index, arr) => {
            arr[index] = {...step, index: index};
        });
    }

    return function reorderSteps({steps, sourceId, destId}) {
        try {
            steps = sortSteps(steps);
            const iSource = findStepIndexById(steps, sourceId);
            const iDest = findStepIndexById(steps, destId);
            steps.splice(iDest, 0, steps.splice(iSource, 1)[0]);
            resetIndexes(steps);
            return steps;
        } catch (err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered a " +
                    "problem trying to change the order of the steps"
            });
        }
    }
}