// Icons
import ExploreIcon from '@material-ui/icons/Explore';
import Home from './features/home/Home';
import SubjectTabs from './features/lessons/lessonsList/SubjectTabs.js'
import UsersList from "./features/users/UsersList";

export default {
    items: [
        {
            path: '/',
            name: 'Home',
            type: 'link',
            icon: ExploreIcon,
            component: Home,
            role: null
        },
        {
            path: '/lessonsList',
            name: 'Lessons',
            type: 'link',
            icon: ExploreIcon,
            component: SubjectTabs,
            role: null,
        },
        {
            path: "/usersList",
            name: "Users",
            type: "link",
            icon: ExploreIcon,
            component: UsersList,
            role: "admin"
        }
    ]
}
