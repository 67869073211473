export function createChapterListModel(chapters) {
    let model = {};
    chapters.map((chapter) => {
        return model[chapter.id] = {
            id: chapter.id,
            title: chapter.name,
            lessons: chapter.lessons,
            description: chapter.description,
        }
    });
    return model;
}

export function lessonDisplay(lessonDisplayOptions, lessons) {
    let res = [...lessons];
    // filter
    Object.keys(lessonDisplayOptions.filter).forEach((key) => {
        res = res.filter((lesson) => {
            return lessonDisplayOptions.filter[key].includes(lesson[key]);
        });
    });

    // search
    if(lessonDisplayOptions.search !== ''){
        res = res.filter((lesson) => {
            return lesson.name.includes(lessonDisplayOptions.search);
        });
    }

    // sort
    Object.keys(lessonDisplayOptions.sort).forEach((key) => {
        res.sort((les1, les2) => {
            if((typeof les1[key] === 'string' || les1[key] instanceof String))
                return ('' + les2[key]).localeCompare(les1[key]);
            else if(Array.isArray(les1[key]))
                return les2[key].length - les1[key].length;
            return les2[key] - les1[key];
        });
    });
    return res;
}