import {makeStep} from "../../entities/statement";
import makeError from "../../entities/error";

export default function makeListSteps({statementRepository}) {
    return async function listSteps({lesson_id}) {
        try{
            const response = await statementRepository.fetchAllSteps(lesson_id);
            return response.map((step) => makeStep({
                stepData: step
            }));
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered a " +
                    "problem trying to access the statements"
            });
        }
    }
}