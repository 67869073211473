// React
import React, {useState} from 'react';
import {Droppable} from 'react-beautiful-dnd';

// Material
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from "@material-ui/core/Box";

// Styles
import {useStyles} from './LessonSingleStyles'

// Custom Components
import LessonList from './LessonList';
import LessonPopup from "./LessonPopup";
import {Card} from "@material-ui/core";

const Column = ({lessons, chapter}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const emptyLesson = {
        id: null,
        title: '',
        description: '',
        state: '',
        dependencies: [],
    }
    const [lesson, setLesson] = useState(emptyLesson);

    const handleModalToggle = (curLesson, isEdit = false) => {
        if (curLesson === undefined) {
            curLesson = emptyLesson;
        }
        setIsEdit(isEdit);
        setLesson(curLesson);
        setOpen(!open);
    }

    return (
        <>
            <LessonPopup
                open={open}
                lesson={lesson}
                subject={chapter.title}
                handleToggle={handleModalToggle}
                chapter={chapter}
                isEdit={isEdit}
            />
            <Droppable
                droppableId={`${chapter.id}`}
            >
                {(provided, snapshot) => {
                    return (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={classes.wrapper}
                        >
                            <div className={classes.list}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Box p={1} display="flex">
                                            <Box flexGrow={1} align='left'>
                                                <Typography className={classes.header}>
                                                    {chapter.title}
                                                </Typography>
                                                <Typography className={classes.subheader}>
                                                    {chapter.description}
                                                </Typography>
                                            </Box>
                                            <Box p={1}>
                                                <IconButton
                                                    onClick={() => handleModalToggle()}
                                                    aria-label="add"
                                                >
                                                    <AddCircleOutlineIcon size='small' color='primary'/>
                                                </IconButton> <br/>
                                                Add Lesson
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                            <Card style={{
                                padding: "10px",
                                height: "70vh",
                                overflowY: "scroll",
                                overflowX: "hidden"
                            }}>
                                <LessonList
                                    chapterId={chapter.id}
                                    lessonBoardName={chapter.title}
                                    lessons={lessons}
                                    handleToggle={handleModalToggle}
                                />
                                {provided.placeholder}
                            </Card>
                        </div>
                    );
                }}
            </Droppable>
        </>
    );
};

export default Column;