import {createAsyncThunk, createSlice, unwrapResult} from "@reduxjs/toolkit";
import AuthController from "../../controllers/AuthController";
import {notificationCreated} from "../notifications/notificationsSlice";
import {userUpdated} from "../authentication/authenticationSlice";

const handleError = (error, action, thunkAPI) => {
    const notification = {
        message: error.message,
        severity: 'error',
        action: null,
    }
    thunkAPI.dispatch(notificationCreated(notification));
    return thunkAPI.rejectWithValue(error);
}

export const listUsers = createAsyncThunk(
    "users/listUsers",
    async ({}, thunkAPI) => {
        const state = thunkAPI.getState();

        // check if users already fetched
        if (state.users.users.length > 0)
            return {
                users: state.users.users
            };

        const currentUser = state.authentication.user;
        const results = await AuthController.list();

        // everything failed
        if (!Array.isArray(results)) {
            return handleError(results, null, thunkAPI);
        }

        // get users, some may have failed
        const users = results.map(r => {
            if (r.error) {
                handleError(r, null, thunkAPI);
                return null;
            }

            if (r.id === currentUser.id) {
                return null;
            }

            return r;
        }).filter(user => user !== null);

        return {
            users: users
        }
    }
);

export const userDeleted = createAsyncThunk(
    "users/userDelete",
    async ({userId}, thunkAPI) => {
        const res = AuthController.deleteUser(userId);
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            deletedId: userId,
        }
    }
);

export const userSaved = createAsyncThunk(
    "users/userSaved",
    async ({userId}, thunkAPI) => {
        const state = thunkAPI.getState();
        let user = state.users.users.find(u => u.id === userId);

        if (typeof user === "undefined") {
            user = {};
            user.id = -1;
        }

        const res = await AuthController.update(JSON.parse(JSON.stringify(user)));
        if (res.error) {
            handleError(res, null, thunkAPI);
            const resetUser = await AuthController.getUser(user.id);
            if (resetUser.error) return handleError(resetUser, null, thunkAPI);
            return {
                user: resetUser,
            };
        }

        const notification = {
            message: "User updated successfully",
            severity: "success",
            action: null
        };

        thunkAPI.dispatch(notificationCreated(notification));

        return {
            user: user
        };
    }
);

export const userAdded = createAsyncThunk(
    "users/userAdded",
    async ({user}, thunkAPI) => {
        const res = await AuthController.addUser(user);
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            user: res
        }
    }
)

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
        status: "idle",
        isAddUserPopupOpen: false,
    },
    reducers: {
        setUserValue: (state, action) => {
            const user = state.users.find(u => u.id === action.payload.userId);
            if (!user || typeof user === "undefined") return;
            user[action.payload.key] = action.payload.value;
        },
        toggleAddUserPopup: (state, action) => {
            state.isAddUserPopupOpen = !state.isAddUserPopupOpen;
        }
    },
    extraReducers: {
        [listUsers.pending]: (state, action) => {
            state.status = "pending";
        },
        [listUsers.fulfilled]: (state, action) => {
            state.status = "idle";
            state.users = action.payload.users;
        },
        [listUsers.rejected]: (state, action) => {
            state.status = "idle";
        },
        [userDeleted.fulfilled]: (state, action) => {
            const index = state.users.findIndex(u =>
                u.id === action.payload.deletedId);
            if (index < 0) return;
            state.users.splice(index, 1);
        },
        [userSaved.fulfilled]: (state, action) => {
            const index = state.users.findIndex(u =>
                u.id === action.payload.user.id);
            if (index < 0) return;
            state.users[index] = action.payload.user;
        },
        [userAdded.fulfilled]: (state, action) => {
            state.users.push(action.payload.user);
        }
    }
});

export const selectUsers = (state) => state.users.users;
export const selectStatus = (state) => state.users.status;
export const selectIsAddUserPopupOpen = (state) => state.users.isAddUserPopupOpen;

export const {setUserValue, toggleAddUserPopup} = usersSlice.actions;
export default usersSlice.reducer;