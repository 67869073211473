import React, {useState} from "react";
import Element from "../../elements/Element";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {MediaCard} from "../../../../../components/Card";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        margin: "auto",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    true: {
        color: "green",
        borderColor: "green",
    },
    false: {
        color: "red",
        borderColor: "red",
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const MCQWidget = ({widget, statementId}) => {
    const [value, setValue] = useState(1);
    const [done, setDone] = useState(false);
    const [text, setText] = useState("");
    const classes = useStyles();

    const onChange = (event) => {
        setValue(event.target.value);
    };

    const correction = () => {
        if (widget.solutions[value] === true) {
            setText("You got the right answer!");
            setDone(true);
        } else {
            setText(widget.mistakeMessage);
            setDone(false);
        }
    };

    const body = (
        <>
            <Typography component="div">
                <Element element={widget.question} statementId={statementId} widgetId={widget.id}/>
                <div className={done === true ? classes.true : classes.false}>
                    {text}
                </div>
            </Typography>
            <FormControl style={{width: "100%"}} component="div" error={false} className={classes.formControl}>
                <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={onChange}>
                    {widget.choices.map((c, i) => (
                        <FormControlLabel
                            key={i} value={`${i}`} control={<Radio />}
                            label={
                                <Element element={c} statementId={statementId} widgetId={widget.id} />
                            }
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <br/>
            <Button
                onClick={correction}
                variant="contained"
                className={classes.button}
                color="primary"
            >
                Correction
            </Button>
        </>
    );
    return <MediaCard title={"Exercise"} description={body}/>

}

export default MCQWidget;