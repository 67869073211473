export default function makeLogout({userRepository, storage}){
    return function logout() {
        try{
            storage.delete("user");
            storage.delete("is_authenticated");
            userRepository.logout();
            return true;
        }catch(err){
            return false;
        }
    }
}