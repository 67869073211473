import {users} from "./Seeder";

export default function makeUserRepoMock(
    makeId, sleep, getToken, setToken, deleteToken) {
    let data = {};
    users.forEach((user) => {
        data[user.id] = user;
    });

    async function fetchById(user_id) {
        await sleep(500);
        return data[user_id];
    }

    async function login({email, password}) {
        await sleep(500);
        const user = Object.values(data).find((user) => user.email === email);
        if (typeof user === "undefined")
            throw new Error("Those credentials do not correspond to our records");
        setToken(user.token);
        return user;
    }

    async function register({
            username, email, password,
            firstName, lastName, role
        }) {
        await sleep(500);
        Object.values(data).forEach((u) => {
            if (u.email === email) throw new Error("Email already exists");
            else if (u.username === username) throw new Error("Username already exists");
        });

        const new_user = {
            id: makeId(),
            username: username,
            email: email,
            firstName: firstName,
            lastName: lastName,
            role: role,
            token: makeId(),
        };
        data[new_user.id] = new_user;
        return new_user.id;
    }

    function logout() {
        deleteToken();
        return true;
    }

    function loginLocal({user}) {
        if(!user.token || typeof user.token === "undefined")
            throw new Error("Invalid token");
        setToken(user.token);
        return true;
    }

    async function update({user}) {
        await sleep(600);
        if (!user.token || typeof user.token === "undefined")
            throw new Error("Invalid token");
        data[user.id] = user;
        return user.id;
    }

    async function fetchAllUsers({}) {
        await sleep(600);
        return Object.values(data);
    }

    async function deleteUser({userId}) {
        await sleep(600);
        delete data[userId];
        return userId;
    }

    async function addUser({user}) {
        await sleep(600);
        user.id = makeId();
        data[user.id] = user;
        return user.id;
    }

    return Object.freeze({
        fetchAllUsers,
        fetchById,
        login,
        register,
        logout,
        loginLocal,
        update,
        deleteUser,
        addUser,
    });
}