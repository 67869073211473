import makeError from "../../entities/error";

export default function makeDeleteStep({statementRepository}) {
    return async function({step}) {
        try {
            return await statementRepository.deleteStepById(step.lessonId, step.id);
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered an " +
                    "error trying to delete this step"
            });
        }
    }
}