import React from 'react';
import {Card, CardHeader} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {grey} from '@material-ui/core/colors';
import Avatar from "@material-ui/core/Avatar";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../authentication/authenticationSlice";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import {toggleProfilePopup} from "./homeSlice";
import ProfilePopup from "./ProfilePopup";
import VisitsChart from "./VisitsChart";
import CompletionRatesChart from "./CompletionRatesChart";
import ErrorRatesChart from "./ErrorRatesChart";
import HintRatesChart from "./HintRatesChart";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "10px",
        padding: "10px"
    },
    innerCard: {
        minWidth: "350px",
        maxWidth: "400px",
        maxHeight: "300px",
    },
    avatar: {
        backgroundColor: grey[500],
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    date: {
        padding: "20px",
        margin: "20px",
        color: "#3f51b5",
        outline: "solid 1px",
        outlineColor: "#3f51b5"
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const Home = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const openProfilePopup = () => {
        dispatch(toggleProfilePopup({}));
    }

    const user = useSelector((state) => selectUser(state));
    return (
        <>
            <Card style={{minHeight: "200px"}} className={classes.root}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                        <Card raised={false} elevation={0} className={classes.innerCard}>
                            <Typography variant="h5" gutterBottom>
                                {`Logged in as '${user.username}'`}
                            </Typography>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="user_profile" className={classes.avatar}>
                                        User
                                    </Avatar>
                                }
                                title={`${user.firstName} ${user.lastName} ( ${user.role} )`}
                                subheader={`${user.email}`}
                            />
                            <Button
                                onClick={openProfilePopup}
                                variant="outlined"
                                color="primary"
                                size={"small"}
                                className={classes.button}
                                endIcon={<EditIcon/>}
                            >
                                Edit
                            </Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Typography
                            className={classes.date}
                            variant="h5"
                        >
                            {new Date().toLocaleDateString()}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card className={classes.root}>
                        <VisitsChart/>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card className={classes.root}>
                        <CompletionRatesChart/>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card className={classes.root}>
                        <ErrorRatesChart/>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card className={classes.root}>
                        <HintRatesChart/>
                    </Card>
                </Grid>
            </Grid>
            <ProfilePopup user={user}/>
        </>
    );
}

export default Home;
