import makeError from "../../entities/error";
import makeStatement from "../../entities/statement";
export default function makeListStatements({statementRepository}) {
    return async function listStatements(lessonId, stepId) {
        try {
            const response = await statementRepository.fetchAllStatements(lessonId, stepId);
            return response.map((stmt) => {
                return makeStatement({
                    data: stmt
                });
            });
        } catch(err) {
            return makeError({
                code: -1,
                message: err.message,
                description: 'The application encountered a problem trying to' +
                    'access the statements for this lesson'
            });
        }
    }
}