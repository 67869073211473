import {createDateRange, dateDisplayOptions} from "../utils/DateUtils";
import {infoColor, successColor} from "../styleVariables";
import {intervals} from "../utils/DateUtils";

export const presentVisitsData = (data, start, end, interval) => {
    const dateArray = createDateRange(start, end, interval);
    const visits = {};

    dateArray.forEach(date => {

        visits[date.toLocaleDateString(undefined, dateDisplayOptions)] = 0;

        const dateCopy = new Date(date);
        const dates = createDateRange(dateCopy.getTime(),
            dateCopy.addDays(intervals[interval] - 1).getTime(), "DAY");

        dates.forEach(d => {
            visits[date.toLocaleDateString(undefined, dateDisplayOptions)] +=
                data[d.toLocaleDateString(undefined, dateDisplayOptions)] ?? 0;
        });

    });

    return {
        labels: Object.keys(visits).map(d => interval !== "DAY" ? `${interval} of: ${d}` : d),
        datasets: [
            {
                label: `Visits per ${interval}`,
                data: Object.values(visits),
                backgroundColor: [
                    infoColor,
                ],
                hoverBackgroundColor: [
                    successColor,
                ],
            }
        ]
    };
}

const presentDataRange = (data, n, offset, labelKey, valueKey, title) => {
    const start = Math.min(n * offset, data.length - 1);
    const end = Math.min(start + n, data.length);
    const sliced_data = data.slice(start, end);
    const labels = sliced_data.map(d => d[labelKey]);
    const values = sliced_data.map(d => d[valueKey]);
    return {
        labels: labels,
        datasets: [{
            label: title,
            data: values,
            backgroundColor: [
                infoColor,
            ],
            hoverBackgroundColor: [
                successColor
            ]
        }]
    }
}

export const presentCompletionRates = (data, n, offset) => {
    return presentDataRange(data, n, offset,
        "name", "avgCompletionRate", "Lesson Completion Rate")
}


export const presentErrorRates = (data, n, offset) => {
    return presentDataRange(data, n, offset,
        "name", "avgErrorRate", "Step Error Rate");
}

export const presentHintRates = (data, n, offset) => {
    return presentDataRange(data, n, offset,
        "name", "avgRevelationRate","Step Hint Revelation Rate");
}