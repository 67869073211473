import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Divider, CardMedia} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    media: {
        height: "20%",
        width: "100%",
    },
    mini: {
        maxWidth: "400px",
    },
});

/**
 * MediaCard
 *
 * @param {*} param0
 *
 *
 */
function MediaCard({title, description, img, button2}) {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={1} variant={"outlined"}>
            {img === undefined ? null : (
                <CardMedia>
                    <img src={img} className={classes.media} alt=""/>
                </CardMedia>
            )}
            <CardHeader title={title}/>
            <Divider/>
            <CardContent>
                <Typography variant="body2" color="textPrimary" component="div">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}

function MiniCard({title, description}) {
    return <MediaCard title={title} description={<div>{description} </div>}/>;
}

export {MiniCard, MediaCard};