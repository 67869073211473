import {v4 as uuidv4} from 'uuid';

const makeId = () => {
    return uuidv4();
}

const users = [
    {
        id: makeId(),
        username: "JohnDoeProf",
        email: "hkoutroumpas@outlook.com",
        firstName: "John",
        lastName: "Doe",
        token: makeId(),
        role: 2,
        visits:
            [
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z"
            ]
    },
    {
        id: makeId(),
        username: "JohnDoeAdmin",
        email: "ilias.koutroumpas@student.uliege.be",
        firstName: "John",
        lastName: "Doe",
        token: makeId(),
        role: 2,
        visits:
            [
                "2021-05-06T00:31:31.031Z",
                "2021-05-03T00:31:31.031Z",
                "2021-05-02T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z"
            ]
    },
    {
        id: makeId(),
        username: "JohnDoeUser",
        email: "test@example.com",
        firstName: "John",
        lastName: "Doe",
        token: makeId(),
        role: 2,
        visits:
            [
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
                "2021-05-06T00:31:31.031Z",
            ]
    }
]

const chapters = [
    {
        id: makeId(),
        name: 'Algebra',
        lessons: [
            {
                id: makeId(),
                name: 'Lesson on Circles',
                description: 'This is a description of the lesson on circles',
                state: 'validated',
                lastModifiedBy: users[0].id,
                createdBy: users[0].id,
                requiredLessonIds: [],
                avgCompletionRate: 0.7
            },
            {
                id: makeId(),
                name: 'Lesson on Triangles',
                description: 'This is a lesson on triangles',
                state: 'pending',
                lastModifiedBy: users[0].id,
                createdBy: users[0].id,
                requiredLessonIds: [],
                avgCompletionRate: 0.4
            },
        ]
    },
    {
        id: makeId(),
        name: 'Geometry',
        lessons: [
            {
                id: makeId(),
                name: 'Lesson on Circles',
                description: 'This is a description of the lesson on circles',
                state: 'validated',
                lastModifiedBy: users[0].id,
                createdBy: users[0].id,
                requiredLessonIds: [],
                avgCompletionRate: 0.9
            },
            {
                id: makeId(),
                name: 'Lesson on Triangles',
                description: 'This is a lesson on triangles',
                state: 'pending',
                lastModifiedBy: users[0].id,
                createdBy: users[0].id,
                requiredLessonIds: [],
                avgCompletionRate: 0.2
            },
        ]
    },
    {
        id: makeId(),
        name: 'Analysis',
        lessons: [
            {
                id: makeId(),
                name: 'Lesson on Circles',
                description: 'This is a description of the lesson on circles',
                state: 'validated',
                lastModifiedBy: users[0].id,
                createdBy: users[0].id,
                requiredLessonIds: [],
                avgCompletionRate: 0.3,
            },
            {
                id: makeId(),
                name: 'Lesson on Triangles',
                description: 'This is a lesson on triangles',
                state: 'pending',
                lastModifiedBy: users[0].id,
                createdBy: users[0].id,
                requiredLessonIds: [],
                avgCompletionRate: 0.1,
            },
        ]
    },
]

const widgets = [
    {
        id: makeId(),
        type: "information",
        name: "My Information Widget",
        description: "This is an information widget!",
        column: 1,
        index: 1,
        subtype: "generic",
        body: {
            type: "text",
            content: "Simple Text Element"
        }
    },
    {
        id: makeId(),
        type: "information",
        name: "Second information widget",
        description: "This is a new information widget",
        column: 2,
        index: 2,
        subtype: "hint",
        body: {
            type: "text",
            content: "Simple Text Element"
        }
    },
    {
        id: makeId(),
        type: "question",
        difficulty: "10",
        error: "Wrong answer!!",
        column: 1,
        index: 3,
        subtype: "mcq",
        question: {
            type: "text",
            content: "Which is the right answer?!"
        },
        choices: [
            {
                type: "text",
                content: "This is the first option"
            },
            {
                type: "text",
                content: "Some Text Element"
            },
            {
                type: "text",
                content: "Last option!"
            }
        ],
        solutions: [true, false, false]
    }
]

const statements = [
    {
        id: makeId(),
        stepId: makeId(),
        name: "Right angles",
        description: "Description",
        screen: 1,
        index: 0,
        type: "theory",
        columns: 1,
        widgets: widgets,
        avgErrorRate: 0.3,
        avgRevelationRate: 0.2,
    },
    {
        id: makeId(),
        stepId: makeId(),
        name: "Acute angles",
        description: "Another description",
        screen: 1,
        index: 1,
        type: "exercise",
        columns: 1,
        widgets: [],
        avgErrorRate: 0.6,
        avgRevelationRate: 0.8
    },
    {
        id: makeId(),
        stepId: makeId(),
        name: "Some other angles",
        description: "Description!",
        screen: 2,
        index: 0,
        type: "problem",
        columns: 2,
        widgets: [],
        avgErrorRate: 0.2,
        avgRevelationRate: 0.5
    },
    {
        id: makeId(),
        stepId: makeId(),
        name: "Rectangles",
        description: "Some description",
        screen: 2,
        index: 1,
        type: "theory",
        columns: 2,
        widgets: [],
        avgErrorRate: 0.5,
        avgRevelationRate: 0.3
    }
];

const steps = [
    {
        id: makeId(),
        lessonId: 1,
        name: "First Step",
        description: "Description of the first step",
        index: 0,
        advised: [],
        statements: [],
        avgErrorRate: 0.4,
        avgRevelationRate: 0.8
    },
    {
        id: makeId(),
        lessonId: 1,
        name: "Second Step",
        description: "Description of the second step",
        index: 1,
        advised: [],
        statements: [],
        avgErrorRate: 0.8,
        avgRevelationRate: 0.4
    },
    {
        id: makeId(),
        lessonId: 1,
        name: "Third Step",
        description: "Description of the third step",
        index: 2,
        advised: [],
        statements: [],
        avgErrorRate: 0.3,
        avgRevelationRate: 0.3
    }
]

statements.forEach((stmt) => stmt.stepId = steps[0].id);

export {users, chapters, statements, steps, widgets};