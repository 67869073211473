import React from "react";
import GenericWidget from "./GenericWidget";
import HintWidget from "./HintWidget";

const InformationWidget = ({widget, statementId}) => {
    switch(widget.subtype) {
        case "generic": return (
            <GenericWidget
                statementId={statementId}
                widget={widget}
            />
        );
        case "hint": return (
            <HintWidget
                statementId={statementId}
                widget={widget}
            />);
        default: return <h1>Unknown Widget</h1>
    }
}

export default InformationWidget;