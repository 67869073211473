import React from "react";
import {MediaCard} from "../../../../components/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        maxWidth: "720px",
    },
}));

const ImageElement = ({element}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img src={element.url} className={classes.root} alt={element.url}/>
            <p/>
        </div>
    );
}

export default ImageElement;