import mockRepositoryService from "./index";

export default function makeLessonMock(makeId, sleep) {
    async function fetchAll(chapter_id) {
        const chapter = await mockRepositoryService.chapterMockRepository.fetchById(chapter_id);
        return chapter.lessons;
    }

    async function add(chapterId, lesson) {
        const chapter = await mockRepositoryService.chapterMockRepository.fetchById(chapterId);
        const newId = makeId();
        const newLesson = {
            id: newId,
            chapterId: chapterId,
            name: lesson.name,
            description: lesson.description,
            state: lesson.state,
            requiredLessonIds: lesson.dependencies,
            lastModifiedBy: lesson.lastModifiedBy ?? null,
            statements: lesson.statements ?? [],
        }
        chapter.lessons.push(newLesson);
        return newLesson.id;
    }

    async function update(chapterId, lesson) {
        const chapter = await mockRepositoryService.chapterMockRepository.fetchById(chapterId);
        chapter.lessons.some((curLesson, index) => {
            if(curLesson.id === lesson.id) {
                const requiredLessonIds = [...lesson.dependencies];
                chapter.lessons[index] = {...lesson};
                delete chapter.lessons[index].dependencies;
                chapter.lessons[index].requiredLessonIds = requiredLessonIds;
                return true;
            }
        });
        return lesson.id;
    }

    async function deleteById(chapterId, lessonId) {
        const chapter = await mockRepositoryService.chapterMockRepository.fetchById(chapterId);
        let deletedLesson = null;
        chapter.lessons.some((lesson, index) => {
            if(lesson.id === lessonId) {
                deletedLesson = lesson;
                chapter.lessons.splice(index, 1);
                return true;
            }
        });
        return deletedLesson;
    }

    return Object.freeze({
        fetchAll,
        add,
        update,
        deleteById,
    });
}