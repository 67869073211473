import React from "react";
import CardContent from "@material-ui/core/CardContent";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {widgetTypes} from "../../../../../presenters/EditorPresenter";
import {useDispatch} from "react-redux";
import {setWidget} from "../../../statementsSlice";

const HintWidgetEditor = ({widget}) => {
    const dispatch = useDispatch();
    const setValue = (key, value) => {
        dispatch(setWidget({
            key: key,
            value: value
        }));
    }
    return (
        <div>
            <CardContent>
                <FormControl style={{margin: '5px', width: '80%',}}>
                    <InputLabel>Widget Subtype</InputLabel>
                    <Select
                        onChange={(event) =>
                            setValue("subtype", event.target.value)}
                        value={widget.subtype}
                    >
                        {Object.keys(widgetTypes[widget.type].subtypes).map((type) => (
                            <MenuItem
                                key={type}
                                value={type}>
                                {widgetTypes[widget.type].subtypes[type].name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </CardContent>
        </div>
    )
}

export default HintWidgetEditor;