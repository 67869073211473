import React from "react";
import {MediaCard} from "../../../../../components/Card";
import Element from "../../elements/Element";

const GenericWidget = ({widget, statementId}) => {
    return (
        <>
            <MediaCard
                title={"A bit of theory"}
                description={<Element
                    statementId={statementId}
                    widgetId={widget.id}
                    element={widget.body}
                />}
            />
            <p/>
        </>
    );
}

export default GenericWidget;