export default function buildMakeVisits({isValidDate, dateDisplayOptions}) {
    const error = "User Visits could not be computed";
    let errorDetails = "";

    const isDataValid = (data) => {
        let isValid = true;
        if (typeof data === "undefined"
            || !Array.isArray(data)) {
            isValid = false;
            errorDetails += "No list of visits was found";
        }

        return isValid;
    }

    return function makeVisits({data}) {
        if (!isDataValid(data)) {
            throw new Error(error + "\n" + errorDetails);
        }

        const visits = {};
        data.forEach(visit => {
            if (isValidDate(visit)) {
                const date = new Date(visit).toLocaleDateString(undefined, dateDisplayOptions);
                if (typeof visits[date] === "undefined") visits[date] = 1;
                else visits[date] = visits[date] + 1;
            }
        });

        return {
            visits: visits
        }
    }
}