import React from "react";
import QuestionWidget from "./question/QuestionWidget";
import InformationWidget from "./information/InformationWidget";
import Selectable from "../Selectable";
import {widgetSelected} from "../../statementsSlice";

const Widget = ({widget, statementId}) => {

    const makeOnSelected = () => {
        return widgetSelected({
            statementId: statementId,
            widgetId: widget.id
        });
    }

    const getWidget = () => {
        switch (widget?.type) {
            case "information": {
                return <InformationWidget
                    statementId={statementId}
                    widget={widget}
                />;
            }
            case "question": {
                return <QuestionWidget
                    statementId={statementId}
                    widget={widget}
                />;
            }
        }
        return null;
    }

    if (typeof widget !== "undefined") {
        return (
            <Selectable
                onSelected={makeOnSelected}
                isSelected={widget.isSelected}
                actions={null}
            >
                {getWidget()}
            </Selectable>
        )
    }

    return null;
}

export default Widget;