import authService from "../authentication";
import statistics from "../../entities/statistics";
import makeError from "../../entities/error";

export default function makeGetVisits({statRepository}) {
    return async function getVisits({}) {
        try {
            const allUsers = await authService.list({});
            const visits = await Promise.all(allUsers.flatMap(u => {
                return statRepository.fetchUserVisits({
                    user_id: u.id
                });
            }));
            return statistics.makeVisits({
                data: visits.flat()
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered a" +
                    "problem trying to get user visits"
            });
        }
    }
}