import React, {useEffect, useState} from "react";
import {
    addWidget,
    deleteStatements, reorderWidgets,
    restoreStatement,
    selectEditorStepId,
    statementSaved,
    statementSelected
} from "../statementsSlice";
import Selectable from "./Selectable";
import Widget from "./widgets/Widget";
import Grid from "@material-ui/core/Grid";
import {ListItem, ListItemSecondaryAction, ListItemText, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from '@material-ui/icons/Save';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RestoreIcon from '@material-ui/icons/Restore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import List from "@material-ui/core/List";
import {errorColor, successColor, warningColor, infoColor} from "../../../styleVariables";
import {useDispatch, useSelector} from "react-redux";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const Statement = ({statement}) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const dispatch = useDispatch();

    const makeOnSelected = () => {
        return statementSelected({
            statementId: statement.id
        });
    }
    useEffect(() => {
        content = setContent();
    }, [statement.columns, statement.widgets]);

    const reorder = (result) => {
        if(!result.destination) return;
        const {source, destination} = result;
        dispatch(reorderWidgets({
            statementId: statement.id,
            source: source,
            destination: destination
        }));
    }

    const setContent = () => {
        if (statement.columns === 2) {
            return (
                <DragDropContext onDragEnd={reorder}>
                    <Grid container direction="row">
                        <Droppable droppableId={"1"}>
                            {(provided, snapshot) => (
                                <Grid {...provided.droppableProps} ref={provided.innerRef}
                                      xs={12} sm={12} md={6} lg={6} item>
                                    {statement.widgets.map((w, i) => (
                                        w.column <= 1 ?
                                            <Draggable
                                                key={w.id}
                                                draggableId={`${w.id}`}
                                                index={i}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Widget
                                                            statementId={statement.id}
                                                            widget={w}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                            : null
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                        <Droppable droppableId={"2"}>
                            {(provided, snapshot) => (
                                <Grid {...provided.droppableProps} ref={provided.innerRef}
                                      xs={12} sm={12} md={6} lg={6} item>
                                    {statement.widgets.map((w, i) => (
                                        w.column >= 2 ?
                                            <Draggable
                                                key={w.id}
                                                draggableId={`${w.id}`}
                                                index={i}
                                            >
                                                {(provided, snapshot) => (
                                                    <div style={{width: "100%"}}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Widget statementId={statement.id} widget={w}/>
                                                    </div>
                                                )}
                                            </Draggable>
                                            : null
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </Grid>
                </DragDropContext>
            )
        } else {
            return (
                <DragDropContext onDragEnd={reorder}>
                    <Droppable droppableId={"1"}>
                        {(provided, snapshot) => (
                            <Grid {...provided.droppableProps} ref={provided.innerRef}
                                  container direction="column"
                            >
                                {statement.widgets.map((w, i) => (
                                    <Draggable
                                        key={w.id === null ? `${i}` : w.id}
                                        draggableId={`${w.id}`}
                                        index={i}>
                                        {(provided, snapshot) => (
                                            <Grid item
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Widget statementId={statement.id} widget={w}/>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            )
        }
    }

    let content = setContent();
    const stepId = useSelector((state) => selectEditorStepId(state));
    const deleteScreenStatements = (statement) => {
        dispatch(deleteStatements({
            statements: [statement],
            stepId: stepId
        }));
    }
    const restore = (statementId) => {
        dispatch(restoreStatement({
            statementId: statementId,
            stepId: stepId
        }));
    }
    const save = (statement) => {
        dispatch(statementSaved({
            statement: statement,
            stepId: stepId,
        }));
    }
    const add = (statementId) => {
        dispatch(addWidget({
            statementId: statementId
        }));
    }

    return (
        <>
            <List dense={true}>
                <ListItem>
                    <ListItemText
                        primary={statement.name}
                        secondary={statement.description}
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title={"Add a widget"}>
                            <IconButton onClick={() => add(statement.id)}
                                        edge="end" aria-label="add widget">
                                <AddCircleOutlineIcon style={{color: infoColor}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Save Statement"}>
                            <IconButton onClick={() => save(statement)}
                                        edge="end" aria-label="save statement">
                                <SaveIcon style={{color: successColor}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Restore to last saved state"}>
                            <IconButton onClick={() => restore(statement.id)}
                                        edge="end" aria-label="restore">
                                <RestoreIcon style={{color: warningColor}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete Statement"}>
                            <IconButton onClick={() => deleteScreenStatements(statement)}
                                        edge="end" aria-label="delete">
                                <DeleteIcon style={{color: errorColor}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Minimize"}>
                            <IconButton onClick={() => setIsMinimized(!isMinimized)}
                                        edge="end" aria-label="minimize">
                                {!isMinimized ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            {
                isMinimized ? null : (
                    <Selectable
                        onSelected={makeOnSelected}
                        isSelected={statement.isSelected}
                        actions={null}
                    >
                        {content}
                    </Selectable>
                )
            }
        </>
    )
}

export default Statement;