// React
import React, {useEffect} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

// Material UI
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Card, CardActions, CardHeader, Chip, Collapse, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Redux
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllStatements,
    reorderScreens,
    selectAllLessons,
    selectStep,
    setOnEditStep, statementSaved,
    stepDeleted,
    toggleStepModal
} from "../statementsSlice";
import {unwrapResult} from "@reduxjs/toolkit";

// Custom components
import ScreenListItem from "./ScreenListItem";
import {aggregateStatementsByScreen, optimisticScreenReorder} from "../../../presenters/StatementsPresenter";
import {CenteredLoader} from "../../../components";
import {errorColor, infoColor} from "../../../styleVariables";
import {AddCircleOutline} from "@material-ui/icons";
import {defaultStatement} from "../../../presenters/EditorPresenter";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        height: '100%',
        margin: "auto",
        alignSelf: "center",
        justifyContent: 'center',
        alignItems: 'center',
    },
    list: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingTop: '20px',
        marginBottom: "10px",
        width: '90%',
        height: '100%',
        boxShadow: theme.shadows[3],
        borderRadius: "20px",
        backgroundColor: theme.palette.background.paper,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function NestedList({stepId, lessonId, provided}) {
    const classes = useStyles();

    // STEP EDITING
    const dispatch = useDispatch();
    const step = useSelector((state) => selectStep(state, lessonId, stepId)) ?? {};
    let statements = step.statements ?? [];
    useEffect(() => {
        statements = step.statements;
    }, [step.statements]);
    const editStepClicked = () => {
        dispatch(setOnEditStep({step: step}));
        dispatch(toggleStepModal({}));
    }
    const deleteStepClicked = () => {
        dispatch(stepDeleted({
            step: step
        }));
    }

    // FETCH AND EXPAND SCREENS AND STATEMENTS OF A STEP
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        if (!expanded) {
            dispatch(fetchAllStatements({
                lessonId: lessonId,
                stepId: stepId
            }));
        }
        setExpanded(!expanded);
    }
    const getExpandContent = () => {
        if (step.status !== "idle") {
            return <CenteredLoader/>;
        } else {
            return (
                <CardContent>
                    <Divider/>
                    <DragDropContext onDragEnd={onScreenDragEnd}>
                        <Droppable droppableId={`${stepId}`}>
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <List
                                            dense={true}
                                            component="h2" aria-labelledby="nested-list-subheader"
                                        >
                                            {Object.keys(formattedStatements).map((key, index) => (
                                                <Draggable
                                                    style={{padding: "10px"}}
                                                    key={key}
                                                    draggableId={`${key}`}
                                                    index={parseInt(key)}  // key = actual screen number. Don't replace with index,
                                                    // Used in the presenter to implement the optimistic reorder
                                                >
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <ScreenListItem
                                                                key={index}
                                                                statements={formattedStatements[key]}
                                                                screenNb={index + 1}
                                                                provided={provided}
                                                                stepId={stepId}
                                                            />
                                                        )
                                                    }}
                                                </Draggable>
                                            ))}
                                        </List>
                                        {provided.placeholder}
                                    </div>
                                )
                            }}
                        </Droppable>
                    </DragDropContext>
                </CardContent>
            )

        }
    }

    if (typeof step.id === "undefined") return <></>;

    // FORMAT STATEMENTS INTO SCREENS
    let formattedStatements = {};
    if (typeof statements !== "undefined" && statements.length) {
        formattedStatements = aggregateStatementsByScreen(statements);
    }

    // REORDER SCREENS
    const onScreenDragEnd = (result) => {
        if (!result.destination) return;
        const {source, destination} = result;
        if (source.index === destination.index) return;
        dispatch(reorderScreens({
            stepId: stepId,
            lessonId: lessonId,
            sourceScreenNb: source.index,
            destScreenNb: destination.index,
            allStatements: JSON.parse(JSON.stringify(formattedStatements)),
        }))
            .then(unwrapResult)
            .catch(err => {
                // Make sure the correct state is displayed to the user.
                dispatch(fetchAllStatements({
                    stepId: stepId, lessonId: lessonId
                }));
            });
        formattedStatements = optimisticScreenReorder(formattedStatements, source, destination);
    }

    // ADD SCREEN
    const addScreen = () => {
        const stmt = defaultStatement();
        if(statements.length === 1)
            stmt.screen = statements[0].screen + 1;
        else{
            stmt.screen = statements.length > 0 ? 1 + statements.reduce((s1, s2) => {
                return s1.screen > s2.screen ? s1.screen : s2.screen;
            }) : 1;
        }
        dispatch(statementSaved({
            stepId: stepId,
            statement: stmt
        }));
    }

    return (
        <Box
            className={classes.root}
            display='flex'
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <Card
                className={classes.list}
                m='auto'
            >
                <div {...provided.dragHandleProps}>
                    <CardHeader
                        disableTypography={true}
                        title={<Typography align="center" variant="subtitle2" gutterBottom={false}>
                            {step.name}
                        </Typography>}
                        subheader={<Typography align="center" variant="subtitle1" gutterBottom>
                            {step.description}
                        </Typography>}
                    />
                    <CardActions justify={"center"} disableSpacing>

                        <Tooltip title={"Add and edit a screen"}>
                            <IconButton onClick={addScreen}
                                        style={{color: infoColor, paddingLeft: '10px',}}
                                        aria-label="edit steps">
                                <AddCircleOutline />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Edit step"}>
                            <IconButton onClick={() => editStepClicked()}
                                        style={{color: infoColor, paddingLeft: '10px',}}
                                        aria-label="edit steps">
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Delete Step"}>
                            <IconButton onClick={() => deleteStepClicked()}
                                        style={{color: errorColor}}
                                        aria-label="share">
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>

                        <IconButton
                            className={clsx(classes.expand)}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <div style={{paddingRight: "10px"}}>Screens</div>
                            <ExpandMoreIcon className={clsx({
                                [classes.expandOpen]: expanded,
                            })}/>
                        </IconButton>
                    </CardActions>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {getExpandContent()}
                </Collapse>
            </Card>
        </Box>
    );
}