import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    open: false,
    message: '',
    severity: 'info',
    action: null,
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        notificationCreated(state, action) {
            state.open = true;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
            state.action = action.payload.action;
        },
        notificationClosed(state, action) {
            state.open = false;
            state.message = '';
            state.severity = 'info';
            state.action = null;
        }
    }
});

export const selectIsOpen = (state) => state.notifications.open;
export const selectMessage = (state) => state.notifications.message;
export const selectSeverity = (state) => state.notifications.severity;
export const selectAction = (state) => state.notifications.action;

export const { notificationCreated, notificationClosed } = notificationsSlice.actions;
export default notificationsSlice.reducer;