import repositoryService from "../../repositories";
import mockRepositoryService from "../../mocks";
import makeListStatements from "./listStatements";
import makeDeleteStatement from "./deleteStatement";
import makeUpdateStatements from "./updateStatements";
import makeListSteps from "./listSteps";
import makeUpdateStep from "./updateStep";
import makeDeleteStep from "./deleteStep";
import makeReorderSteps from "./reorderSteps";

// Pick repo
let repo;
if(process.env.REACT_APP_USE_MOCKS !== 'false'){
    console.log('USING MOCK REPOSITORIES / Statements');
    repo = mockRepositoryService.statementMockRepository;
}else{
    console.log('USING API / Statements');
    repo = repositoryService.statementRepository;
}

// Create Use Cases
const listSteps = makeListSteps({
    statementRepository: repo,
});

const listStatements = makeListStatements({
    statementRepository: repo,
});

const deleteStatement = makeDeleteStatement({
    statementRepository: repo,
})

const updateStatements = makeUpdateStatements({
    statementRepository: repo,
});

const updateStep = makeUpdateStep({
    statementRepository: repo,
});

const deleteStep = makeDeleteStep({
    statementRepository: repo,
});

const reorderSteps = makeReorderSteps({});

const statementsService = Object.freeze({
    listSteps,
    listStatements,
    deleteStep,
    deleteStatement,
    updateStatements,
    updateStep,
    reorderSteps,
});

export {listSteps, listStatements, deleteStatement, updateStatements, updateStep,
    deleteStep, reorderSteps};
export default statementsService;