import React, {useEffect} from "react";
import {Route, Redirect, useHistory, useLocation, Switch} from "react-router-dom";
import ResetPass from "../features/authentication/ResetPass";
import Login from "../features/authentication/Login";
import Register from "../features/authentication/Register";
import Dashboard from "./Dashboard";
import {useSelector} from "react-redux";
import {selectAuthState} from "../features/authentication/authenticationSlice";
import {ToastNotifier} from "../features/notifications";


const RoutingComponent = () => {
    const isAuthenticated = useSelector((state) => selectAuthState(state));
    const history = useHistory();
    const location = useLocation();
    const publicLocations = ["/login", "/register", "/forgot", "/404"];

    useEffect(() => {
        if (!isAuthenticated) {
            if (!publicLocations.includes(location.pathname))
                history.push("/login");
        }
    }, [isAuthenticated, location]);

    return (
        <div>
            <ToastNotifier/>
            <Switch>
                <Route exact path="/404" component={Login}/>
                <Route exact path="/forgot" component={ResetPass}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/register" component={Register}/>
                <Route path="/" component={Dashboard}/>
                <Redirect to="/404"/>
            </Switch>
        </div>
    )
}

export default RoutingComponent;