export default function buildMakeSingleQuestionWidget(
    {subtypeString, subtypeVal, elementFactory}
) {
    const error = "Creating a Single Question Widget failed! ";
    let errorDetails = "";

    const isValidWidget = (data) => {
        let isValid = true;
        if(data.subtype !== subtypeVal
            && data.subTypeString !== subtypeString) {
            isValid = false;
            errorDetails += "Invalid type for a Single Question Widget";
        }

        if(!data.question || (typeof data.question !== "object")) {
            isValid = false;
            errorDetails += "No question found. ";
        }

        if(!data.solution || (typeof data.solution !== "string"
            && !data.solution instanceof String)) {
            isValid = false;
            errorDetails += "No solution found. ";
        }

        return isValid;
    }

    return function makeSingleQuestionWidget({data}) {
        if (!isValidWidget(data)) {
            throw new Error(error + "\n" + errorDetails);
        }

        return {
            subtype: subtypeVal,
            subtypeString: subtypeString,
            question: elementFactory.create(data.question),
            solution: data.solution,
        };
    }
}