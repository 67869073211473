// React
import React from 'react';

// Material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";
import {infoColor} from "../../styleVariables";

const CenteredLoader = () => {
    return (
        <Box
            display="flex"
            width='100%'
            height='100%'
            bgcolor='transparent'
        >
            <Box m='auto'>
                <CircularProgress style={{color: infoColor}} />
            </Box>
        </Box>
    );
};

export default CenteredLoader;