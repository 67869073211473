import makeError from "../../entities/error";

export default function makeGetStatementHintRates({statRepository}) {
    return async function getStatementHintRates({}) {
        try {
            return await statRepository.fetchStatementHintRates();
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description
            });
        }
    }
}