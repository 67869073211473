export default function buildMakeError() {

    // Token errors
    const EXPIRED_TOKEN = 12;
    const INVALID_TOKEN = 11;
    const ERROR_TOKEN = 14;

    // Unauthorized action attempts
    const UNAUTHORIZED = 2;
    const maxAttempts = 5;
    let current = 0;


    return function makeError(
        {
            code = -1,
            message = 'Unknown error occurred',
            description = 'There was no known description of this error'
        } = {}) {


        let shouldResetUser = false;
        switch (code) {
            case EXPIRED_TOKEN:
                shouldResetUser = true;
                break;
            case INVALID_TOKEN:
                shouldResetUser = true;
                break;
            case ERROR_TOKEN:
                shouldResetUser = true;
                break;
            case UNAUTHORIZED: {
                if (current++ > maxAttempts) {
                    shouldResetUser = true;
                    current = 0;
                }
            }
        }

        return Object.freeze({
            error: true,
            code: code,
            message: message,
            description: description,
            shouldResetUser: shouldResetUser
        });
    }
}