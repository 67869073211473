import React from "react";
import {equations2html} from "../../../../presenters/EditorPresenter";

const TextElement = ({element}) => {
    return (
        <div dangerouslySetInnerHTML={{__html: equations2html(element.content) }}>
        </div>
    )
}

export default TextElement;