export default function buildMakeGraphElement(
    {typeString, typeVal, isValidEquation, makeId}
) {
    const error = "Creating a graph element failed. ";
    let errorDetails = "";

    const isValidElement = (data) => {
        let isValid = true;
        if (data.type !== typeVal &&
            data.typeString !== typeString) {
            isValid = false;
            errorDetails += "Invalid type for graph element. ";
        }

        if (!data.axis || !Array.isArray(data.axis)) {
            isValid = false;
            errorDetails += "No axis found. ";
        }

        if (!data.fn || !isValidEquation(data.fn)) {
            let isValid = false;
            errorDetails += "No (or invalid) equation. ";
        }

        return isValid;
    }

    return function makeGraphElement({data}) {
        if (!isValidElement(data)) {
            throw new Error(error + "\n" + errorDetails);
        }

        return {
            id: makeId(),
            type: typeVal,
            typeString: typeString,
            axis: data.axis,
            fn: data.fn,
        };
    }
}