import buildMakeTextElement from "./TextElement";
import buildMakeImageElement from "./ImageElement";
import buildMakeGraphElement from "./GraphElement";
import {v4 as uuidv4} from 'uuid';


// check if variable is a string
const isString = (string) => {
    return !(!string || typeof string !== "string" && !string instanceof String);
}

const isBase64 = (str) => {
    if (str ==='' || str.trim() ===''){ return false; }
    try {
        return btoa(atob(str)) == str;
    } catch (err) {
        return false;
    }
}

// validate a url
const isValidUrl = (url) => {
    if (!isString(url)) return false;

    const isEncoded = isBase64(url.split(',')[1]);

    try {
        url = new URL(url);
    } catch (_) {
        return isEncoded;
    }

    return (url.protocol === "http:" || url.protocol === "https:") || isEncoded;
}

// validate an equation
const isValidEquation = (fn) => {
    if (!isString(fn)) return false;
    //TODO: USE MATH JS HERE TO PARSE STRING FUNCTIONS
    return true;
}

const makeId = () => {
    return uuidv4();
}

const elementMap = {
    text: "text",
    image: "image",
    graph: "graph",
};

const makeTextElement = buildMakeTextElement({
    typeString: "Text Element",
    typeVal: elementMap.text,
    makeId: makeId
});

const makeImageElement = buildMakeImageElement({
    typeString: "Image Element",
    typeVal: elementMap.image,
    isValidUrl: isValidUrl,
    makeId: makeId,
});

const makeGraphElement = buildMakeGraphElement({
    typeString: "Graph Element",
    typeVal: elementMap.graph,
    isValidEquation: isValidEquation,
    makeId: makeId
});

const elementFactory = {
    create: (data) => {
        if (data.type && typeof data.type !== "undefined") {
            switch (data.type) {
                case elementMap.text:
                    return makeTextElement({
                        data: data
                    });
                case elementMap.image:
                    return makeImageElement({
                        data: data
                    });
                case elementMap.graph:
                    return makeGraphElement({
                        data: data
                    });
                default:
                    throw new Error("Unknown element type!");
            }
        } else {
            switch (data.typeString) {
                case "Text Element":
                    return makeTextElement({
                        data: data
                    });
                case "Image Element":
                    return makeImageElement({
                        data: data
                    });
                case "Graph Element":
                    return makeGraphElement({
                        data: data
                    });
                default:
                    throw new Error("Unknown element type!");
            }
        }
    }
};

export default elementFactory;