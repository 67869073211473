import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setWidget} from "../../../statementsSlice";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import SplitButton from "../../../../../components/SplitButton/SplitButton";
import {errorColor} from "../../../../../styleVariables";
import Button from "@material-ui/core/Button";
import {defaultElement} from "../../../../../presenters/EditorPresenter";

const MCQWidgetEditor = ({widget}) => {
    const dispatch = useDispatch();
    const setValue = (key, value) => {
        dispatch(setWidget({
            key: key,
            value: value,
        }));
    }

    // Correct solution index
    const getRightIndex = () => {
        widget.solutions.forEach((solution, i) => {
            if (solution) return i.toString();
        });
        return "0";
    }
    const [index, setIndex] = useState(getRightIndex());
    const handleRadioChange = (event) => {
        setIndex(event.target.value);
        const solutions = widget.choices.map((choice, i) => {
            return parseInt(event.target.value) === i;
        });
        setValue("solutions", solutions);
    }

    // Delete option index
    const [deleteIndex, setDeleteIndex] = useState(0);
    const handleDeleteChange = (index) => {
        setDeleteIndex(index);
    }
    const deleteClicked = () => {
        const choices = JSON.parse(JSON.stringify(widget.choices));
        const solutions = [...widget.solutions];
        choices.splice(deleteIndex, 1);
        const wasSolution = solutions.splice(deleteIndex, 1);
        if (wasSolution) solutions[0] = true;
        setValue("solutions", solutions);
        setValue("choices", choices);
    }
    const options = widget.choices.map((c, i) => {
        return `Remove option nb ${i + 1}`;
    });

    // add an option
    const addClicked = () => {
        const element = defaultElement();
        element.isSelected = false;
        const choices = [
            ...widget.choices,
            element
        ];
        const solutions = [
            ...widget.solutions,
            false
        ];
        setValue("choices", choices);
        setValue("solutions", solutions);
    };

    return (
        <div>
            <FormControl style={{margin: '5px', width: '80%',}}>
                <InputLabel id="mcq_right_answer_label">
                    Set the right answer ...
                </InputLabel>
                <Select
                    labelId="mcq_right_answer"
                    id="mcq_right_answer"
                    value={index}
                    onChange={handleRadioChange}
                >
                    {widget.choices.map((choice, i) => (
                        <MenuItem key={choice.id} value={`${i}`}>
                            {`Option number ${i + 1}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> <br/> <br/>
            <FormControl style={{margin: '5px', width: '80%'}}>
                <InputLabel shrink={true} htmlFor="split_button_remove">
                    Add an option ...
                </InputLabel>
            </FormControl>
            <FormControl style={{margin: '5px', width: '80%'}}>
                <Button onClick={addClicked} variant="outlined">
                    Add an option
                </Button>
            </FormControl> <br/> <br/>
            <FormControl style={{margin: '5px', width: '80%'}}>
                <InputLabel shrink={true} htmlFor="split_button_remove">
                    Select an option to remove ...
                </InputLabel>
            </FormControl>
            <FormControl style={{margin: '5px', width: '80%'}}>
                <SplitButton
                    id="split_button_remove"
                    disabled={widget.choices.length === 1}
                    index={deleteIndex}
                    setIndex={handleDeleteChange}
                    options={options}
                    onClick={deleteClicked}
                    color={errorColor}
                />
            </FormControl>
            <Divider style={{marginTop: "25px"}}/>
        </div>
    );
}
export default MCQWidgetEditor;