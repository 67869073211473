// React
import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import classNames from 'classnames';

// Components
import Header from "./Header/Header"
import Sidebar from "./Sidebar/Sidebar"
import Workspace from "./Workspace/Workspace";

// Styles
import {useStyles} from "./DashboardStyles";
import {MobileBreakpoint} from "../styleVariables";
import routes from "../routes";
import screenEditor from "../features/statements/screenEditor/screenEditor";
import StepList from "../features/statements/statementsList/StepList";
import {useSelector} from "react-redux";
import {selectUser} from "../features/authentication/authenticationSlice";

const Dashboard = ({history}) => {
    const classes = useStyles();
    const user = useSelector((state) => selectUser(state));
    const [opened, setOpened] = useState(true);
    const useMountEffect = fun => useEffect(fun, []);
    const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

    const resizeDispatch = () => {
        if (typeof Event === "function") {
            window.dispatchEvent(new Event("resize"));
        } else {
            const evt = window.document.createEvent("UIEvents");
            evt.initUIEvent("resize", true, false, window, 0);
            window.dispatchEvent(evt);
        }
    };

    const handleDrawerToggle = () => {
        setOpened(!opened);
        resizeDispatch();
    };

    const handleFullscreenToggle = () => {
        const element = document.querySelector("#root");
        const isFullscreen =
            document.webkitIsFullScreen || document.mozFullScreen || false;

        element.requestFullScreen =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            function () {
                return false;
            };
        document.cancelFullScreen =
            document.cancelFullScreen ||
            document.webkitCancelFullScreen ||
            document.mozCancelFullScreen ||
            function () {
                return false;
            };
        isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
    };

    const getRoutes = (
        <Switch>
            {routes.items.map((item, index) => {
                // check that user has access to this route
                if (item.role !== null && item.role !== user.role)
                    return null;

                return item.type === 'external' ? (
                    <Route
                        exact
                        path={item.path}
                        component={item.component}
                        name={item.name}
                        key={index}
                    />
                ) : item.type === 'submenu' ? (
                    item.children.map(subItem => (
                        <Route
                            exact
                            path={`${item.path}${subItem.path}`}
                            component={subItem.component}
                            name={subItem.name}
                        />
                    ))
                ) : (
                    <Route
                        exact
                        path={item.path}
                        component={item.component}
                        name={item.name}
                        key={index}
                    />
                )
            })}
            <Route
                path={'/statements/:lesson_id'}
                component={StepList}
                name={'statements'}
                key={'statements'}
            />
            <Route
                path={'/edit'}
                component={screenEditor}
                name={'screenEditor'}
                key={'screenEditor'}
            />
        </Switch>
    );

    useMountEffect(() => {
        if (mediaMatcher.matches) setOpened(false);
        mediaMatcher.addEventListener('change', match => {
            setTimeout(() => {
                setOpened(!match.matches)
            }, 300);
        });

        return () => {
            mediaMatcher.removeEventListener('change', match => {
                setTimeout(() => {
                    setOpened(!match.matches);
                }, 300);
            });
        };
    });

    return (
        <>
            <Header
                logoAltText='MSS Dashboard'
                logo='../../public/static/images/logo.png'
                toggleDrawer={handleDrawerToggle}
                toggleNotifications={() => {
                }}
                toggleFullscreen={handleFullscreenToggle}
            />
            <div className={classNames(classes.panel, "theme-dark")}>
                <Sidebar
                    routes={routes.items}
                    opened={opened}
                    toggleDrawer={handleDrawerToggle}
                />
                <Workspace opened={opened}>{getRoutes}</Workspace>
            </div>
        </>
    );
};

export default Dashboard;


