import makeError from "../../entities/error";

export default function makeDeleteStatement({statementRepository}) {
    return async function deleteStatement(statement, lessonId, stepId) {
        try {
            return await statementRepository.deleteStatementById(lessonId,
                stepId, statement.id);
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "Statement " + statement.title +
                    " could not be deleted"});
        }
    }
}