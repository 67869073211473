import makeError from "../../entities/error";

export default function makeDeleteUser({userRepository}) {
    return async function deleteUser({userId}) {
        try {
            return await userRepository.deleteUser({
                userId: userId
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered" +
                    "a problem trying to delete a user"
            });
        }
    }
}