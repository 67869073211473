import React, {useState} from "react";
import Element from "../../elements/Element";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const HintWidget = ({widget, statementId}) => {
    const [clicked, setClicked] = useState(false);
    const [sent, setSent] = useState(false);

    const onClick = (_) => {
        setClicked(!clicked);
        if (sent === false) setSent(true);
    };

    const body =
        clicked === true ? (
            <div>
                <Element
                    statementId={statementId}
                    widgetId={widget.id}
                    element={widget.body}
                />
                <Button onClick={onClick} variant="contained" color="primary">
                    Hide Hint
                </Button>
            </div>
        ) : (
            <Button onClick={onClick} variant="contained" color="primary">
                Show Hint
            </Button>
        );

    return (
        <div>
            <Typography variant="body2" color="textPrimary" component="div">
                <p/>
                {body}
                <p/>
            </Typography>
        </div>
    );
}

export default HintWidget;