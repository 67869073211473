export default function buildMakeUser({uuid, isValidId}) {
    const error = "Creating user failed";
    let errorDetails = "";
    const validRolesMap = {
        0: "student",
        1: "teacher",
        2: "admin"
    };

    const isValidUser = (userData) => {
        let isValid = true;

        if (!isValidId) {
            isValid = false;
            errorDetails = "User invalid id";
        }

        if (!userData.role) {
            isValid = false;
            errorDetails = "This user does not seem to have a role";
        } else {
            if (typeof userData.role === 'string') {
                const index = Object.values(validRolesMap).indexOf(userData.role);
                if (index > -1) userData.role = parseInt(Object.keys(validRolesMap)[index]);
                else {
                    isValid = false;
                    errorDetails = "Unknown user role: " + userData.role;
                }
            } else if (typeof validRolesMap[userData.role] === "undefined") {
                errorDetails = "Unknown user role id: " + userData.role;
            }
        }
        return isValid;
    }

    const roleNameToValue = (name) => {
        let value = null;
        Object.keys(validRolesMap).some((k) => {
            if (validRolesMap[k] === name) {
                value = validRolesMap[k];
                return true;
            }
            return false;
        });
        return value;
    }

    return function makeUser({userData}) {
        if (!isValidUser(userData)) {
            throw new Error(error + "\n " + errorDetails);
        }

        return {
            id: userData.id,
            username: userData.username ?? "Unknown",
            email: userData.email ?? "Unknown",
            firstName: userData.firstName ?? "Unknown",
            lastName: userData.lastName ?? "Unknown",
            role: validRolesMap[userData.role],
            roleVal: userData.role,
            token: userData.token ?? "",
            password: userData.password ?? "",
            roleNameToValue: roleNameToValue
        }
    }
}