import repositoryService from "../../repositories";
import mockRepositoryService from "../../mocks";
import makeGetVisits from "./getVisits";
import makeGetLessonCompletionRates from "./getLessonCompletionRates";
import makeGetStatementErrorRates from "./getStatementErrorRates";
import makeGetStatementHintRates from "./getStatementHintRates";
import makeGetUpTimes from "./getUpTimes";

// Pick repo
let repo;
if (process.env.REACT_APP_USE_MOCKS !== 'false') {
    console.log('USING MOCK REPOSITORIES / Statistics');
    repo = mockRepositoryService.statisticsMockRepository;
} else {
    console.log('USING API / Statistics');
    repo = repositoryService.statisticsRepository;
}

// Create Use Cases
const getVisits = makeGetVisits({
    statRepository: repo,
});

const getLessonCompletionRates = makeGetLessonCompletionRates({
    statRepository: repo,
});

const getStatementErrorRates = makeGetStatementErrorRates({
    statRepository: repo,
});

const getStatementHintRates = makeGetStatementHintRates({
    statRepository: repo,
});

const getUpTimes = makeGetUpTimes({
    statRepository: repo,
});

const statisticsService = Object.freeze({
    getVisits,
    getLessonCompletionRates,
    getStatementErrorRates,
    getStatementHintRates,
    getUpTimes,
});

export {getVisits, getLessonCompletionRates, getStatementErrorRates,
    getStatementHintRates, getUpTimes}
export default statisticsService;
