export default function makeLessonRepo({baseUrl, uuid, isJson, getToken, checkError}) {
    const apiToEntityAdapter = {
        approved: "validated",
        modified: "dirty",
        deps: "requiredLessonIds"
    };

    const entityToApiAdapter = {
        validated: "approved",
        dirty: "modified",
        deps: "dependencies"
    };

    /**
     * Adapts server lesson state to server dashboard state
     * @param lesson
     * @param adapter
     * @returns lesson whose state might've been modified
     */
    function adaptState(lesson, adapter) {
        return {
            ...lesson,
            state: adapter[lesson.state] ?? lesson.state,
            dependencies: lesson[adapter.deps] ?? lesson.dependencies,
            requiredLessonIds: lesson[adapter.deps] ?? lesson.requiredLessonIds,
        };
    }

    /**
     * Fetches all lessons from a chapter
     * @param chapterId : chapter id
     * @returns all lessons from chapter with id = chapterId
     */
    async function fetchAll(chapterId) {
        const apiUrl = 'http://localhost:3000/api/chapters/' + chapterId.toString() + '/lessons/';
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'GET',
            headers: myHeaders
        })).json();
        checkError(response);
        response.lessons = response.lessons.map(l => adaptState(l, apiToEntityAdapter))
        return response.lessons;
    }

    /**
     * Fetches a lesson
     * @param chapterId
     * @param lessonId : lesson id
     * @returns lesson with id = lessonId
     */
    async function fetchById(chapterId, lessonId) {
        const apiUrl = baseUrl + 'lessons/' + lessonId.toString();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'GET',
            headers: myHeaders
        })).json();
        checkError(response);
        return adaptState(response);
    }

    /**
     * Add a new lesson
     * @param chapterId : chapter in which lesson is added
     * @param newLesson
     * @returns lesson id
     */
    async function add(chapterId, newLesson) {
        const apiUrl = baseUrl + 'chapters/' + chapterId.toString() + '/lessons';
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(adaptState(newLesson, entityToApiAdapter))
        })).json();
        checkError(response);
        return response.id;
    }

    /**
     * Delete a lesson given its id
     * @param chapterId : chapter from in which lesson is in
     * @param lessonId : lesson id
     * @returns lesson
     */
    async function deleteById(chapterId, lessonId) {
        const apiUrl = baseUrl + 'lessons/' + lessonId.toString();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'DELETE',
            headers: myHeaders
        })).json();

        checkError(response)
        response.id = Number(response.id)
        return response;
    }

    /**
     * Modifies a lesson
     * @param chapterId : chapter from in which lesson is in
     * @param newLesson : lesson with updated info
     * @returns lesson id
     */
    async function update(chapterId, newLesson) {
        const apiUrl = baseUrl + 'lessons/' + newLesson.id.toString();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", getToken());
        const response = await (await fetch(apiUrl, {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(adaptState(newLesson, entityToApiAdapter))
        })).json();
        checkError(response);
        return response.id;
    }

    return Object.freeze({
        fetchAll,
        fetchById,
        add,
        deleteById,
        update,
    });
}