import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import Element from "../../elements/Element";
import {FormControl, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {MediaCard} from "../../../../../components/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  true: {
    color: "green",
    borderColor: "green",
  },
  false: {
    color: "red",
    borderColor: "red",
  },
}));

const SingleQuestionWidget = ({widget, statementId}) => {
    const [value, setValue] = useState("");
    const [done, setDone] = useState(false);
    const [text, setText] = useState("");
    const classes = useStyles();

    const onChange = (event) => {
        setValue(event.target.value);
    };

    const correction = () => {
        if (value === widget.solution) {
            setText("You got the right answer!");
            setDone(true);
        } else {
            setText(widget.mistakeMessage);
            setDone(false);
        }
    };
    const body = (
        <div onChange={onChange}>
            <Typography component="div">
                <Element
                    element={widget.question}
                    statementId={statementId}
                    widgetId={widget.id}
                />
                <div className={done === true ? classes.true : classes.false}>
                    {text}
                </div>
            </Typography>
            <FormControl style={{margin: '5px', width: '100%',}}>
                <TextField id="standard-basic" label="Response"/>
            </FormControl> <br/> <br/>
            <Button
                onClick={correction}
                className={classes.button}
                variant="contained"
                color="primary"
            >
                Correction
            </Button>
        </div>
    );
    return <MediaCard title={"Exercice"} description={body}/>;
}

export default SingleQuestionWidget;