export default function buildMakeWidget(
    {questionWidgetFactory, informationWidgetFactory, makeWidgetFactory}
) {
    const widgetMap = {
        information: "information",
        question: "question"
    };

    const createWidget = (data) => {
        if (typeof data.type !== "undefined") {
            switch (data.type) {
                case widgetMap.information:
                    return informationWidgetFactory.create(data);
                case widgetMap.question:
                    return questionWidgetFactory.create(data);
                default:
                    throw new Error("Unknown Widget Type!");
            }
        } else {
            switch (data.typeString) {
                case "Information Widget":
                    return informationWidgetFactory.create(data);
                case "Question Widget":
                    return questionWidgetFactory.create(data);
                default:
                    throw new Error("Unknown Widget Type");
            }
        }
    }

    const widgetFactory = makeWidgetFactory({
        createWidget
    });

    return function makeWidget({data}) {
        return widgetFactory.create(data);
    }
}
