import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {resetError, selectAuthError, selectStatus, userRegistered} from "./authenticationSlice";
import {useHistory} from "react-router-dom";
import {notificationCreated} from "../notifications/notificationsSlice";
import {CircularProgress} from "@material-ui/core";
import {unwrapResult} from "@reduxjs/toolkit";

const useStyles = makeStyles(theme => ({
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 1300,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const Register = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const emailChanged = (event) => setEmail(event.target.value);
    const [pass, setPass] = useState("");
    const passChanged = (event) => setPass(event.target.value);
    const [passVer, setPassVer] = useState("");
    const passVerChanged = (event) => setPassVer(event.target.value);
    const [username, setUsername] = useState("");
    const usernameChanged = (event) => setUsername(event.target.value);
    const [firstName, setFirstName] = useState("");
    const firstNameChanged = (event) => setFirstName(event.target.value);
    const [lastName, setLastName] = useState("");
    const lastNameChanged = (event) => setLastName(event.target.value);

    const status = useSelector((state) => selectStatus(state));
    const history = useHistory();
    const formSubmitted = (e) => {
        e.preventDefault();
        if(pass.length < 5){
            const notification = {
                message: "Password must be more than 5 characters long",
                severity: "error",
                action: null
            };
            dispatch(notificationCreated(notification));
            return;
        }
        if(pass !== passVer){
            const notification = {
                message: "Password confirmation failed. Check your password",
                severity: "warning",
                action: null
            };
            dispatch(notificationCreated(notification));
            return;
        }
        const newUser = {
            id: null,
            email: email,
            username: username,
            password: pass,
            lastName: lastName,
            firstName: firstName,
            role: "teacher"
        };
        dispatch(userRegistered({newUser: newUser}))
            .then((unwrapResult))
            .then(() => {
                const notification = {
                    message: "Registered successfully!",
                    severity: "success",
                    action: null
                };
                dispatch(notificationCreated(notification));
                dispatch(resetError({}));
                history.push("/login");
            })
    }

    // Handle error
    const errMessage = useSelector((state) => selectAuthError(state));
    useEffect(() => {
        if (status === 'failed' && errMessage !== "") {
            const notification = {
                message: errMessage,
                severity: "error",
                action: null
            };
            dispatch(notificationCreated(notification));
            resetForm();
        }
    }, [errMessage])

    const resetForm = () => {
        setEmail("");
        setPass("");
        setPassVer("");
        setUsername("");
        setFirstName("");
        setLastName("");
        dispatch(resetError({}));
    }

    const registerState = () => {
        if (status === "loading") return <CircularProgress className="pt-1 text-md-center"/>;
        else return (
            <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
            >
                Create your account
            </Button>
        );
    }

    return (
        <div className={classNames(classes.session, classes.background)}>
            <div className={classes.content}>
                <div className={classes.wrapper}>
                    <Card>
                        <CardContent>
                            <form onSubmit={(e) => formSubmitted(e)}>
                                <div
                                    className={classNames(classes.logo, `text-xs-center pb-xs`)}
                                >
                                    <Typography variant="h6">
                                        Welcome to the
                                    </Typography>
                                    <Typography variant="h4">
                                        MSS dashboard
                                    </Typography>
                                    <Divider style={{margin: "10px"}}/>
                                    <Typography variant="caption">
                                        Register to acquire credentials
                                    </Typography>
                                </div>
                                <TextField
                                    required
                                    value={email}
                                    id="email"
                                    label="Email address"
                                    className={classes.textField}
                                    fullWidth
                                    margin="normal"
                                    onChange={(event) => emailChanged(event)}
                                />
                                <TextField
                                    required
                                    value={pass}
                                    id="password"
                                    label="Password"
                                    className={classes.textField}
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    onChange={(event) => passChanged(event)}
                                />
                                <TextField
                                    required
                                    value={passVer}
                                    id="cpassword"
                                    label="Confirm Password"
                                    className={classes.textField}
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    onChange={(event) => passVerChanged(event)}
                                />

                                <TextField
                                    required
                                    value={username}
                                    id="username"
                                    label="Username"
                                    className={classes.textField}
                                    fullWidth
                                    margin="normal"
                                    onChange={(event) => usernameChanged(event)}
                                />

                                <TextField
                                    required
                                    value={firstName}
                                    id="firstName"
                                    label="First name"
                                    className={classes.textField}
                                    fullWidth
                                    margin="normal"
                                    onChange={(event) => firstNameChanged(event)}
                                />

                                <TextField
                                    required
                                    value={lastName}
                                    id="lastName"
                                    label="Last name"
                                    className={classes.textField}
                                    fullWidth
                                    margin="normal"
                                    onChange={(event) => lastNameChanged(event)}
                                />

                                <Divider style={{margin: "10px"}}/>

                                {registerState()}

                                <div className="pt-1 text-xs-center">
                                    <Link to="/forgot">
                                        <Button>Forgot password?</Button>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Link to="/login">
                                        <Button>Access your account.</Button>
                                    </Link>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Register;