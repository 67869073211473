import React from "react";
import {Card, Paper} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {addStatement, selectOnEditStatements} from "../statementsSlice";
import Statement from "./Statement";
import Button from "@material-ui/core/Button";
import {infoColor} from "../../../styleVariables";


const Previewer = () => {
    const dispatch = useDispatch();
    const statements = useSelector((state) => selectOnEditStatements(state));
    const add = () => {
        dispatch(addStatement({}));
    }

    return (
        <Card style={{width: '100%', height: '900px', overflowY: "scroll"}}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Preview
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider style={{marginBottom: "20px"}}/>
            {statements.map((statement, i) => (
                <Statement
                    key={statement.id ?? i}
                    statement={statement}
                />
            ))}
            <Paper
                elevation={5}>
                <Button
                    onClick={add}
                    style={{color: infoColor, margin: "10px"}}
                    variant="outlined"
                >
                Add statement
                </Button>
            </Paper>
        </Card>
    );
};

export default Previewer;