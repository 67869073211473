import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchStatementHintRates,selectStatementHintRates} from "./homeSlice";
import {CenteredLoader} from "../../components";
import {presentErrorRates, presentHintRates} from "../../presenters/StatisticsPresenter";
import {Bar} from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import {FormControl, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const HintRatesChart = () => {
    const dispatch = useDispatch();
    const hintRates = useSelector(state =>
        selectStatementHintRates(state));

    useEffect(() => {
        dispatch(fetchStatementHintRates({}));
    }, []);

    const [nbStatements, setNbStatements] = useState(10);
    const [offset, setOffset] = useState(0);
    const next = () => {
        if(offset < hintRates.data.length / nbStatements) setOffset(offset + 1);
    }
    const prev = () => {
        if (offset > 0) setOffset(offset - 1);
    }

    if (hintRates.status === "loading")
        return (<CenteredLoader/>);

    return (
        <div style={{padding: "10px"}}>
            <Grid container direction="row">
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <FormControl style={{margin: '5px', width: '80%', height: "100%"}}>
                        <TextField
                            onChange={(event) => {
                                let val = 1;
                                try {
                                    val = parseInt(event.target.value);
                                    val = (val <= 0) ? 1 : val;
                                    val = (val >= 10) ? 10 : val;
                                } catch (_) {
                                }
                                setNbStatements(val)
                            }
                            }
                            style={{width: "80%"}}
                            id="columns"
                            label="Number of Statements (max 10)"
                            type="number"
                            inputProps={{min: 1, max: 10, type: "number"}}
                            fullWidth
                            value={nbStatements}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Button
                        onClick={prev}
                        variant="outlined">
                        Previous
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Button
                        onClick={next}
                        variant="outlined">
                        Next
                    </Button>
                </Grid>
            </Grid>
            <Bar
                data={presentHintRates(hintRates.data, nbStatements, offset)}
                options={{
                    title: {
                        display: true,
                        text: "User Visits",
                        fontSize: 20,
                    },
                    legend: {
                        display: false,
                        position: "center"
                    },
                    scales: {
                        y: {
                            suggestedMax: 1,
                        }
                    }
                }}
                type='bar'
            />
        </div>
    );
}

export default HintRatesChart;