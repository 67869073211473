import makeUser from "../../entities/user";
import makeError from "../../entities/error";

export default function makeLogin({userRepository, storage}){
    return async function login({email, password, persist}) {
        try {
            let res = await userRepository.login({
                email: email,
                password: password,
            });
            const user = makeUser({
                userData: res
            });

            if(persist) {
                storage.write("user", user);
                storage.write("is_authenticated", true);
            }

            return user;
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered " +
                    "a problem"
            });
        }
    }
}