export default function buildMakeChapter({uuid, makeLesson, isValidId}) {
    return function makeChapter (
        {
            id = uuid.make(),
            name = '',
            description = '',
            lessons = []
        } = {}) {
        if(name.length < 5)
            throw new Error('Chapter name must be at least 5 characters long');
        if(!Array.isArray(lessons))
            throw new Error('The lessons of a chapter must be an array!');
        if(!isValidId)
            throw new Error('The id of a chapter must be a valid id');

        const validLessons = lessons.map((lesson) => {
            return makeLesson(lesson);
        });

        return {
            id: id,
            name: name,
            lessons: validLessons,
            description: description,
        };
    }
}