import makeStatement from "../entities/statement";
import {makeStep} from "../entities/statement";
import {steps, statements} from "./Seeder";
import {chapterMockRepository, lessonMockRepository} from "./index";

export default function makeStatementMock(makeId, sleep) {
    let data = {};
    let cur_statements = statements;

    async function fetchAllSteps(lesson_id) {
        let lessons = [];
        let chapters = await chapterMockRepository.fetchAll();
        await Promise.all(chapters.map(async (chapter) => {
            let chapter_lessons = await lessonMockRepository.fetchAll(chapter.id);
            lessons.push(...chapter_lessons);
        }));
        lessons.forEach((lesson) => {
            data[lesson.id] = [...steps];
            data[lesson.id].map((step) => {
                step.lessonId = lesson.id
                return step;
            });
        });
        return data[lesson_id];
    }

    async function deleteStepById(lesson_id, step_id) {
        await sleep(600);
        data[lesson_id] = data[lesson_id].filter((step) => step.id === step_id);
        return step_id;
    }

    async function updateStep(lesson_id, step_id, step) {
        await sleep(600);
        data[lesson_id].some((s, i) => {
            if (s.id === step_id) {
                data[lesson_id][i] = makeStep({
                    stepData: step
                });
                return true;
            }
            return false;
        });
        return step.id;
    }

    async function addStep(lesson_id, step_id, step) {
        await sleep(600);
        step.id = step.id ?? makeId();
        step = makeStep({stepData: step});
        if (Array.isArray(data[lesson_id])) {
            data[lesson_id].push(step);
        } else {
            data[lesson_id] = [step];
        }
        return data[lesson_id][data[lesson_id].length - 1].id;
    }

    async function fetchStepById(lesson_id, step_id) {
        await sleep(600);
        let step = null;
        data[lesson_id].find((s) => {
            if (s.id === step_id) {
                step = s;
                return true;
            }
        });
        return step;
    }

    async function fetchAllStatements(lesson_id, step_id) {
        await sleep(600);
        const stmts = cur_statements.filter((stmt) => stmt.stepId === step_id);
        const step = data[lesson_id].find(s => s.id === step_id);
        step.statements = stmts;
        return stmts;
    }

    async function addStatement(lesson_id, step_id, statement) {
        await sleep(600);
        statement.id = statement.id ?? makeId();
        const step = data[lesson_id].find(s => s.id === step_id);
        step.statements.push({...statement});
        cur_statements.push(statement);
        return step.statements[step.statements.length - 1].id;
    }

    async function deleteStatementById(lesson_id, step_id, statement_id) {
        await sleep(600);
        const stepIndex = data[lesson_id].findIndex(s => s.id === step_id);
        if(stepIndex < 0) throw new Error("Step not found!");
        const statementIndex = data[lesson_id][stepIndex].statements.findIndex(stmt => stmt.id === statement_id);
        if(statementIndex < 0) throw new Error("Statement not found");
        delete data[lesson_id][stepIndex].statements.splice(statementIndex, 1);
        cur_statements = cur_statements.filter(s => s.id !== statement_id);
        return statement_id;
    }

    async function fetchStatementById(lesson_id, step_id, statement_id) {
        await sleep(600);
        let statement = null;
        data[lesson_id].some((s) => {
            if (s.id === step_id) {
                s.statements.find((stmt) => {
                    if (stmt.id === statement_id)
                        statement = stmt;
                    return true;
                });
                return true;
            }
            return false;
        });
        return statement;
    }

    async function updateStatement(lesson_id, step_id, statement) {
        const step = data[lesson_id].find(s => s.id === step_id);

        const index = step.statements.findIndex(stmt => stmt.id === statement.id);
        step.statements[index] = makeStatement({
            data: {...statement}
        });

        const index_2 = cur_statements.findIndex(stmt => stmt.id === statement.id);
        cur_statements[index_2] = makeStatement({
            data: {...statement}
        });

        return statement.id;
    }

    return Object.freeze({
        fetchAllSteps,
        fetchStepById,
        deleteStepById,
        addStep,
        updateStep,
        fetchAllStatements,
        fetchStatementById,
        deleteStatementById,
        addStatement,
        updateStatement,
    });
}



