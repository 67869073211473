import makeError from "../../entities/error";
import makeUser from "../../entities/user";

export default function makeAddUser({userRepository}) {
    return async function addUser({user}) {
        try {
            user.id = await userRepository.addUser({
                user: user
            });
            return makeUser({
                userData: user
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered " +
                    "a problem adding the user."
            });
        }
    }
}