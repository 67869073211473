import React from "react";
import Button from "@material-ui/core/Button";
import {errorColor, successColor} from "../styleVariables";

export const createColumns = ({onDelete, onSave, renderRoleEditCell}) => {
    return [
        {field: "username", headerName: "Username", width: 150, editable: true},
        {field: "email", headerName: "Email", width: 150, editable: true},
        {field: "firstName", headerName: "First Name", width: 150, editable: true},
        {field: "lastName", headerName: "Last Name", width: 150, editable: true},
        {
            field: "role", headerName: "Role", width: 150, editable: true, type: "object",
            renderEditCell: renderRoleEditCell,
        },
        {
            field: "id", headerName: "Actions", width: 250, editable: false,
            renderCell: (params) => (
                <div>
                    <Button
                        onClick={() => onDelete(params.value)}
                        style={{color: errorColor}}
                        variant="outlined"
                        size="small"
                    >
                        Delete User
                    </Button>
                    <Button
                        onClick={() => onSave(params.value)}
                        style={{color: successColor}}
                        variant="outlined"
                        size="small"
                    >
                        Save User
                    </Button>
                </div>
            )
        }
    ];
}