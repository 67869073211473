export default function buildMakeMCQWidget(
    {subtypeString, subtypeVal, elementFactory}
) {
    const error = "Creating a MCQ Widget failed! ";
    let errorDetails = "";

    const isValidWidget = (data) => {
        let isValid = true;
        if(data.subtype !== subtypeVal
            && data.subtypeString !== subtypeString) {
            isValid = false;
            errorDetails += "Invalid type for a MCQ widget. ";
        }

        if(!data.question || (typeof data.question !== "object")) {
            isValid = false;
            errorDetails += "No Question found. ";
        }

        if(!Array.isArray(data.choices)
            || !Array.isArray(data.solutions)) {
            isValid = false;
            errorDetails += "Expected an array of choices and solutions";
        } else {
            if(data.choices.length !== data.solutions.length) {
                isValid = false;
                errorDetails += "Choices and solutions length mismatch";
            }
        }
        return isValid;
    }

    return function makeMCQWidget({data}) {
        if(!isValidWidget(data)) {
            throw new Error(error + "\n" + errorDetails);
        }

        return {
            subtype: subtypeVal,
            subTypeString: subtypeString,
            choices: data.choices.map((choice) =>
                elementFactory.create(choice)),
            solutions: data.solutions,
            question: elementFactory.create(data.question)
        };
    }
}