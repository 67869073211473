import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchVisits, selectVisits} from "./homeSlice";
import {CenteredLoader} from "../../components";
import {presentVisitsData} from "../../presenters/StatisticsPresenter";
import {Bar} from "react-chartjs-2"
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

const VisitsChart = () => {
    const dispatch = useDispatch();
    const visits = useSelector((state) => selectVisits(state));

    useEffect(() => {
        dispatch(fetchVisits({}));
    }, []);

    const date = new Date();
    const intervals = ["DAY", "WEEK", "MONTH"];
    const [interval, setInterval] = useState("DAY");
    const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 7));
    const [endDate, setEndDate] = useState(Date.now());

    let chartData = presentVisitsData(visits.data, startDate, endDate, interval);
    useEffect(() => {
        chartData = presentVisitsData(visits.data, startDate,
            endDate, interval);
    }, [startDate, endDate, interval]);

    if (visits.status === "loading")
        return (<CenteredLoader/>);

    return (
        <div style={{padding: "10px"}}>
            <Grid container direction="row">
                <Grid item xs={3} sm={3} md={3} lg={3} style={{marginLeft: "10px"}}>
                    <FormControl style={{margin: '5px', width: '80%', height: "100%"}}>
                        <InputLabel>Interval</InputLabel>
                        <Select
                            onChange={(event) =>
                                setInterval(event.target.value)}
                            value={interval}
                        >
                            {intervals.map((int) => (
                                <MenuItem
                                    key={int}
                                    value={int}>
                                    {int}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <FormControl style={{margin: '5px', width: '80%', height: "100%"}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                margin="normal"
                                label="Start Date"
                                id="date-picker-inline"
                                value={startDate}
                                onChange={setStartDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <FormControl style={{margin: '5px', width: '80%', height: "100%"}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                margin="normal"
                                label="Start Date"
                                id="date-picker-inline"
                                value={endDate}
                                onChange={setEndDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
            </Grid>
            <Bar
                data={chartData}
                options={{
                    title: {
                        display: true,
                        text: "User Visits",
                        fontSize: 20,
                    },
                    legend: {
                        display: false,
                        position: "center"
                    }
                }}
                type='bar'
            />
        </div>
    );
}

export default VisitsChart;