import makeUser from "../../entities/user";
import makeError from "../../entities/error";

export default function makeListUsers({userRepository}) {
    return async function listUsers({}) {
        try {
            const users = await userRepository.fetchAllUsers({});
            return users.map(u => {
                try {
                    return makeUser({
                        userData: u
                    });
                } catch (err) {
                    return makeError({
                        code: err.code ?? -1,
                        message: err.message,
                        description: err.description ?? "The application encountered" +
                            "a problem trying to fetch some user"
                    });
                }
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered a " +
                    "problem trying to fetch the users"
            });
        }
    }
}