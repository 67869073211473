// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Material UI
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import withWidth from '@material-ui/core/withWidth';

// Components
import SidebarItem from './SidebarItem';

// Styles
import { useStyles } from './SidebarStyles';
import {useSelector} from "react-redux";
import {selectUser} from "../../features/authentication/authenticationSlice";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const Sidebar = ({
    opened,
    toggleDrawer,
    routes,
    location
}) => {
    const classes = useStyles();
    const user = useSelector((state) => selectUser(state));
    const [activeRoute, setActiveRoute] = useState(undefined);
    const toggleMenu = index => {
        setActiveRoute(activeRoute === index ? undefined : index);
    }

    const menu = (
        <List component="div">
            {routes.map((route, index) => {
                if (route.role !== null && route.role !== user.role)
                    return null;
                const isCurrentPath = true
                return (
                    <SidebarItem
                        key={index}
                        index={index}
                        route={route}
                        activeRoute={activeRoute}
                        toggleMenu={toggleMenu}
                        currentPath={isCurrentPath}
                    />
                );
            })}
        </List>
    );

    return (
        <>
            <Hidden smDown>
                <Drawer
                    variant="persistent"
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    open={opened}
                    ModalProps={{
                        keepMounted: false,
                        className: classes.modal,
                        BackdropProps: {
                            className: classes.backdrop
                        },
                        onBackdropClick: toggleDrawer
                    }}
                >
                    {menu}
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <SwipeableDrawer
                    variant="temporary"
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    open={opened}
                    onClose={toggleDrawer}
                    onOpen={toggleDrawer}
                    disableBackdropTransition={!iOS}
                    ModalProps={{
                        keepMounted: false,
                        className: classes.modal,
                        BackdropProps: {
                            className: classes.backdrop
                        },
                        onBackdropClick: toggleDrawer
                    }}
                >
                    {menu}
                </SwipeableDrawer>
            </Hidden>
        </>
    );
};

Sidebar.prototypes = {
    opened: PropTypes.func,
    toggleDrawer: PropTypes.func,
    closeDrawer: PropTypes.func,
    openDrawer: PropTypes.func,
    routes: PropTypes.object
};

const SidebarWithRouter = withRouter(Sidebar);
export default withWidth()(SidebarWithRouter);
