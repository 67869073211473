import lessonsService from "../usecases/lessons";

export async function createLessonController(chapter, newLesson, isEdit) {
    if(!isEdit)
        return await lessonsService.createLesson(chapter, newLesson);
    return await lessonsService.updateLesson(chapter, newLesson);
}

export async function deleteLessonController(chapter, lesson) {
    return await lessonsService.deleteLesson(chapter, lesson);
}

export async function setLessonState(lesson, chapter, oldState) {
    let res = lesson;

    if(lesson.state === "deleted" || lesson.state === "pending" || lesson.state === "dirty"
        || (lesson.state === "validated" && oldState !== "deleted")) {
        res = await createLessonController(chapter, lesson, true);
    } else if(lesson.state === "validated" && oldState === "deleted") {
        res = await deleteLessonController(chapter, lesson);
        res.wasDeleted = true;
    }

    return res;
}

export function getLessonDependencies(curLesson, allLessons) {
    return lessonsService.getLessonDependencies({
        root: curLesson, deps: allLessons,
        current: curLesson, nbOfNodes: allLessons.length
    });
}


const LessonController = {
    createLessonController,
    deleteLessonController,
    getLessonDependencies,
    setLessonState,
};

export default LessonController;