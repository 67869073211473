import makeUser from "../../entities/user";
import makeError from "../../entities/error";

export default function makeUpdateUser({userRepository}) {
    return async function updateUser({user}) {
        try {
            user.id = await userRepository.update({
                user: user
            });
            return makeUser({
                userData: user
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message: err.message,
                description: err.description ?? "The application encountered " +
                    "a problem trying to update this user's data"
            });
        }
    }
}