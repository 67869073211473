import repositoryService from "../../repositories";
import mockRepositoryService from "../../mocks";
import Storage from "../../utils/Storage";
import makeLogin from "./login";
import makeGetInitState from "./getInitState";
import makeLogout from "./logout";
import makeRegister from "./register";
import makeUpdateUser from "./update";
import makeListUsers from "./listUsers";
import makeDeleteUser from "./deleteUser";
import makeGetUser from "./getUser";
import makeAddUser from "./addUser";

let repo;
if(process.env.REACT_APP_USE_MOCKS !== "false"){
    console.log("USING MOCK REPOSITORIES / Auth");
    repo = mockRepositoryService.userRepoMock;
} else {
    console.log("USING API / Auth");
    repo = repositoryService.userRepository;
}

const login = makeLogin({
    userRepository: repo,
    storage: Storage,
});

const logout = makeLogout({
    userRepository: repo,
    storage: Storage,
});

const getInitState = makeGetInitState({
    userRepository: repo,
    storage: Storage,
});

const register = makeRegister({
    userRepository: repo,
});

const update = makeUpdateUser({
    userRepository: repo,
});

const list = makeListUsers({
    userRepository: repo,
});

const deleteUser = makeDeleteUser({
    userRepository: repo,
});

const getUser = makeGetUser({
    userRepository: repo,
});

const addUser = makeAddUser({
    userRepository: repo,
});

const authService = Object.freeze({
    login,
    logout,
    getInitState,
    register,
    update,
    list,
    deleteUser,
    getUser,
    addUser,
});

export {login, logout, getInitState, register,
    update, list, deleteUser, getUser};
export default authService;