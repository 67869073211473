import statisticsService from "../usecases/statistics";

export async function getVisits() {
    return await statisticsService.getVisits({});
}

export async function getLessonCompletionRates() {
    return await statisticsService.getLessonCompletionRates({});
}

export async function getStatementErrorRates() {
    return await statisticsService.getStatementErrorRates({});
}

export async function getStatementHintRates() {
    return await statisticsService.getStatementHintRates({});
}

export async function getUpTimes() {
    return await statisticsService.getUpTimes({});
}

const StatisticsController = Object.freeze({
    getVisits,
    getLessonCompletionRates,
    getStatementErrorRates,
    getStatementHintRates,
    getUpTimes,
});

export default StatisticsController;