import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DialogContentText} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {chapterSaved, selectAddingStatus} from "../lessonsSlice";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";


const ChapterPopup = ({open, handleToggle, chapter}) => {
    const dispatch = useDispatch();
    const addingStatus = useSelector(selectAddingStatus);
    const [chapterTitle, setChapterTitle] = useState(JSON.parse(JSON.stringify(chapter.title)));
    const [chapterDesc, setChapterDesc] = useState(JSON.parse(JSON.stringify(chapter.description)));
    const titleChanged = (event) => {
        setChapterTitle(event.target.value);
    }
    const descChanged = (event) => {
        setChapterDesc(event.target.value);
    }

    const addChapter = () => {
        const lessonBoardName = chapterTitle;
        const newChapter = {
            id: chapter.id,
            name: chapterTitle,
            description: chapterDesc,
            lessons: chapter.lessons ?? [],
        };

        if (addingStatus === 'idle') {
            dispatch(chapterSaved({lessonBoardName, chapter: newChapter}));
            handleToggle();
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth='sm' open={open}>
            <DialogTitle id='form-dialog-title'>Creating a new Chapter ...</DialogTitle>
            <Divider/>
            <Box mr={2} ml={2} mt={0}>
                <DialogContentText style={{marginTop: '20px'}}>
                    Fill in a <strong>title</strong> and a <strong>description</strong> for the new chapter!
                </DialogContentText>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <TextField
                            onChange={titleChanged}
                            autoFocus
                            id="chapter"
                            label="Chapter Title"
                            type="text"
                            fullWidth
                            defaultValue={chapterTitle}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={descChanged}
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={4}
                            defaultValue={chapterDesc}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
            <DialogActions>
                <Button onClick={handleToggle} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={addChapter}
                    color="primary">
                    Done!
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChapterPopup;