import React from "react";
import TextElement from "./TextElement";
import Selectable from "../Selectable";
import {elementSelected} from "../../statementsSlice";
import ImageElement from "./ImageElement";

const Element = ({statementId, widgetId, element}) => {
    const makeOnSelected = () => {
        return elementSelected({
            statementId: statementId,
            widgetId: widgetId,
            elementId: element.id
        });
    }

    const getElement = () => {
        switch (element && element.type) {
            case "text":
                return <TextElement element={element}/>
            case "image":
                return <ImageElement element={element}/>
            default:
                return <h1> Unknown Element </h1>
        }
    }

    return (
        <Selectable
            onSelected={makeOnSelected}
            isSelected={element.isSelected}
            actions={null}
        >
            {getElement()}
        </Selectable>
    )
}

export default Element;