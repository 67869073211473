import katex from 'katex';
import {v4 as uuidv4} from 'uuid';

const makeId = () => {
    return uuidv4();
}

export const defaultElement = () => {
    return {
        id: makeId(),
        type: "text",
        content: "Text Element Content",
        isSelected: true
    }
};

const widget = () => {
    return {
        name: "",
        description: "",
        column: 1,
        index: 1,
        isSelected: true,
    }
};

const informationWidget = () => {
    return {
        ...widget(),
        type: "information"
    }
};

const defaultGenericWidget = () => {
    return {
        ...informationWidget(),
        id: makeId(),
        subtype: "generic",
        body: defaultElement(),
    }
};

const defaultHintWidget = () => {
    return {
        ...informationWidget(),
        id: makeId(),
        subtype: "hint",
        body: defaultElement()
    }
};

const questionWidget = () => {
    return {
        ...widget(),
        id: makeId(),
        type: "question",
        error: "Error Message",
        difficulty: 1,
    }
};

const defaultSingleQuestionWidget = () => {
    return {
        ...questionWidget(),
        id: makeId(),
        subtype: "single_question",
        question: defaultElement(),
        solution: "Single Question Solution"
    }
};

const defaultMCQWidget = () => {
    return {
        ...questionWidget(),
        id: makeId(),
        subtype: "mcq",
        question: defaultElement(),
        choices: [{...defaultElement(), isSelected: false}],
        solutions: [true]
    }
};

export const defaultWidget = () => {
    return defaultGenericWidget();
}

export const getDefaultWidget = (subtype) => {
    switch (subtype) {
        case "generic":
            return defaultGenericWidget();
        case "hint":
            return defaultHintWidget();
        case "single_question":
            return defaultSingleQuestionWidget();
        case "mcq":
            return defaultMCQWidget();
        default:
            return defaultGenericWidget();
    }
};

export const defaultStatement = () => {
    return {
        id: makeId(),
        type: "theory",
        name: "Statement Name",
        description: "Statement Description",
        columns: 1,
        index: 0,
        widgets: [],
        isSelected: false,
    }
};

export const statementTypes = {
    theory: "Theory",
    exercise: "Exercise",
    problem: "Problem"
};

export const widgetTypes = {
    information: {
        name: "Information Widget",
        subtypes: {
            generic: {
                name: "Generic Widget",
            },
            hint: {
                name: "Hint Widget",
            }
        },
    },
    question: {
        name: "Question Widget",
        subtypes: {
            mcq: {
                name: "Multiple Choice Widget"
            },
            single_question: {
                name: "Single Question Widget"
            }
        }
    }
};

export const elementTypes = {
    text: "Text Element",
    image: "Image Element",
    graph: "Graph Element",
};

export const html2equations = (contents) => {
    let root = document.createElement("div");
    root.innerHTML = contents;
    for (let el of root.children) {
        if (el.getElementsByClassName("__se__katex").length) {
            for (let element of el.getElementsByClassName("__se__katex")) {
                let equation = element.dataset.exp.replace(/\s/g, '');
                let equationHtml = document.createElement("span");
                equationHtml.className = "equation";
                equationHtml.innerHTML = equation;
                element.parentNode.replaceChild(equationHtml, element);
            }
        }
    }
    return root.innerHTML;
}

export const equations2html = (fakeHtml) => {
    const fakeHtmlObject = document.createElement("div");
    fakeHtmlObject.innerHTML = fakeHtml;
    for (let child of fakeHtmlObject.children) {
        if (child.getElementsByClassName("equation").length) {
            for (let equation of child.getElementsByClassName("equation")) {
                let tmp = document.createElement("div");
                tmp.innerHTML = katex.renderToString(equation.innerHTML, {
                    throwOnError: false
                });
                tmp.firstChild.className += " __se__katex";
                tmp.firstChild.setAttribute("contenteditable", "false");
                tmp.firstChild.dataset.exp = equation.innerHTML;
                tmp = tmp.firstChild;
                equation.parentNode.replaceChild(tmp, equation);
            }
        }
    }
    return fakeHtmlObject.innerHTML;
}

export const unselectWidget = (widget) => {
    if (!widget || typeof widget === "undefined") return;
    widget.isSelected = false;
    switch (widget.type) {
        case "information":
            switch (widget.subtype) {
                case "generic":
                    widget.body.isSelected = false;
                    break;
                case "hint":
                    widget.body.isSelected = false;
                    break;
            }
            break;
        case "question":
            switch (widget.subtype) {
                case "single_question":
                    widget.question.isSelected = false;
                    break;
                case "mcq":
                    widget.choices.forEach(c => c.isSelected = false);
                    widget.question.isSelected = false;
                    break;
            }
            break;
    }
}

export const prepareStatements = (statements) => {
    statements.sort((s1, s2) => s1.index - s2.index);
    statements.forEach((statement, i) => {
        const nbOfCols = statement.columns;
        statement.isSelected = false
        statement.widgets.forEach(w => {
            unselectWidget(w);
            w.column = Math.min(w.column, nbOfCols);
        });
        statement.widgets.sort((w1, w2) => w1.index - w2.index);
    });
    return statements;
}