import makeChapter from "../../entities/chapter";
import makeError from "../../entities/error";

export default function makeAddChapter({chapterRepository}) {
    return async function addChapter({newChapter}) {
        try {
            let res = null;
            if(newChapter.id === null) {
                res = await chapterRepository.add({
                    name: newChapter.name,
                    description: newChapter.description ?? ""
                });
            } else {
                res = await chapterRepository.update({
                    id: newChapter.id,
                    name: newChapter.name,
                    description: newChapter.description ?? ""
                });
            }

            return makeChapter({
                id: res,
                name: newChapter.name,
                description: newChapter.description ?? "",
                lessons: newChapter.lessons ?? [],
            });
        } catch(err) {
            return makeError({
                code: err.code ?? -1,
                message:err.message,
                description: err.description ?? 'The application encountered' +
                    'a problem trying to add this chapter.'
            });
        }
    }
}