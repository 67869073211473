// React
import React, {useEffect, useState} from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Chip, DialogContentText, FormControl, Input, InputLabel, MenuItem, Select} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {lessonSaved} from '../lessonsSlice';
import {
    selectAddingStatus,
    selectDependencies,
    setDependencies,
    setEditingLesson,
    selectEditingLesson
} from "../lessonsSlice";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 250,
        },
    },
};


const LessonPopup = ({open, lesson, subject, handleToggle, chapter, isEdit}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const addingStatus = useSelector(selectAddingStatus);
    const availableDeps = useSelector(selectDependencies);
    const editingLesson = useSelector(selectEditingLesson);
    const [lessonTitle, setLessonTitle] = useState(editingLesson.name);
    const [lessonDesc, setLessonDesc] = useState(editingLesson.description);

    // Fetch initial data
    useEffect(() => {
        let copy = JSON.parse(JSON.stringify(lesson));

        dispatch(setEditingLesson({
            lesson: copy
        }));

        setLessonTitle(copy.name);
        setLessonDesc(copy.description);

        dispatch(setDependencies({
            chapterId: chapter.id,
            lesson: copy,
            dependencies: copy.dependencies
        }));
    }, [lesson, dispatch, chapter.id]);


    const titleChanged = (event) => setLessonTitle(event.target.value);
    const descChanged = (event) => setLessonDesc(event.target.value);
    const depsChanged = (event) => {
        dispatch(setDependencies({
            chapterId: chapter.id,
            dependencies: event.target.value,
        }));
    };

    const getLessonNameById = (id) => {
        let name;
        chapter.lessons.forEach((lesson) => {
            if (lesson.id === id) {
                name = lesson.name;
            }
        });
        return name;
    }
    const isDepAvailable = (depId) => {
        return (availableDeps.some((available) => available.id === depId)) ||
            editingLesson.dependencies.includes(depId);
    }

    const addLesson = () => {
        if (addingStatus === 'idle' || addingStatus === 'failed') {
            dispatch(lessonSaved({
                newLesson: {
                    ...editingLesson,
                    name: lessonTitle,
                    description: lessonDesc,
                    state: "dirty",
                },
                chapterId: chapter.id,
                isEdit: isEdit,
            }));
            handleToggle();
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth='sm' open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id='form-dialog-title'>{subject.toUpperCase()}</DialogTitle>
            <Divider/>
            <Box mr={2} ml={2} mt={0}>
                <DialogContentText style={{marginTop: '20px'}}>
                    You're creating a lesson for <strong>{chapter.title}</strong>.
                    Please select a <strong>title</strong> and a short <strong>description</strong>
                </DialogContentText>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <TextField
                            onChange={titleChanged}
                            autoFocus
                            id="title"
                            label="Lesson Title"
                            type="text"
                            fullWidth
                            defaultValue={lessonTitle}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={descChanged}
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={4}
                            defaultValue={lessonDesc}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="deps">Dependencies</InputLabel>
                            <Select
                                labelId="deps"
                                id="lesson_dependencies"
                                multiple
                                value={editingLesson.dependencies}
                                onChange={depsChanged}
                                input={<Input id="select-multiple-chip"/>}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={getLessonNameById(value)}
                                                className={classes.chip}/>
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {chapter.lessons.map((depLesson) => (
                                    isDepAvailable(depLesson.id) ?
                                        <MenuItem key={depLesson.id} value={depLesson.id}>
                                            {depLesson.name}
                                        </MenuItem> : <MenuItem disabled
                                            key={depLesson.id} value={depLesson.id}>
                                            {depLesson.name}
                                        </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <DialogActions>
                <Button onClick={() => handleToggle()} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={addLesson}
                    color="primary">
                    Done!
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LessonPopup;