export default function buildMakeQuestionWidgetFactory({}) {
    const error = "Creating a question widget failed! ";
    let errorDetails = "";

    const isValidData = (data) => {
        let isValid = true;
        if (data.difficulty < -1) {
            isValid = false;
            errorDetails += "Invalid difficulty for the question widget. ";
        }

        if (typeof data.mistakeMessage !== "string" && typeof data.error !== "string") {
            isValid = false;
            errorDetails += "Invalid error message for the question widget. ";
        }

        return isValid;
    }

    return function makeQuestionWidgetFactory({createWidget}) {
        return {
            create: (data) => {
                if (!isValidData(data)) {
                    throw new Error(error + "\n" + errorDetails);
                }
                return {
                    difficulty: data.difficulty,
                    error: data.mistakeMessage ?? data.error,
                    mistakeMessage: data.mistakeMessage ?? data.error,
                    ...createWidget(data),
                };
            }
        }
    }
}