import React from "react";
import {Card, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {selectOnEditElement, setElement} from "../statementsSlice";
import TextElementEditor from "./elementEditors/TextElementEditor";
import {elementTypes} from "../../../presenters/EditorPresenter";
import ImageElementEditor from "./elementEditors/ImageElementEditor";

const InstanceForm = () => {
    const dispatch = useDispatch();
    const setValue = (key, value) => {
        dispatch(setElement({
            key: key,
            value: value
        }));
    }
    const element = useSelector((state) => selectOnEditElement(state));

    const getContent = () => {
        if (typeof element === "undefined" || element === null)
            return (<h6> No Element Selected </h6>);
        return (
            <Grid container spacing={1} alignItems="stretch"
                  align="center" justify="center" direction="row"
            >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl style={{margin: '5px', width: '80%',}}>
                        <InputLabel>Widget Subtype</InputLabel>
                        <Select
                            onChange={(event) =>
                                setValue("type", event.target.value)}
                            value={element.type}
                        >
                            {Object.keys(elementTypes).map((type) => (
                                <MenuItem
                                    key={type}
                                    value={type}>
                                    {elementTypes[type]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {element ? getElementEditor() : null}
            </Grid>
        );
    }
    const getElementEditor = () => {
        switch (element.type) {
            case "text":
                return <TextElementEditor element={element}/>
            case "image":
                return <ImageElementEditor element={element} />
        }
    }

    return (
        <Card style={{
            width: '100%', marginLeft: '5px',
            marginRight: '5px', marginTop: '5px',
            height: '100%',
            overflowY: "scroll"
        }}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Element
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider/>
            {getContent()}
        </Card>
    );
};

export default InstanceForm;