import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AuthController from "../../controllers/AuthController";
import {notificationCreated} from "../notifications/notificationsSlice";

const initialState = AuthController.getInitState();

const handleError = (error, action, thunkAPI) => {
    const notification = {
        message: error.message,
        severity: 'error',
        action: null,
    }
    thunkAPI.dispatch(notificationCreated(notification));
    return thunkAPI.rejectWithValue(error);
}

export const userLoggedIn = createAsyncThunk(
    "authentication/login",
    async ({email, password, persist}, thunkAPI) => {
        const res = await AuthController.login(email, password, persist);
        if(res.error) return thunkAPI.rejectWithValue(res);
        return {
            user: res
        }
    }
);

export const userRegistered = createAsyncThunk(
    "authentication/register",
    async ({newUser}, thunkAPI) => {
        const res = await AuthController.register(newUser);
        if(res.error) return thunkAPI.rejectWithValue(res);
        return {
            user: res
        };
    }
)

export const userUpdated = createAsyncThunk(
    "authentication/update",
    async ({updatedUser}, thunkAPI) => {
        const res = await AuthController.update(updatedUser);
        if (res.error) return handleError(res, null, thunkAPI);
        return {
            user: res
        };
    }
);

const authenticationSlice = createSlice({
    name: "authentication",
    initialState: initialState,
    reducers: {
        resetUser: (state, action) => {
            state.user = {};
            state.isAuthenticated = false;
            state.errMessage = action.payload.error;
            AuthController.logout();
        },
        resetError: (state, action) => {
            state.errMessage = "";
            state.errDescription = "";
            state.status = "idle";
        },
    },
    extraReducers: {
        [userLoggedIn.pending]: (state, action) => {
            state.status = "loading"
        },
        [userLoggedIn.fulfilled]: (state, action) => {
            state.isAuthenticated = true;
            state.isAdmin = action.payload.user.role === "admin";
            state.user = action.payload.user;
            state.status = "success";
            state.errMessage = "";
            state.errDescription = "";
        },
        [userLoggedIn.rejected]: (state, action) => {
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.user = {...initialState.user};
            state.status = "failed";
            state.errMessage = action.payload.message;
            state.errDescription = action.payload.description;
        },
        [userRegistered.pending]: (state, action) => {
            state.status = "loading";
        },
        [userRegistered.fulfilled]: (state, action) => {
            state.user = action.payload.user;
            state.status = "success";
        },
        [userRegistered.rejected]: (state, action) => {
            state.status = "failed";
            state.errMessage = action.payload.message;
        },
        [userUpdated.fulfilled]: (state, action) => {
            state.user = action.payload.user;
        }
    }
});

export const selectUser = (state) => state.authentication.user;
export const selectAuthError = (state) => state.authentication.errMessage;
export const selectUsername = (state) => state.authentication.user.username;
export const selectStatus = (state) => state.authentication.status;
export const selectAuthState = (state) => state.authentication.isAuthenticated;
export const {resetUser, resetError} = authenticationSlice.actions;
export default authenticationSlice.reducer;