import React, {useEffect, useRef, useState} from "react";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex';
import plugins from 'suneditor/src/plugins'
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {setElement} from "../../statementsSlice";
import {equations2html, html2equations} from "../../../../presenters/EditorPresenter";

const TextElementEditor = ({element}) => {
    const dispatch = useDispatch();

    const [initValue, setInitValue] = useState(null);
    useEffect(() => {
        setInitValue(equations2html(element.content));
    }, [element]);

    // set editor contents!
    const editorRef = useRef();
    const editorChanged = (contents, core) => {
        dispatch(setElement({
            key: "content",
            value: html2equations(contents)
        }));
    }

    return (
        <Grid item xs={12} sm={12} md={8} lg={8}>
            {
                initValue === null ? null :
                    <SunEditor
                        height={200}
                        placeholder="Type here ..."
                        styles={{display: 'block'}}
                        setContents={`<p>${equations2html(element.content)}</p>`}
                        ref={editorRef}
                        onChange={editorChanged}
                        lang="en"
                        setOptions={{
                            plugins: [plugins],
                            katex: katex,
                            buttonList: [
                                ['undo', 'redo'],
                                ['blockquote', 'fontColor', 'strike', 'align'],
                                ['bold', 'underline', 'italic', 'indent', 'subscript', 'superscript'],
                                ['removeFormat'],
                                ['math'],
                                ['fullScreen', 'codeView'],
                            ]
                        }}
                    />
            }
        </Grid>
    );
}

export default TextElementEditor;