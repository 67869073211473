const Storage = {
    read: (key) => {
        const val = localStorage.getItem(key);
        return JSON.parse(val);
    },
    write: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    delete: (key) => {
        localStorage.removeItem(key);
    },
    clear: () => {
        localStorage.clear();
    }
}

export default Storage;