import { Draggable } from 'react-beautiful-dnd';

import React from 'react';
import LessonSingle from './LessonSingle';
import {selectLessonDisplayOptions} from "../lessonsSlice";
import {useSelector} from "react-redux";
import {lessonDisplay} from "../../../presenters/ChapterPresenter";

const LessonList = ({lessons, lessonBoardName, chapterId, handleToggle}) => {
    const lessonDisplayOptions = useSelector((state) => selectLessonDisplayOptions(state));
    return (
        lessonDisplay(lessonDisplayOptions, lessons).map((lesson, index) => (
            <Draggable
                key={lesson.id}
                draggableId={`${lesson.id}`}
                index={index}
            >
                {(provided, snapshot) => {
                    return (
                        <LessonSingle
                            key={lesson.id}
                            lesson={lesson}
                            lessonBoardName={lessonBoardName}
                            chapterId={chapterId}
                            provided={provided}
                            handleToggle={handleToggle}
                        />
                    )
                }}
            </Draggable>
            )
        )
    )
}

export default LessonList;
