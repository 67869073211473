import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAddUserPopupOpen, toggleAddUserPopup, userAdded} from "./usersSlice";
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel, MenuItem, Select,
    TextField
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const AddUserPopup = () => {
    const dispatch = useDispatch();
    const open = useSelector((state) => selectIsAddUserPopupOpen(state));
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState("student");

    const closeModal = () => {
        setUsername("");
        setEmail("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setRole("student");
        dispatch(toggleAddUserPopup({}));
    }

    const addUser = () => {
        const user = {
            id: null,
            username: username,
            email: email,
            lastName: lastName,
            firstName: firstName,
            role: role,
            password: password,
        };
        dispatch(userAdded({
            user: user
        }));
        closeModal();
    }

    const validateAndSetEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email === "") setIsEmailValid(true);
        setIsEmailValid(re.test(String(email).toLowerCase()));
        setEmail(email);
    }

    return (
        <Dialog fullWidth={true} open={open} aria-labelledby="profile-dialog-popup">
            <DialogTitle>
                Add user
            </DialogTitle>
            <Divider/>
            <Box mr={2} ml={2} mt={0}>
                <DialogContentText style={{marginTop: "20px"}}>
                    Fill in the <strong>fields</strong> below to create a new user information.
                    Press <strong>Done!</strong> to save.
                </DialogContentText>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setUsername(event.target.value)}
                            autoFocus
                            id="username"
                            label="Username"
                            type="text"
                            fullWidth
                            value={username}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setFirstName(event.target.value)}
                            id="firstname"
                            label="First Name"
                            type="text"
                            fullWidth
                            value={firstName}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setLastName(event.target.value)}
                            id="lastname"
                            label="Last Name"
                            type="text"
                            fullWidth
                            value={lastName}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                validateAndSetEmail(event.target.value)}
                            error={!isEmailValid}
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            value={email}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(event) =>
                                setPassword(event.target.value)}
                            id="password"
                            label="Password"
                            type="text"
                            fullWidth
                            value={password}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl style={{margin: '5px', width: '80%',}}>
                            <InputLabel>Role</InputLabel>
                            <Select
                                onChange={(event) =>
                                    setRole(event.target.value)}
                                value={role}
                            >
                                <MenuItem
                                    value={"admin"}>
                                    Admin
                                </MenuItem>
                                <MenuItem
                                    value={"student"}>
                                    Student
                                </MenuItem>
                                <MenuItem
                                    value={"teacher"}>
                                    Teacher
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <DialogActions>
                <Button
                    onClick={closeModal}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={addUser}
                    color="primary">
                    Done!
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddUserPopup;