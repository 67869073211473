export default function makeGetLessonDependencies() {
    return function getLessonDependencies(
        {root, current, deps, nbOfNodes}
    ) {
        // If this is a new lesson, then all dependencies are valid
        if(current.id === null)
            return deps;

        // Deep copy all lessons for reference
        let lessons = JSON.parse(JSON.stringify(deps));
        let lessonsById = {};
        lessons.forEach((less) => lessonsById[less.id] = less);
        lessonsById[current.id] = current;

        // Get the index of an array element based on id
        const getIndex = (arr, item) => {
            if(typeof item === "undefined") return null;
            let index = -1;
            arr.some((it, i) => {
                if(it.id === item.id) {
                    index = i;
                    return true;
                }
                return false;
            });
            return index;
        }

        // Recursive function removing all dependencies that would introduce
        // a cyclic dependency from root to branch. If any!
        function setDepsRec(current, branch, visited) {
            if(!Array.isArray(branch)) branch = [branch];
            if (current.id === root.id) {
                const first = getIndex(deps, current);
                if(first > -1) deps.splice(first, 1);
                for (const item of branch) {
                    const i = getIndex(deps, item);
                    if(i > -1) deps.splice(i, 1);
                }
                return;
            }
            if (visited.length === nbOfNodes) return;
            if(getIndex(visited, current) === -1) visited.push(current);
            branch.push(current);
            current.dependencies.forEach((depId) => {
                setDepsRec(lessonsById[depId], [...branch], visited);
            });
        }

        Object.values(lessonsById).forEach((current) =>
            setDepsRec(current, [], []));
        return deps;
    }
}