export default function buildMakeTextElement(
    {typeString, typeVal, makeId}
) {
    const error = "Creating a text element failed! ";
    let errorDetails = "";

    const isValidElement = (data) => {
        let isValid = true;
        if (data.type !== typeVal &&
            data.typeString !== typeString) {
            isValid = false;
            errorDetails += "Invalid type for text element. ";
        }

        if (!data.content) {
            isValid = false;
            errorDetails = "No content found";
        }
        return isValid;
    }

    return function makeTextElement({data}) {
        if (!isValidElement(data)) {
            throw new Error(error + "\n" + errorDetails);
        }

        return {
            id: makeId(),
            type: typeVal,
            typeString: typeString,
            content: data.content,
        };
    }

}